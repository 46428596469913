import apigee from '@/api/apigee'

export default {
  getEnrolledDevices (payload) {
    return apigee.get('/uspdata/v1/usp-maintenance/device/monitoring')
  },
  searchEnrolledDevice (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/device/search?location_id=${payload.location_id}&status=${payload.status}`)
  },
  updateData (id, payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/device/monitoring/${id}`, payload)
  },
  updateStatus (id, payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/device/status/${id}`, payload)
  },
  deleteDevice (id) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/device/monitoring/${id}`)
  }
}
