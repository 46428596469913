import apigee from '@/api/apigee'
import gcpdev from '@/api/gcpdev.js'
import { setup } from 'axios-cache-adapter'

export default {
  googleLogin () {
    return gcpdev.get('/api/login/google')
  },
  oktaLogin () {
    return gcpdev.get('/api/login/okta')
  },
  userPWLogin (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/login/user', payload)
  },
  userLogout (userId, ipAddress) {
    return apigee.get(`/uspdata/v1/usp-maintenance/login/logout?user_name=${userId}&ip_address=${ipAddress}`)
  },
  verifyAuth () {
    const baseDomain = process.env.VUE_APP_API_DEV_URL || 'https://privatedrp.perahub.com.ph'
    const baseURL = `${baseDomain}`
    const api = setup({
      // `axios` options
      baseURL: baseURL,
      // `axios-cache-adapter` options
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Perahub-Gateway-Token': process.env.VUE_APP_API_TOKEN
      },
      cache: {
        maxAge: 259200 // 3days
      }
    })
    return api.get('verify-auth')
  },
  verifyOTP (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/otp/usp-otp-validate', payload)
  },
  closeBranch (locationID, trxDate, userID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/login/close-branch?location_id=${locationID}&trx_date=${trxDate}&user_id=${userID}`)
  },
  checkToken (payload) {
    return apigee.post('/usp-auth/v1/api/auth/check', payload)
  },
  refreshToken (payload) {
    return apigee.post('/usp-auth/v1/api/auth/refresh', payload)
  },
  requestPasswordReset (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/reset-password/reset-pw', payload)
  },
  updatePassword (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/reset-password/verify-reset-pw', payload)
  }
}
