import apigee from '@/api/apigee'

export default {
  gridWU (locationID, trxDate) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/grid-wu?location_id=${locationID}&trx_date=${trxDate}`)
  },
  saveWUPending (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/save-wu-pending', payload)
  },
  saveWU (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/save-wu', payload)
  },
  deleteWU (id) {
    return apigee.delete(`/uspdata/v1/usp-transaction/trx/wupending/${id}`)
  }
}
