import apigee from '@/api/apigee'

export default {
  grid (trxDate, locationID) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/mc?trx_date=${trxDate}&location_id=${locationID}`)
  },
  save (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/mc', payload)
  },
  rates (trxDate, locationID, currencyID) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/mc-get-rate?trx_date=${trxDate}&location_id=${locationID}&currency_id=${currencyID}`)
  }
}
