import apigee from '@/api/apigee'

export default {
  getServiceFeeList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/phvc/grid?user_id=${payload.user_id}&module_id=${payload.module_id}&access_permission=${payload.access_permission}`)
  },
  addServiceFee (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/phvc/save', payload)
  },
  updateServiceFee (id, code, payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/phvc/update?id=${id}&phvc_code=${code}`, payload)
  },
  deleteServiceFee (payload) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/phvc/remove?id=${payload.id}&user_id=${payload.user_id}&module_id=${payload.module_id}&access_permission=${payload.access_permission}`)
  },
  getServiceFee (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/phvc/retrieve-fee', payload)
  }
}
