<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Summary
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br>
          <div class="ph-confirm-subtitle">
            <p><b>Kindly check all the details before sending the payout.</b></p>
          </div>
          <table class="ph-payout-summary">
            <tbody>
              <tr>
                <td><strong>Sender Details</strong></td>
              </tr>
              <tr>
                <td>Sender Name</td>
                <td>{{transactionData.sender_name}}</td>
              </tr>
              <br>
              <tr>
                <td class="payout-headers"><strong>Receiver Details</strong></td>
              </tr>
              <tr>
                <td>Receiver Name</td>
                <td>{{transactionData.customer_name}}</td>
              </tr>
              <br>
              <tr>
                <td>Present Address</td>
                <td>
                  {{transactionData.current_address.address_1}}, {{transactionData.current_address.barangay}}
                  {{transactionData.current_address.city}}, {{transactionData.current_address.province}},
                  {{transactionData.current_address.country}}, {{transactionData.current_address.zip_code}}
                </td>
              </tr>
              <br>
              <tr>
                <td>ID Type</td>
                <td>{{transactionData.id_type}}</td>
              </tr>
              <tr>
                <td>ID Number</td>
                <td>{{transactionData.id_number}}</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>{{transactionData.contact_number}}</td>
              </tr>
              <tr>
                <td>Purpose of Transaction</td>
                <td>{{transactionData.purpose_transaction}}</td>
              </tr>
              <br>
              <tr>
                <td class="payout-headers"><strong>Transaction Details</strong></td>
              </tr>
              <tr>
                <td>Control Number</td>
                <td>{{transactionData.control_number}}</td>
              </tr>
              <tr>
                <td>Date and Time</td>
                <td>{{formatDateTime(new Date())}}</td>
              </tr>
              <tr>
                <td>Branch</td>
                <td>{{currUser.location_name}}</td>
              </tr>
              <br>
              <tr>
                <td class="payout-headers"><strong>Breakdown</strong></td>
              </tr>
              <tr class="payout-breakdown">
                <td>Amount Sent</td>
                <td>{{formatAmount(transactionData.principal_amount)}}</td>
              </tr>
              <tr class="payout-breakdown">
                <td>Transfer Fee</td>
                <td>{{formatAmount(transactionData.service_charge)}}</td>
              </tr>
              <tr class="payout-breakdown">
                <td>DST</td>
                <td>{{formatAmount(transactionData.dst_amount)}}</td>
              </tr>
              <tr class="payout-breakdown">
                <td>Delivery Charge</td>
                <td>0.00</td>
              </tr>
              <tr class="payout-breakdown">
                <td>Discount</td>
                <td>0.00</td>
              </tr>
              <tr class="payout-breakdown">
                <td>Exchange Rate</td>
                <td>1.00000000</td>
              </tr>
              <tr class="payout-breakdown">
                <td><b>TOTAL</b></td>
                <td><b>{{formatAmount(transactionData.total_amount)}}</b></td>
              </tr>
              <tr class="payout-breakdown">
                <td><b>Payout Amount</b></td>
                <td><b>{{formatAmount(transactionData.total_amount)}}</b></td>
              </tr>
              <!-- <tr>
                <td colspan="2"><b>Customer Signature</b></td>
              </tr> -->
            </tbody>
          </table>
          <!-- <div style="text-align:center;" v-if="currUser.has_wacom === true">
            <img :src="customerSignature" style="height:auto; width: 500px;" alt="">
            <p style="position:relative; bottom:30px; font-weight:bold; font-size:20px;">{{transactionData.receiver_name}}</p>
          </div> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            class="ph-action-button"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button"
            elevation="0"
            @click="showWacom"
          >
            Payout
          </v-btn>
          <!-- <v-btn
            color="primary"
            class="ph-action-button"
            elevation="0"
            v-else-if="hasWacom && !hasSignature"
            @click="renderSignaturePad"
          >
            Add a Signature
          </v-btn> -->
          <!-- <wacom v-else-if="!hasSignature && currUser.has_wacom" @submit-sig="submitSig" type="transactional" :trxData="transactionData" :referenceSignatures="customerSignaturesReference" /> -->
          <wacom-transaction
            v-if="currUser.has_wacom"
            @submit-sig="submitSig" type="transactional"
            :referenceSignatures="customerSignaturesReference"
            :trxData="{sender_name: transactionData.sender_name, receiver_name: transactionData.customer_name, total_amount: transactionData.total_amount}"
          />
          <wacom-consent />
          <wacom-data-consent-first />
          <wacom-data-consent-second />
          <wacom-data-consent-third />
          <wacom-data-consent-fourth />
          <wacom-data-consent-fifth />
          <wacom-data-consent-sixth />
          <wacom-data-consent-seventh />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="500"
      persistent
    >

      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Successful
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>The print receipt window will open in 3 seconds.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            class="ph-action-button ph-action-button-receive-confirm"
            @click="backToListPage"
          >
            Back to List Page
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="generateACR"
          >
            Print Receipt
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="white"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { format } from 'date-fns'
import { Buffer } from 'buffer/'
import wacomMixin from '@/mixins/wacom.js'
import { renderToast, renderAVPSpiel, getAge } from '@/utils'

export default {
  name: 'ReceiveMoneyConfirm',
  mixins: [wacomMixin],
  components: {
    wacomTransaction,
    wacomConsent,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  props: {
    showConfirmDialog: Boolean,
    transactionData: Object
  },
  data () {
    return {
      dialog: false,
      successDialog: false,
      payload: {},
      result: {},
      interval: {},
      progressValue: 0,
      countdown: 3,
      base64String: '',
      hasSignature: false,
      hasWacom: '',
      customerSignature: '',
      customerSignaturesReference: [],
      crossSellingData: {},
      hasCustomerDataConsent: false,
      isMoneyGram: false
    }
  },
  computed: {
    ...mapGetters({
      currentTrx: 'nonex/currentTrx',
      payoutFormData: 'nonex/payoutFormData',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      consentValues: 'wacom/consentValues',
      cebuanaRelationList: 'nonex/getCebuanaRelationList'
    })
  },
  watch: {
    'transactionData.customer_id': function (newVal, oldVal) {
      this.checkConsent()
    },
    showConfirmDialog: function (data) {
      this.dialog = data
    },
    payoutFormData: {
      function (data) {
        this.payload = data
      },
      deep: true
    },
    countdown: {
      handler (count) {
        if (count === 0) {
          const that = this
          setTimeout(function () {
            that.progressValue = 0
          }, 1000)

          clearInterval(this.interval)
        }
      },
      currentTrx: {
        handler (data) {
          console.log(data)
          this.selectedTrxData = data
        },
        deep: true
      }
    }
  },
  async mounted () {
    if (this.transactionData.remco_id === 19) {
      this.checkIfMoneygram()
    }
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    if (this.transactionData.customer_id) {
      const signatures = await this.$store.dispatch('customers/retrieveSignatures', { customer_id: this.transactionData.customer_id })
      this.customerSignaturesReference = signatures.result
      this.checkConsent()
    }
  },
  methods: {
    checkIfMoneygram () {
      var ctlNo = this.transactionData.control_number
      if (ctlNo.substr(0, 2) !== 'FT' && ctlNo.substr(0, 3) !== 'ALB' && ctlNo.substr(0, 5) !== 'CMTMY' && ctlNo.substr(0, 5) !== 'CMTTH' && ctlNo.substr(0, 1) !== 'X' && ctlNo.substr(0, 3) !== 'P33' && ctlNo.substr(0, 2) !== 'QZ' && ctlNo.substr(0, 2) !== 'CS' && ctlNo.substr(0, 2) !== 'SW' && ctlNo.substr(0, 3) !== 'EMQ' && ctlNo.substr(0, 2) !== 'AF' && ctlNo.substr(0, 3) !== 'CEB' && ctlNo.substr(0, 6) !== 'REWIRE' && ctlNo.substr(0, 4) !== '1982' && ctlNo.substr(0, 3) !== 'RFE' && ctlNo.substr(0, 3) !== 'RAK' && ctlNo.substr(0, 1) !== 'R' && ctlNo.substr(0, 4) !== '13EC' && ctlNo.substr(-2) !== 'CB' && ctlNo.substr(0, 2) !== 'CE' && ctlNo.substr(0, 1) !== 'C' && ctlNo.substr(0, 2) !== 'EZ') {
        this.isMoneyGram = true
      }
    },
    showWacom () {
      console.log(this.transactionData)
      if (this.currUser.has_wacom) {
        if (this.hasCustomerDataConsent) {
          this.$store.commit('wacom/SET_WACOM_DIALOG', true)
        } else {
          this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
        }
      } else {
        this.executePayout()
      }
    },
    renderToast,
    renderAVPSpiel,
    getAge,
    submitSig (emitted) {
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.hasSignature = true
      this.customerSignature = emitted
      if (this.hasCustomerDataConsent === false) {
        this.generateConsent()
      }
      this.executePayout()
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    closeDialog () {
      this.dialog = false
      this.$emit('update:showConfirmDialog', false)
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      // FileSaver.saveAs(blob, 'filename')
    },
    renderSignaturePad () {
      console.log('signature pad renders')
    },
    backToListPage () {
      this.successDialog = false
      window.location.href = '/pera-remit/receive'
    },
    async executePayout () {
      const payoutPayload = {
        endpoint: this.transactionData.endpoint ? this.transactionData.endpoint : this.selectedTrxData.payout_route,
        formData: this.transactionData
      }

      if (payoutPayload.formData.id_type === "Voter's ID" && (payoutPayload.formData.remco_id === 7 || payoutPayload.formData.remco_id === 19)) {
        payoutPayload.formData.id_number = payoutPayload.formData.id_number.slice(0, 17)
      }

      if (payoutPayload.formData.remco_id === 19) {
        const trxData = payoutPayload.formData
        const currentAddress = `${trxData.current_address.address_1}`
        // Add validation for current address
        if (currentAddress.length < 10) {
          this.renderToast('error', 'The current address less than 10 characters', 'The current address less than 10 characters. The current Address must be at least 10 characters. Please try a valid address.')
          return false
        }
        let cebuanaRelationship = trxData.relation_to
        await this.$store.dispatch('nonex/cebuanaGetRelation')
        this.cebuanaRelationList.filter(data => {
          if (data.RelationshipToSenderCode === trxData.relation_to) {
            cebuanaRelationship = data.ID
          }
        })
        const beneficiaryPayload = {
          beneficiary_id: trxData.beneficiary_id,
          birth_date: trxData.birth_date ? trxData.birth_date : '',
          cellphone_country_id: '166',
          contact_number: trxData.contact_number,
          country_address_id: '166',
          birth_country_id: '166',
          current_address: currentAddress,
          city: trxData.city,
          zip_code: trxData.zip_code,
          occupation: trxData.occupation,
          user_id: trxData.user_id,
          // new for moneygram
          telephone_area_code: '166',
          occupation_id: trxData.occupation_id,
          province_address: trxData.current_address.province,
          city_address: trxData.city,
          telephone_number: trxData.contact_number,
          relation_to: cebuanaRelationship,
          gender_id: trxData.gender_id,
          citizenship_country_id: '166',
          citizenship_birth_country_id: '166',
          birth_city: trxData.birth_place
        }

        await this.$store.dispatch('nonex/updateCebuanaIntBeneficiary', beneficiaryPayload).then((result) => {
          this.renderToast('success', 'Success', 'The beneficiary information was updated successfully.')
        }).catch((error) => {
          console.log(error)
          this.renderToast('error', 'Error', 'Update failed. Please try again')
        })
      }

      await this.$store.dispatch('nonex/executePayout', payoutPayload).then((result) => {
        if (result.remco_id === 20 && result.code === '00000000') {
          this.renderCrossSellingSpiel()

          const that = this
          this.successDialog = true
          this.countdown = 3
          this.interval = setInterval(() => {
            if (this.progressValue === 99.99 && this.countdown === 0) {
              return (this.progressValue = 0)
            }
            this.progressValue += 33.3333333333
            this.countdown -= 1
          }, 1000)
          setTimeout(function () {
            that.generateACR()
          }, 3000)
        } else if (!Object.prototype.hasOwnProperty.call(result, 'error') || !Object.prototype.hasOwnProperty.call(result, 'errors')) {
          const successMessages = [
            'success',
            'successful',
            'success!',
            'order confirmed as paid.',
            'successfully payout!',
            'good'
          ]

          const responseMsg = result.message
          const responseLowerCaseMsg = responseMsg.toLowerCase()

          // check if BPI transaction is successful
          const checkIfBPISuccessful = result.remco_id === 2 && responseLowerCaseMsg.includes('successful') && result.code === '8'
          // check if instacash transaction is successful
          const checkIfInstacashSuccessful = result.remco_id === 16 && result.result.remarks === 'Succesful Payout'

          if (successMessages.includes(responseLowerCaseMsg) || checkIfBPISuccessful || checkIfInstacashSuccessful) {
            this.renderCrossSellingSpiel()

            const that = this
            this.successDialog = true
            this.countdown = 3
            this.interval = setInterval(() => {
              if (this.progressValue === 99.99 && this.countdown === 0) {
                return (this.progressValue = 0)
              }
              this.progressValue += 33.3333333333
              this.countdown -= 1
            }, 1000)
            setTimeout(function () {
              that.generateACR()
            }, 3000)
          } else {
            if (Object.prototype.hasOwnProperty.call(result, 'errors')) {
              let errorItems = ''

              result.errors.forEach((res, index) => {
                errorItems += res.message + (index > 0 ? ',\n ' : '')
              })

              this.renderToast('error', 'Payout Error', errorItems)
            } else if (Object.prototype.hasOwnProperty.call(result, 'error')) {
              this.renderToast('error', 'Payout Error', result.error?.message ? result.error.message : 'Payout failed. Please try Again')
            } else {
              this.renderToast('error', 'Error', 'Payout failed. Please try again')
            }
          }
        } else {
          this.renderToast('error', 'Error', 'Payout failed. Please try again')
        }
      }).catch(() => {
        this.renderToast('error', 'Error', 'Payout failed. Please try again')
      })
    },
    renderCrossSellingSpiel () {
      const trxData = this.transactionData

      const payload = {
        product: 'remittance',
        amount: trxData.principal_amount,
        gender: trxData.gender,
        age: this.getAge(trxData.birth_date),
        type: this.selectedCustomer.civil_status
      }

      this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
        this.crossSellingData = res.result[0]
        this.renderAVPSpiel(this.crossSellingData)
      }).catch(err => {
        console.log(err)
      })
    },
    generateACR () {
      const that = this
      const trxData = this.transactionData

      const currentAddress = `${trxData.current_address.address_1}, ${trxData.current_address.barangay}, ${trxData.current_address.city}, ${trxData.current_address.province}, ${trxData.current_address.country}, ${trxData.current_address.zip_code}`
      const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
      const ACRPayload = {
        remco_name: trxData.remco_name,
        sender_name: trxData.sender_name,
        remco_id: trxData.remco_id,
        trx_type: trxData.trx_type,
        receiver_name: trxData.receiver_name,
        current_address: currentAddress,
        id_type: trxData.id_type,
        id_number: trxData.id_number,
        contact_number: trxData.contact_number,
        purpose_transaction: trxData.purpose_transaction,
        control_number: trxData.control_number,
        trx_timestamp: this.formatDateTime(new Date()),
        vlocation_name: this.currUser.location_name,
        currency_name: trxData.currency,
        principal_amount: trxData.principal_amount,
        service_charge: trxData.service_charge,
        total_amount: trxData.total_amount,
        buy_back_amount: trxData.buy_back_amount,
        currency_id: trxData.currency_id,
        agent_name: agentName,
        location_id: trxData.location_id,
        has_wacom: this.currUser.has_wacom,
        img: this.customerSignature,
        img_fla: this.currUser.userSignature,
        customer_id: trxData.customer_id.toString(),
        crn: trxData.client_reference_no,
        uploaded_by: this.currUser.user_id
      }
      if (this.isMoneyGram && this.transactionData.remco_id === 19) {
        ACRPayload.originating_country = this.transactionData.country
        ACRPayload.trx_date = this.currUser.trx_date
        this.$store.dispatch('customers/generateACRMoneygram', ACRPayload).then((res) => {
          that.base64String = res.result
          that.pdfConvert()
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
          that.base64String = res.result
          that.pdfConvert()
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: this.transactionData.remco_name + ' ' + this.transactionData.trx_type,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Consent Error', 'Failed to save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.transactionData.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    }
  }
}
</script>
<style scoped>
  .ph-payout-summary {
    width: 100%
  }

  .ph-payout-summary tr td:first-child {
    text-align: left !important
  }

  .ph-payout-summary tr td:last-child {
    text-align: right
  }

  .ph-action-button-receive-confirm {
    padding: 25px !important
  }
</style>
