<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="4" :md="3">
          <v-select
            v-model="searchCategory"
            :items="statusList"
            item-text="text"
            item-value="value"
            class="ph-textbox ml-md-3"
            label="Status"
            outlined
          />
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-trusted-device-search-btn"
            @click="filterExcessFundList(searchCategory)"
          >
            Filter
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="excessFundRequestList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 3 || item.status === '3'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip right v-if="item.status === 1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="excessAction(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 1" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 1" @click="excessAction(item, 'disapprove')" color="primary" icon>
                      <v-icon>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Disapprove</span>
                </v-tooltip>
               {{ item.status === 2 ? '-' : '' }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(confirmPayload, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'ExcessFundRquests',
  data () {
    return {
      searchLocation: '',
      searchCategory: '',
      statusList: [
        { text: 'All', value: 0 },
        { text: 'Pending', value: 1 },
        { text: 'Approved', value: 2 },
        { text: 'Disapproved', value: 3 }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Excess Funds',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Amount',
          sortable: false,
          value: 'amount'
        },
        {
          text: 'Currency',
          sortable: false,
          value: 'currency_name'
        },
        {
          text: 'FLA',
          sortable: false,
          value: 'fla_name'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location_name'
        },
        {
          text: 'Reference Number',
          sortable: false,
          value: 'reference_number'
        },
        {
          text: 'Transaction Date',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Remarks',
          sortable: false,
          value: 'remarks'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: '',
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currentLocation: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      excessFundRequestList: 'closeBranch/excessFundRequestList',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('getAllLocations', 0)
    await this.filterExcessFundList(0)
  },
  methods: {
    renderToast,
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    excessAction (item, type) {
      this.confirmPayload = item
      this.confirmActionType = type

      switch (type) {
        case 'approve':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Approve Request'
          this.confirmDialogText = 'Are you sure you want to approve this request?'
          break
        case 'disapprove':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Disapprove Request'
          this.confirmDialogText = 'Are you sure you want to reject this request?'
          break
      }
    },
    async doConfirmAction (item, type) {
      console.log(this.confirmPayload)
      const payload = {
        id: item.id,
        params: {
          status: (type === 'approve') ? 2 : 3,
          approved_by: this.currUser.user_id
          // remarks: (type === 'approve') ? 'Excess fund request is approved.' : 'Excess fund request is rejected.'
        }
      }

      let confirmAction = ''
      let confirmTypeText = ''

      switch (type) {
        case 'approve':
          confirmTypeText = 'The request has been successfully approved'
          confirmAction = 'closeBranch/updateExcessFundStatus'
          break
        case 'disapprove':
          confirmTypeText = 'The request has been successfully rejected'
          confirmAction = 'closeBranch/updateExcessFundStatus'
          break
      }

      await this.$store.dispatch(confirmAction, payload).then(res => {
        if (res.code === 200) {
          this.renderToast('success', 'Action Successful', confirmTypeText)
          this.confirmDialog = false
          this.confirmDialogTitle = ''
          this.confirmDialogText = ''
          this.currentLocation = ''
          this.filterExcessFundList(0)
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
        this.confirmDialog = false
        this.confirmDialogTitle = ''
        this.confirmDialogText = ''
        this.currentLocation = ''
      })
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    async filterExcessFundList (statusId) {
      const payload = {
        trx_date: this.currUser.trx_date,
        user_id: this.currUser.user_id,
        user_name: this.currUser.user_name,
        status: statusId
      }
      await this.$store.dispatch('closeBranch/getExcessFundRequestList', payload)

      this.searchLocation = ''
      this.searchCategory = ''
    }
  }
}
</script>
<style>
.browser-item-container {
  display: flex;
  flex-direction: row;
}

.item-container {
  margin-right: 10px;
}

.item-icon {
  height: 18px;
  width: auto;
  position: relative;
  top: 4px;
}

.ph-primary-button.ph-trusted-device-search-btn {
  margin: 0 10px;
  height: 49px !important;
}
</style>
