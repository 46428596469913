<template>
  <v-snackbar
    min-width="300px"
    v-model="snackbarAttrs.renderSnackbar"
    :color="snackbarAttrs.snackbarColor"
    multi-line
    style="z-index:10000"
    :timeout="(snackbarAttrs.snackbarTitle === 'Transaction Saved on Record') ? -1 : 5000"
  >
    <b>{{ snackbarAttrs.snackbarTitle }}</b>
    <div v-if="snackbarAttrs.snackbarColor === 'error' && (typeof snackbarAttrs.snackbarText === 'string' || typeof snackbarAttrs.snackbarText === 'text')  && snackbarAttrs.snackbarText !== ''">
      <p>{{ snackbarAttrs.snackbarText }}</p>
    </div>
    <div v-if="snackbarAttrs.snackbarColor === 'error' && typeof snackbarAttrs.snackbarText !== 'string' && snackbarAttrs.snackbarText !== ''">
      <ul>
        <li v-for="(item, index) in snackbarAttrs.snackbarText" :key="index">
          {{item}}
        </li>
      </ul>
    </div>
    <p v-if="snackbarAttrs.snackbarColor !== 'error' && snackbarAttrs.snackbarText !== ''&& snackbarAttrs.snackbarText !== ''">{{ snackbarAttrs.snackbarText }}</p>

    <template v-slot:action="{ attrs }">
      <v-btn
        color="default"
        text
        v-bind="attrs"
        @click="resetState"
      >
        Dismiss
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Snackbar',
  data () {
    return {
      snackbarAttrs: {
        renderSnackbar: false,
        snackbarColor: '',
        snackbarTitle: '',
        snackbarText: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'app/getSnackbarState'
    })
  },
  watch: {
    snackbar: function (data) {
      this.snackbarAttrs = data

      console.log(this.snackbarAttrs.snackbarText.length)
    }
  },
  methods: {
    resetState () {
      this.snackbarAttrs = {
        renderSnackbar: false,
        snackbarColor: '',
        snackbarTitle: '',
        snackbarText: ''
      }

      this.$store.commit('app/SET_SNACKBAR_STATE', this.snackbarAttrs)
    }
  }
}
</script>
<style scoped>

</style>
