import apigee from '@/api/apigee'

export default {
  getReceipt (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/ereceipt/seriesno', payload)
  },
  saveReceipt (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/ereceipt/detailed', payload)
  },
  formNumber (locationID, type) {
    return apigee.get(`/uspdata/v1/usp-maintenance/receipt/get-available?location_id=${locationID}&type=${type}`)
  },
  cancelFormNumber (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/receipt/cancel-form', payload)
  },
  print (locationID, trxDate, refNo, trxType, type) {
    return apigee.get(`/uspdata/v1/usp-maintenance/receipt/print?location_id=${locationID}&trx_date=${trxDate}&ref_no=${refNo}&trx_type=${trxType}&type=${type}`)
  }
}
