<template>
  <v-col :cols="0" :md="4">
    <v-card id="summaryOverview" class="d-none d-md-block px-6 py-6 grey--text text--darken-2" flat>
      <h3 class="text-center mb-md-8">Transaction Summary</h3>
      <v-row no-gutters>
        <v-col v-if="alert.isVisible" :cols="12">
          <v-alert
            outlined
            :type="alert.type"
            prominent
            border="left"
          >
            {{ alert.message }}
          </v-alert>
        </v-col>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
      <v-btn
        @click="checkEmail"
        class="rounded-lg mt-md-4 font-weight-black"
        color="primary"
        block
        x-large
      >
        Confirm
      </v-btn>
    </v-card>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-receive-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-receive-summary-minified">
          <div class="ph-wu-receive-summary-content">
            <div class="ph-wu-receive-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>{{ totalAmount }}</b></p>
            </div>
            <div class="ph-wu-receive-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Transaction Summary</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col class="px-2 py-2" v-if="alert.isVisible" :cols="12">
            <v-alert
              outlined
              :type="alert.type"
              prominent
              border="left"
            >
              {{ alert.message }}
            </v-alert>
          </v-col>
          <v-col v-for="data in summaryData" :key="data.text" :cols="12">
            <div class="my-2 px-2 my-md-0 mx-md-0 d-flex justify-space-between">
              <div>{{ data.text }}</div>
              <div>
                <strong>{{ data.value || '—' }}</strong>
              </div>
            </div>
            <v-divider class="my-md-2" style="border-style: dashed" />
          </v-col>
        </v-row>
        <div class="px-1">
          <v-btn
            @click="checkEmail"
            class="rounded-lg mt-6 font-weight-black"
            color="primary"
            block
            x-large
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="manualConfirmPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Would you like to proceed with this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="manualConfirmPrompt = false"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <wacom-transaction
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedCustomer.full_name, receiver_name: 'N/A', total_amount: transactionData.product.Amount}"
    />
    <wacom-consent />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney, renderToast } from '@/utils'
import { format } from 'date-fns'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import wacomMixin from '@/mixins/wacom.js'

export default {
  mixins: [wacomMixin],
  components: {
    wacomTransaction,
    wacomConsent,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  computed: {
    ...mapGetters({
      transactionData: 'eload/transactionData',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      alert: 'eload/alert',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues',
      seriesDetail: 'manualReceipt/seriesDetail'
    }),
    totalAmount () {
      const { product } = this.transactionData
      if (product) {
        return toMoney(parseFloat(product.Amount) + parseFloat(product.Additional))
      } else {
        return toMoney(0)
      }
    },
    completedForm () {
      const data = this.transactionData
      return (!data.issueOAR || (data.issueOAR && (this.receipt.oar_series_no || this.seriesDetail.series_no))) &&
        data.product &&
        data.mobileNumber &&
        (!data.freeLoad || (data.freeLoad && data.promoCode))
    },
    summaryData () {
      const fullData = this.transactionData
      const summary = {
        product: { text: 'Product', value: null },
        type: { text: 'Type', value: null },
        mobileNumber: { text: 'Mobile Number', value: null },
        amount: { text: 'Load', value: null },
        fee: { text: 'Fee', value: null },
        promoCode: { text: 'Promo Code', value: null },
        totalAmount: { text: 'Total Amount', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'product':
            if (fullData.product) {
              summary[field].value = fullData.product.ProductName
            }
            break
          case 'type':
            if (fullData.product) {
              summary[field].value = fullData.product.ProductType
            }
            break
          case 'amount':
            if (fullData.product) {
              summary[field].value = toMoney(parseFloat(fullData.product.Amount))
            }
            break
          case 'fee':
            if (fullData.product) {
              summary[field].value = toMoney(parseFloat(fullData.product.Additional))
            }
            break
          case 'totalAmount':
            if (fullData.product) {
              summary[field].value = this.totalAmount
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      if (fullData.freeLoad === false) delete summary.promoCode

      return Object.values(summary)
    }
  },
  data () {
    return {
      summaryDialog: false,
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      customerSignature: '',
      manualConfirmPrompt: false
    }
  },
  methods: {
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.doSave()
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    proceedTransaction () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      } else {
        this.withoutEmailPrompt = false
        this.withEmailPrompt = false
        this.doSave()
      }
    },
    checkEmail () {
      if (this.transactionData.issueOAR && this.currUser.location_type === '1') {
        if (this.currUser.receipt_type === 3) {
          this.sendViaEmail = true
          if (this.selectedCustomer.email_add) {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = true
          } else {
            this.withoutEmailPrompt = true
            this.withEmailPrompt = false
            this.sendViaEmail = false
          }
        } else {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = false
          this.sendViaEmail = false
          this.manualConfirmPrompt = true
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.proceedTransaction()
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async doSave () {
      if (this.$parent.$refs.form.validate() && this.completedForm) {
        const data = this.transactionData
        const rrnDate = format(new Date(), 'Ymd')
        const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100
        let tpa = ['', '']

        if (this.currUser.tpa) tpa = this.currUser.tpa.split('|')

        const payload = {
          uid: tpa[0],
          pw: tpa[1],
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          trx_date: this.currUser.trx_date,
          customer_number: data.mobileNumber,
          rrn: `PNET${rrnDate}${randomNumber}`,
          product_code: data.product.ProductCode,
          amount: data.product.Amount,
          comission: data.product.Commission,
          product_type: data.product.ProductType,
          provider: data.product.Provider,
          form_type: '',
          form_number: '',
          customer_id: this.selectedCustomer.customer_id,
          customer_name: this.selectedCustomer.full_name,
          added_comission: data.product.Additional,
          promo_code: ''
        }

        if (data.issueOAR) {
          payload.form_type = 'OAR'
          payload.form_number = (this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no)
        }

        if (data.freeLoad) payload.promo_code = data.promoCode

        try {
          const req = await this.$store.dispatch('eload/save', payload)

          if (req.code === 200 && req.result.resp === '0') {
            this.$store.commit('eload/SET_SUCCESS_DATA', req.result)
            this.$store.commit('eload/SET_CURRENT_PAGE', 2)

            if (data.issueOAR) {
              if (this.currUser.receipt_type === 3) {
                const address = this.selectedCustomer.address
                const barangay = this.selectedCustomer.barangay
                const city = this.selectedCustomer.municipalty
                const province = this.selectedCustomer.province
                const zipCode = this.selectedCustomer.zip_code
                const saveReceipt = await this.$store.dispatch('saveReceipt', {
                  ereceipt_id: this.receipt.ereceipt_id,
                  application_id: this.currUser.application_id,
                  application_header_name: this.currUser.branchDetail.header_name,
                  application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                  receipt_type: this.receipt.type,
                  customer_id: this.selectedCustomer.customer_id,
                  customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                  customer_tin_no: '',
                  customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                  customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                  cashier_id: this.currUser.user_id,
                  cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                  oar_receipt_no: this.receipt.oar_receipt_no,
                  oar_number: this.receipt.oar_series_no,
                  oar_service: 'Pera Load',
                  oar_currency: 'PHP',
                  oar_principal_amount: data.product.Amount,
                  oar_discount: 0,
                  oar_total: data.product.Amount,
                  grand_total: data.product.Amount,
                  print_email_hold: 0,
                  is_forex_logo: 0
                })

                if (saveReceipt.code === 200) {
                  if (saveReceipt.result.receipt_status === 1) {
                    renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                  } else if (saveReceipt.result.receipt_status === 2) {
                    const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                      application_id: this.currUser.application_id,
                      ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                    })
                    var newBlob = new Blob([res], { type: 'application/pdf' })
                    var newFileURL = URL.createObjectURL(newBlob)
                    window.open(newFileURL, '_blank')
                  } else {
                    renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                  }
                } else {
                  renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
                }
              } else {
                const payload = {
                  id: this.seriesDetail.id,
                  status: 2,
                  customer_id: this.selectedCustomer.customer_id,
                  encoded_by: this.currUser.user_id,
                  date_encoded: format(new Date(), 'yyyy-MM-dd'),
                  amount: data.product.Amount,
                  description: 'Pera Load',
                  remarks: 'Pera Load'
                }

                await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
                  if (res.code >= 200 && res.code <= 299) {
                    renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
                  } else {
                    renderToast('error', 'Error', res.message)
                  }
                }).catch(err => {
                  renderToast('error', 'Error', err)
                })
              }
            }
          } else if (req.code === 200 && req.result.resp !== '0') {
            renderToast('error', 'Load Central Error', `${req.result.err} (${req.result.resp})`)
            this.$store.dispatch('eload/resetForm')
            this.$router.push('/pera-load')
          } else {
            renderToast('error', 'Transaction Failed', 'Failed to process this transaction')
          }
        } catch (e) {
          renderToast('error', 'Transaction Error', 'An error occurred while processing your transaction, please contact IT Support')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to proceed with the transaction')
      }
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      console.log(this.selectedCustomer)
      console.log(this.transactionData)
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'Pera Load - ' + this.transactionData.product.Provider,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    formatAmount (amount, currency) {
      if (amount) {
        return toMoney(amount, currency)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
  }
}
</script>

<style scoped>
#summaryOverview {
  position: sticky !important;
  top: 80px !important;
  background: #f4f4fa !important;
  border-radius: 5px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}
.v-stepper.ph-wu-receive-stepper .v-stepper__header {
  box-shadow: none !important;
}

.ph-wu-receive-summary .v-card {
  padding: 20px;
}

.ph-transfer-summary-header {
  font-size: 18px;
  text-align: center;
}

.ph-transfer-summary-content-title {
  font-size: 14px;
}

.transfer-info-title,
.transfer-info-value {
  border-bottom: thin dashed rgba(0,0,0,.12) !important;
}

.transfer-info-title {
  font-weight: bolder;
  padding-left: 0 !important;
}

.transfer-info-value {
  text-align: right;
  padding-right: 0 !important;
}

.ph-transfer-summary-table {
  background-color: transparent !important;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  height: 60px !important;
  width: 100%;
  margin: 10px 0;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  /* border: 2px solid #1A2791; */
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-transaction-container {
  padding: 0;
}

.ph-stepper-action-buttons {
  display: flex;
  margin-bottom: 100px;
}

.ph-primary-button.ph-stepper-button {
  height: 40px !important;
  width: 115px;
}

/* @media only screen and (min-width: 600px) {
  .v-stepper.ph-wu-receive-stepper .v-stepper__step {
    padding-left: 0;
  }
} */

.ph-wu-receive-summary-minified {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F4F4FA;
}

.ph-wu-receive-summary-content {
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
}

.ph-wu-receive-summary-minified-info {
  margin-right: auto;
}

.ph-wu-receive-summary-minified-info p {
  margin-bottom: 0;
}

.ph-wu-receive-summary-minified-action {
  margin-left: auto;
}

.ph-wu-receive-form {
  padding: 12px 0;
}

@media only screen and (min-width: 960px) {
  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 0;
  }
}
</style>
