import apigee from '@/api/apigee'

export default {
  getRemoteLocations (locationGroupID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/remote/locations?location_group_id=${locationGroupID}`)
  },
  getRemoteUsers (locationID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/remote/users?location_id=${locationID}`)
  },
  validateRemoteUser (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/remote/validate', payload)
  }
}
