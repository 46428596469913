import apigee from '@/api/apigee'

export default {
  getBranchList () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/mog-branch-receipt-grid')
  },
  getLocationInfo (locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/mog-branch-receipt-get?location_id=${locationId}`)
  },
  getSeriesByBranch (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/mog-get-all-series-number?location_id=${payload.location_id}&type=${payload.type}`)
  },
  getAvailableSeriesNo (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/mog-get-available-series-number?location_id=${payload.location_id}&type=${payload.type}`)
  },
  updateSeriesNo (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mog-update-available-series-number', payload)
  },
  createBranchDetail (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mog-insert', payload)
  },
  enrollEReceipt (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mog-ereceipt-application-enrollment', payload)
  },
  updateEReceipt (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mog-ereceipt-application-update', payload)
  },
  updateReceiptType (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mog-toggle-receipt-type', payload)
  }
}
