import apigee from '@/api/apigee'

export default {
  retrieveData (trxDate) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/mc-rate-general-grid?trx_date=${trxDate}`)
  },
  saveData (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mc-rate-general-insert', payload)
  },
  updateStatus (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mc-rate-update-status', payload)
  }
}
