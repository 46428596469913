import api from '@/api'
import apigee from '@/api/apigee'
// import newkycgatewayv2 from '@/api/newkycgatewayv2'
// import uspmaintenance from '@/api/uspmaintenance'

export default {
  getCICOList (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/cashincashout?trx_date=${payload.trxDate}&location_id=${payload.locationId}`)
  },
  trxCICOValidate (payload) {
    return api.post('/v1/cico/wrapper/validate', payload)
  },
  trxCICOExecute (payload) {
    return api.post('/v1/cico/wrapper/execute', payload)
  },
  trxCICOInquire (payload) {
    return api.post('/v1/cico/wrapper/inquiry', payload)
  },
  trxCICORetry (payload) {
    return api.post('/v1/cico/wrapper/retry', payload)
  },
  trxOTPCustomer (payload) {
    return api.post('/v1/cico/wrapper/otp', payload)
  },
  trxEONCashIn (payload) {
    return api.post('/v1/cico/eon/ibl/cashin', payload)
  },
  trxEONCashOut (payload) {
    return api.post('/v1/cico/eon/ibl/cashout', payload)
  },
  trxEONRetry (payload) {
    return api.post('/v1/cico/eon/ibl/retry', payload)
  },
  trxOTPSend (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/otp-cashout/verification', payload)
  },
  trxOTPPerahub (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/otp-cashout/fla-verification', payload)
  },
  trxPHVCashOutExecute (payload) {
    return api.post('v1/cico/eon/ibl/debit-account', payload)
  },
  trxPHVCashOutRetry (payload) {
    return api.post('v1/cico/eon/ibl/transaction-inquiry', payload)
  },
  generateCICOVoucher (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/receipt/print-voucher', payload)
  },
  getCICOProviders () {
    return apigee.get('/uspdata/v1/usp-maintenance/cico/providers')
  }
}
