import apigee from '@/api/apigee'

export default {
  grid (trxDate, locationID) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/trxair?trx_date=${trxDate}&location_id=${locationID}`)
  },
  saveAirAsia (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/pera-dali', payload)
  },
  saveCebuPacific (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/trxair', payload)
  },
  avpServiceFee (locationID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/avp-sf?location_id=${locationID}&service_id=31`)
  }
}
