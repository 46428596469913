import apigee from '@/api/apigee'

export default {
  retrieveData (trxDate, locationId, userId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/trx-user-cash-transfer-grid?trx_date=${trxDate}&location_id=${locationId}&user_id=${userId}`)
  },
  saveData (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/trx-user-cash-transfer-insert', payload)
  },
  getUsers (trxDate, locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/users-grid-cash-transfer?trx_date=${trxDate}&location_id=${locationId}`)
  }
}
