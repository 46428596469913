import apigee from '@/api/apigee'

export default {
  printMalayan (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-malayan', payload)
  },
  printMalayanNew (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-malayan-new', payload)
  },
  reprintMalayan (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/display-malayan', payload)
  },
  printPeraPayapa (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-pera-payapa', payload)
  },
  printDengueProtectPlus (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-dengue-protect-plus', payload)
  },
  printDengueProtectv2 (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-dengue-protect-v2', payload)
  },
  printCovidProtectPlus (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-covid-a', payload)
  },
  printCTPL (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/print-ctpl', payload)
  }
}
