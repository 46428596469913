import apigee from '@/api/apigee'

export default {
  getBillers () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/billspay/billers')
  },
  getBillerByID (id) {
    return apigee.get(`/uspdata/v1/usp-transaction/maintenance/billspay/billers/${id}`)
  },
  getCategories () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/biller-category/1')
  },
  addBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/billers-add', payload)
  },
  updateBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/billers-update', payload)
  },
  deleteBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/billers-delete', payload)
  },
  switchBillerStatus (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/billers-activate-deactivate', payload)
  },
  getPartners () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/billspay/partners')
  },
  addPartner (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/partners-add', payload)
  },
  updatePartner (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/partners-update', payload)
  },
  deletePartner (id) {
    return apigee.delete(`/uspdata/v1/usp-transaction/maintenance/billspay/partners/${id}`)
  },
  switchPartnerStatus (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/partner-activate-deactivate', payload)
  },
  getCommonBillers () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/biller-hierarchy')
  },
  addCommonBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/biller-hierarchy-add', payload)
  },
  updateCommonBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/biller-hierarchy-update', payload)
  },
  deleteCommonBiller (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/biller-hierarchy-delete', payload)
  },
  getApprovalRequests () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/billspay/request-list')
  },
  approveRequest (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/maintenance/billspay/approve-request', payload)
  },
  disapproveRequest (id, payload) {
    return apigee.put(`/uspdata/v1/usp-transaction/maintenance/billspay/pending-request/${id}`, payload)
  }
}
