<template>
  <div class="px-md-4 mt-3">
    <TransactionProfileCard />
    <v-form ref="form">
      <div class="mb-4 mb-md-0" v-if="!hasSelectedCustomer">
        <v-row no-gutters>
          <v-col class="mt-2" :cols="12">
            <p class="caption">
              Please choose one of the following:
            </p>
            <v-btn
              @click="selectSearchBy('by_id')"
              :class="['mr-1 mb-1 rounded-lg', { 'selected': transactionData.searchBy === 'by_id' }]"
              color="grey darken-1"
              outlined
            >
              ID
            </v-btn>
            <v-btn
              @click="selectSearchBy('by_mobile')"
              :class="['mx-1 mb-1 rounded-lg', { 'selected': transactionData.searchBy === 'by_mobile' }]"
              color="grey darken-1"
              outlined
            >
              Mobile Number
            </v-btn>
            <v-btn
              @click="selectSearchBy('by_wu_card')"
              :class="['ml-1 mb-1 rounded-lg', { 'selected': transactionData.searchBy === 'by_wu_card' }]"
              color="grey darken-1"
              outlined
            >
              My WU
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-md-4" no-gutters v-if="transactionData.searchBy === 'by_id'">
          <v-col class="mt-4 mt-md-8" :cols="12">
            <strong>Primary ID</strong>
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="5">
            <v-autocomplete
              v-model="idType"
              :items="collections.wuIDList"
              item-text="id_type"
              item-value="id_value"
              class="ph-textbox mb-md-6 mr-md-1"
              label="ID Type*"
              outlined
            />
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="5">
            <v-text-field
              v-model="idNumber"
              class="ph-textbox mb-md-6 mx-md-1"
              label="ID Number*"
              outlined
            />
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="2">
            <v-btn @click="search" class="ml-md-1 rounded-lg" color="primary" block x-large>Search</v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-md-4" no-gutters v-if="transactionData.searchBy === 'by_mobile'">
          <v-col class="mt-4 mt-md-8" :cols="12">
            <strong>Mobile Number</strong>
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="10">
            <v-text-field
              v-model="mobileNumber"
              class="ph-textbox mb-md-6 mr-md-1"
              label="Mobile Number*"
              type="number"
              hide-spin-buttons
              outlined
            />
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="2">
            <v-btn @click="search" class="ml-md-1 rounded-lg" color="primary" block x-large>Search</v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-md-4" no-gutters v-if="transactionData.searchBy === 'by_wu_card'">
          <v-col class="mt-4 mt-md-8" :cols="12">
            <strong>My WU</strong>
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="10">
            <v-text-field
              v-model="myWUNumber"
              class="ph-textbox mb-md-6 mr-md-1"
              label="My WU Number*"
              outlined
            />
          </v-col>
          <v-col class="mt-3 mt-md-5" :cols="12" :md="2">
            <v-btn @click="search" class="ml-md-1 rounded-lg" color="primary" block x-large>Search</v-btn>
          </v-col>
        </v-row>
      </div>
      <strong>Basic Information</strong>
      <v-row class="mt-3 mt-md-4 mb-md-4" no-gutters>
        <v-col :cols="12" :md="6">
          <v-autocomplete
            v-model="selectedCustomer.occupation"
            :items="collections.occupation"
            item-text="occupation"
            item-value="occupation_value"
            class="ph-textbox mb-4 mb-md-6 mr-md-1"
            label="Occupation"
            :rules="requiredField"
            outlined
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-autocomplete
            v-model="selectedCustomer.source_fund"
            :items="collections.sourceOfFund"
            item-text="source_of_funds"
            item-value="source_of_funds"
            class="ph-textbox mb-4 mb-md-6 ml-md-1"
            label="Source of Funds"
            :rules="requiredField"
            outlined
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-autocomplete
            v-model="selectedCustomer.relation_to"
            :items="collections.relationship"
            item-text="relationship"
            item-value="relationship"
            class="ph-textbox mb-4 mb-md-6 mr-md-1"
            label="Relationship to Receiver"
            :rules="requiredField"
            outlined
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-autocomplete
            v-model="selectedCustomer.purpose_transaction"
            :items="collections.purposeOfTransaction"
            item-text="purpose"
            item-value="purpose"
            class="ph-textbox mb-4 mb-md-6 ml-md-1"
            label="Purpose of Transaction"
            :rules="requiredField"
            outlined
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-text-field
            v-model="selectedCustomer.country_birth"
            class="ph-textbox mb-4 mb-md-6 mr-md-1"
            label="Country of Birth"
            :rules="requiredField"
            disabled
            outlined
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-autocomplete
            v-model="selectedCustomer.nationality"
            :items="collections.countries"
            item-text="nationality"
            item-value="nationality"
            class="ph-textbox mb-4 mb-md-6 ml-md-1"
            label="Nationality"
            :rules="requiredField"
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <!-- <strong>Transaction Verification</strong>
    <v-row class="mt-md-4" no-gutters>
      <v-col :cols="12">
        <p class="body-2">
          Is transaction on behalf of Individual or Organization?
        </p>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-select
          v-model="transactionData.isOnBehalf"
          :items="choices"
          class="ph-textbox mb-md-6"
          label="Answer*"
          outlined
        />
      </v-col>
    </v-row> -->
    <div class="mt-6 d-flex justify-space-between">
      <v-btn
        @click="prev"
        class="rounded-lg font-weight-bold"
        color="primary"
        large
        outlined
      >
        Back
      </v-btn>
      <v-btn
        @click="next"
        class="rounded-lg font-weight-bold"
        color="primary"
        :disabled="!hasSelectedCustomer"
        large
      >
        Next
      </v-btn>
    </div>
    <v-dialog v-model="resultDialog" persistent width="60%">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            WU Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectWUCustomer"
            :headers="headers"
            :items="searchResult"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.wu_no`]="{ item }">
              {{ item.mywu_details.mywu_number || '-' }}
            </template>
            <template v-slot:[`item.customer_name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(`${item.name.last_name}, ${item.name.first_name}`)">
                <span class="white--text text-body-2">{{ avatarInitial(`${item.name.last_name}, ${item.name.first_name}`) }}</span>
              </v-avatar>
              {{ toProperCase(item.name.first_name) }} {{ toProperCase(item.name.last_name) }}
            </template>
            <template v-slot:[`item.postal_code`]="{ item }">
              {{ item.address.postal_code || '-' }}
            </template>
            <template v-slot:[`item.country_code`]="{ item }">
              {{ item.address.country_details.ctry_code || '-' }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import {
  toProperCase,
  avatarColor,
  avatarInitial,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      transactionData: 'send/transactionData',
      kycLookupData: 'send/kycLookupData',
      remoteData: 'obo/remoteData'
    }),
    hasSelectedCustomer () {
      const { remcoID } = this.transactionData

      if (remcoID !== 0 || (remcoID === 0 && this.kycLookupData)) return true

      return false
    },
    customerID () {
      let img = this.selectedCustomer.img || '{}'
      img = JSON.parse(img)
      return Object.values(img)
    }
  },
  components: {
    TransactionProfileCard
  },
  data () {
    return {
      idType: '',
      idNumber: '',
      mobileNumber: '',
      myWUNumber: '',
      resultDialog: false,
      searchResult: [],
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      requiredField: [
        v => !!v || 'This field is required'
      ],
      headers: [
        {
          text: 'WU No.',
          sortable: true,
          value: 'wu_no'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customer_name'
        },
        {
          text: 'Postal Code',
          sortable: true,
          value: 'postal_code'
        },
        {
          text: 'Country Code',
          sortable: true,
          value: 'country_code'
        }
      ],
      choices: [
        {
          text: 'Yes',
          value: 'Y'
        },
        {
          text: 'No',
          value: 'N'
        }
      ]
    }
  },
  methods: {
    renderToast,
    prev () {
      this.$store.commit('send/SET_CURRENT_STEP', 1)
    },
    next () {
      if (this.$refs.form?.validate()) {
        this.$store.commit('send/SET_CURRENT_STEP', 3)
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to proceed with the transaction')
      }
    },
    getGalacticID (data) {
      if (data) {
        return data.split('J619')[1].substr(0, 19)
      }
      return ''
    },
    async getWUDetails () {
      const payload = {
        foreign_reference_no: this.transactionData.refNo,
        search_by: this.transactionData.searchBy,
        id_type: this.idType,
        id_number: this.idNumber,
        contact_phone: this.mobileNumber,
        mywu_number: this.myWUNumber,
        first_name: '',
        last_name: '',
        is_multi: 'S',
        operator_id: this.operatorId,
        terminal_id: this.terminalId,
        location_id: this.locationId
      }

      const req = await this.$store.dispatch('send/kycLookup', payload)

      if (req.code === 200) {
        const { customer, receiver } = req.result

        const galacticID = this.getGalacticID(customer.compliance_details?.compliance_data_buffer)

        this.$store.commit('send/SET_KYC_LOOKUP_DATA', req.result)

        if (galacticID) {
          this.$store.commit('send/SET_TRANSACTION_DATA', {
            prop: 'galacticID',
            value: galacticID
          })
        }

        let receiverList = []

        if (receiver) {
          if (receiver.number_matches > 1) {
            receiverList = receiver.receiver.map(({ name, address }) => {
              return {
                first_name: name.first_name,
                last_name: name.last_name,
                country_code: address.country_details.ctry_code
              }
            })
          } else if (receiver.number_matches === 1) {
            const { name, address } = receiver.receiver

            receiverList.push({
              first_name: name.first_name,
              last_name: name.last_name,
              country_code: address.country_details.ctry_code
            })
          }
        }

        if (receiverList.length > 0) {
          this.$store.commit('send/SET_RECEIVER_LIST', receiverList)
        }
      } else {
        // Handle WU Error
      }
    },
    async search () {
      if (this.transactionData.searchBy === 'by_wu_card') {
        this.getWUDetails()
      } else {
        const payload = {
          foreign_reference_no: this.transactionData.refNo,
          search_by: this.transactionData.searchBy,
          id_type: this.idType,
          id_number: this.idNumber,
          contact_phone: this.mobileNumber,
          mywu_number: this.myWUNumber,
          first_name: '',
          last_name: '',
          is_multi: 'M',
          operator_id: this.operatorId,
          terminal_id: this.terminalId,
          location_id: this.locationId
        }

        if (this.terminalId === '' || this.terminalId === null || this.terminalId === undefined) {
          this.renderToast('error', 'Error', 'Terminal ID is Missing. Kindly Relogin.')
        } else if (this.operatorId === '' || this.operatorId === null || this.operatorId === undefined) {
          this.renderToast('error', 'Error', 'Operator ID is Missing. Kindly Relogin.')
        } else if (this.idNumber === '' || this.idType === '') {
          if (this.idNumber === '') {
            this.renderToast('error', 'Incomplete Fields', 'ID Number is required')
          } else if (this.idType === '') {
            this.renderToast('error', 'Incomplete Fields', 'ID Type is required')
          } else if (this.idNumber === '' && this.idType === '') {
            this.renderToast('error', 'Incomplete Fields', 'ID Type and ID Number is required')
          }
        } else {
          const req = await this.$store.dispatch('send/kycLookup', payload)

          if (req.code === 200) {
            const { customer } = req.result

            if (customer.number_matches > 1) {
              this.searchResult = customer.customer
              this.resultDialog = true
            } else {
              this.myWUNumber = customer.customer?.mywu_details?.mywu_number
              this.getWUDetails()
            }
          } else if (req.code === 'WU' && req.message === 'T1234 NO RECORD FOUND') {
            this.$store.commit('send/SET_KYC_LOOKUP_DATA', {})
          } else {
            // Handle WU Error
            console.log(req)
          }
        }
      }
    },
    selectWUCustomer (data) {
      this.myWUNumber = data.mywu_details?.mywu_number
      this.getWUDetails()
      this.resultDialog = false
    },
    selectSearchBy (type) {
      this.$store.commit('send/SET_TRANSACTION_DATA', {
        prop: 'searchBy',
        value: type
      })
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async mounted () {
    if (this.transactionData.remcoID === 0) await this.$store.dispatch('getIDList', 'wu')

    this.$store.commit('SET_PAGE_TITLE', 'KYC Lookup')

    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }

    if (this.selectedCustomer) {
      if (this.customerID.length > 0) {
        const wuIDDetails = this.collections.wuIDList.filter(item => item.id_type === this.customerID[0].id_type)

        if (wuIDDetails.length > 0) {
          this.idType = wuIDDetails[0].id_value
        }

        this.idNumber = this.customerID[0].id_number
      }

      if (this.selectedCustomer.mobile_no) {
        this.mobileNumber = this.selectedCustomer.mobile_no
      }

      if (this.selectedCustomer.wu_card_no) {
        this.myWUNumber = (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : ''
      }
    }
  }
}
</script>
