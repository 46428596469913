import { endpoints } from '@/api/endpoints'

const manualReceipt = endpoints.use('manualReceipt')

export default {
  getBranchList: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.getBranchList(payload)
      .then(({ data }) => {
        data.result.forEach(item => {
          item.is_ereceipt_enabled = item.ereceipt_enrollment_status === 'Enrolled' && item.receipt_type === 'E-receipt'
        })

        commit('SET_BRANCH_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getAvailableSeriesNo: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.getAvailableSeriesNo(payload)
      .then(({ data }) => {
        if (data.result.length > 0) {
          commit('SET_SERIES_DETAIL', data.result[0])
        } else {
          commit('SET_SERIES_DETAIL', {})
        }
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateSeriesNo: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.updateSeriesNo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getSeriesByBranch: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.getSeriesByBranch(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getLocationInfo: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.getLocationInfo(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  createBranchDetail: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.createBranchDetail(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  enrollEReceipt: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.enrollEReceipt(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateEReceipt: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.updateEReceipt(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateReceiptType: ({ commit }, payload) => new Promise((resolve, reject) => {
    manualReceipt.updateReceiptType(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
