import api from '@/api'
import apigee from '@/api/apigee'

export default {
  getCardTransactions (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/debitcard?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  registerPHVCard (payload) {
    return api.post('/v1/banks/eon/api/eon/register/card', payload)
  },
  getCardStatus (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/lookup-card?card_number=${payload}`)
  },
  getEONSourceOfFund () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/eon-source-of-fund')
  },
  getEONEmployment () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/eon-employment')
  },
  getEONNatureWork () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/eon-nature-of-work')
  },
  getEONIdList () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/eon-id-list')
  },
  getCardInventory (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/cards?location_id=${payload.location_id}&card_category=${payload.card_category}&current_status=${payload.current_status}`)
  },
  updateCardInventory (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/cards/${payload.id}`, payload.data)
  },
  requestCardEmail (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/request-cards', payload)
  },
  inquireCardNumber (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/card-inquiry', payload)
  },
  savePendingVirtualCard (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/virtualcard', payload)
  },
  updatePendingVirtualCard (id, payload) {
    return apigee.put(`/uspdata/v1/usp-transaction/trx/virtualcard/${id}`, payload)
  },
  savePendingDebitCard (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/debitcard', payload)
  },
  updatePendingDebitCard (id, payload) {
    return apigee.put(`/uspdata/v1/usp-transaction/trx/debitcard/${id}`, payload)
  }
}
