import apigee from '@/api/apigee'

export default {
  generateConsent (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/generate-data-consent', payload)
  },
  checkCustomerConsent (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/check-data-consent', payload)
  },
  getLatestConsent (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/get-latest-consent', payload)
  }
}
