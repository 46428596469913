import apigee from '@/api/apigee'

export default {
  retrieveData (trxDate, locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/adjustment-and-expenses-grid?location_id=${locationId}&trx_date=${trxDate}`)
  },
  saveData (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/adjustment-and-expenses-insert', payload)
  },
  deleteRecord (id) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/maintenance/adjustment-and-expenses-delete/${id}`)
  },
  getCount (trxDate, locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/adjustment-and-expenses-get-count?location_id=${locationId}&trx_date=${trxDate}`)
  },
  getTypes () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/petty-cash-category-grid')
  }
}
