import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Middleware from '@/middleware'
import Default from '@/layouts/Default.vue'
import SinglePage from '@/layouts/SinglePage.vue'
import NotFound from '@/views/NotFound.vue'
// import Login from '@/views/Login.vue'
// import VerifyOneTimeCode from '@/views/VerifyOneTimeCode.vue'
// import ResetPassword from '@/views/ResetPassword.vue'
// import ForgetPassword from '@/views/ForgetPassword.vue'
import Dashboard from '@/views/Dashboard.vue'
import Customers from '@/views/BOS/Customers.vue'
import NewCustomer from '@/views/BOS/NewCustomer.vue'
import ViewCustomer from '@/views/BOS/ViewCustomer.vue'
import Account from '@/views/Account.vue'
import Receipt from '@/views/Receipt/Index.vue'
import BillsPaymentReceipt from '@/views/PeraBills/PaymentReceipt.vue'
import PeraRemitLanding from '@/views/PeraRemit/Landing.vue'
import PeraRemitReceive from '@/views/PeraRemit/ReceiveMoney/ReceiveIndex.vue'
import PeraRemitSend from '@/views/PeraRemit/SendMoney/SendIndex.vue'
import PeraRemitSendNew from '@/views/PeraRemit/SendMoney/SendNew.vue'
import PeraRemitSendFulfill from '@/views/PeraRemit/SendMoney/SendMoneyFulfill.vue'
import PeraRemitManualEntry from '@/views/PeraRemit/ManualEntry/ManualIndex.vue'
import PeraRemitManualSend from '@/views/PeraRemit/ManualEntry/ManualSend.vue'
import PeraRemitManualReceive from '@/views/PeraRemit/ManualEntry/ManualReceive.vue'
import PeraRemitManualUpdate from '@/views/PeraRemit/ManualEntry/UpdateEntry.vue'
import PeraRemitQuickpay from '@/views/PeraRemit/QuickPay/Quickpay.vue'
import PeraRemitQuickpayNew from '@/views/PeraRemit/QuickPay/QuickpayNew.vue'
import NonExReceiveNew from '@/views/PeraRemit/ReceiveMoney/Nonex/ReceiveMoneyNewTrx.vue'
import WUReceiveNew from '@/views/PeraRemit/ReceiveMoney/WU/ReceiveMoneyNewWUTrx.vue'
import WUReceiveFulfill from '@/views/PeraRemit/ReceiveMoney/WU/ReceiveMoneyFulfill.vue'
import PayStatus from '@/views/PeraRemit/PayStatus.vue'
import F2Zoom from '@/views/PeraRemit/F2Zoom.vue'
import UserManagement from '@/views/Admin/UserManagement.vue'
import OBO from '@/views/PeraRemit/OBO.vue'
import PeraProtect from '@/views/Insurance/PeraProtectIndex.vue'
import PeraProtectNew from '@/views/Insurance/PeraProtectNew.vue'
import PeraCICO from '@/views/PeraCICO/CICOIndex.vue'
import PeraCashIn from '@/views/PeraCICO/PeraCashIn.vue'
import PeraCashOut from '@/views/PeraCICO/PeraCashOut.vue'
import PeraBillsLanding from '@/views/PeraBills/Landing.vue'
import PeraBillsPayment from '@/views/PeraBills/Payments/BillsPaymentIndex.vue'
import PeraBillsPaymentNew from '@/views/PeraBills/Payments/BillsPaymentNewTrx.vue'
import PeraBillsInquire from '@/views/PeraBills/Inquire.vue'
import PeraBillsCollection from '@/views/PeraBills/Collections/BillsCollectionIndex.vue'
import PeraBillsCollectionNew from '@/views/PeraBills/Collections/BillsCollectionNewTrx.vue'
import PeraPalit from '@/views/MoneyChange/PeraPalitIndex.vue'
import PeraPalitNewBuy from '@/views/MoneyChange/PeraPalitNewBuy.vue'
import PeraPalitNewSell from '@/views/MoneyChange/PeraPalitNewSell.vue'
import PeraLoad from '@/views/ELoad/PeraLoadIndex.vue'
import PeraLoadNew from '@/views/ELoad/PeraLoadNew.vue'
import BillsVoucherReceipt from '@/views/PeraBills/Voucher.vue'
import PHVCMaintenance from '@/views/Admin/PHVCMaintenance.vue'
import CardsLanding from '@/views/Cards/Landing.vue'
import PeraBank from '@/views/Cards/PeraBank/PeraBankIndex.vue'
import PeraBankNew from '@/views/Cards/PeraBank/PeraBankNewTrx.vue'
import PHVCardInventory from '@/views/Cards/PHVCardInventory.vue'
import PeraDebitCard from '@/views/Cards/PeraDebitCard/PeraDebitCardIndex.vue'
import PeraDebitCardNew from '@/views/Cards/PeraDebitCard/PeraDebitCardNewTrx.vue'
import UserRoleManagement from '@/views/Admin/UserRoleManagement.vue'
import LoansLanding from '@/views/Loans/Landing.vue'
import BillerMaintenance from '@/views/Admin/BillerMaintenance.vue'
import PartnerMaintenance from '@/views/Admin/PartnerMaintenance.vue'
import BillerPrioritization from '@/views/Admin/BillerPrioritization.vue'
import BillerApproval from '@/views/Admin/BillerApproval.vue'
import HitListDashboard from '@/views/BOS/HitListDashboard.vue'
import PeraTravel from '@/views/PeraTravel/PeraTravelIndex.vue'
import PeraTravelNew from '@/views/PeraTravel/PeraTravelNew.vue'
import LoansCollection from '@/views/Loans/LoanCollections/LoanCollectionIndex.vue'
import LoansCollectionNew from '@/views/Loans/LoanCollections/LoanCollectionNewTrx.vue'
import PeraDali from '@/views/Loans/PeraDali/PeraDaliIndex.vue'
import PeraDaliNew from '@/views/Loans/PeraDali/PeraDaliNewTrx.vue'
import LoanVoucherReceipt from '@/views/Loans/Voucher.vue'
import PeraLoan from '@/views/Loans/PeraLoan/PeraLoanIndex.vue'
import PeraLoanNew from '@/views/Loans/PeraLoan/PeraLoanNewTrx.vue'
import ReportsIndex from '@/views/Reports/ReportsIndex.vue'
import GenerateReportForm from '@/views/Reports/GenerateReportForm.vue'
import ReportViewer from '@/views/Reports/ReportViewer.vue'
import PromoMaintenance from '@/views/Promos/PromoMaintenanceIndex.vue'
import PromoMaintenanceNew from '@/views/Promos/PromoMaintenanceNew.vue'
import PromoMaintenanceEdit from '@/views/Promos/PromoMaintenanceEdit.vue'
import TransactionLimit from '@/views/TransactionLimit/TransactionLimitIndex.vue'
import TransactionLimitDefaultIndex from '@/views/TransactionLimit/DefaultLimit/DefaultLimitIndex.vue'
import TransactionLimitDefaultLocationForm from '@/views/TransactionLimit/DefaultLimit/LocationLimitForm.vue'
import TransactionLimitDefaultLocationUpdateForm from '@/views/TransactionLimit/DefaultLimit/LocationLimitUpdateForm.vue'
import TransactionLimitDefaultCustomerForm from '@/views/TransactionLimit/DefaultLimit/CustomerLimitForm.vue'
import TransactionLimitDefaultCustomerUpdateForm from '@/views/TransactionLimit/DefaultLimit/CustomerLimitUpdateForm.vue'
import TransactionLimitDefaultUploadFile from '@/views/TransactionLimit/DefaultLimit/UploadFile.vue'
import TransactionLimitApprovers from '@/views/TransactionLimit/Approvers/ApproversIndex.vue'
import TransactionLimitApproversForm from '@/views/TransactionLimit/Approvers/ApproverForm.vue'
import TransactionLimitApproversUpdateForm from '@/views/TransactionLimit/Approvers/ApproverUpdateForm.vue'
import TransactionLimitIncreaseRequests from '@/views/TransactionLimit/IncreaseRequests/IncreaseRequestsIndex.vue'
import TransactionLimitIncreaseLocationRequestForm from '@/views/TransactionLimit/IncreaseRequests/IncreaseLocationLimitForm.vue'
import TransactionLimitIncreaseLocationRequestUpdateForm from '@/views/TransactionLimit/IncreaseRequests/IncreaseLocationLimitUpdateForm.vue'
import TransactionLimitIncreaseCustomerRequestForm from '@/views/TransactionLimit/IncreaseRequests/IncreaseCustomerLimitForm.vue'
import TransactionLimitIncreaseCustomerRequestUpdateForm from '@/views/TransactionLimit/IncreaseRequests/IncreaseCustomerLimitUpdateForm.vue'
import TransactionLimitApprovalRequests from '@/views/TransactionLimit/LimitApproval/RequestsIndex.vue'
import TransactionLimitApprovalLocationRequestUpdateForm from '@/views/TransactionLimit/LimitApproval/LocationLimitUpdateForm.vue'
import TransactionLimitApprovalCustomerRequestUpdateForm from '@/views/TransactionLimit/LimitApproval/CustomerLimitUpdateForm.vue'
import TransactionLimitProviders from '@/views/TransactionLimit/Providers/ProvidersIndex.vue'
import TransactionLimitProvidersForm from '@/views/TransactionLimit/Providers/ProviderForm.vue'
import TransactionLimitProvidersUpdateForm from '@/views/TransactionLimit/Providers/ProviderUpdateForm.vue'
import TransactionLimitMonitoring from '@/views/TransactionLimit/Monitoring/MonitoringIndex.vue'
import TransactionLimitMonitoringGenerateReport from '@/views/TransactionLimit/Monitoring/GenerateReport.vue'
import TransactionLimitMonitoringPreviewReport from '@/views/TransactionLimit/Monitoring/PreviewReport.vue'
import WUPaymentsLanding from '@/views/WUPayments/Landing.vue'
import WUPaymentsPaymentOrder from '@/views/WUPayments/PaymentOrder/Index.vue'
import WUPaymentsPaymentOrderNew from '@/views/WUPayments/PaymentOrder/NewTransaction.vue'
import WUPaymentsRefundOrder from '@/views/WUPayments/RefundOrder/Index.vue'
import WUPaymentsRefundOrderNew from '@/views/WUPayments/RefundOrder/NewTransaction.vue'
import WUPaymentSuccessPage from '@/views/WUPayments/WUPaymentSuccessPage.vue'
import UserTrustedDevices from '@/views/Admin/UserTrustedDevices.vue'
import PRPDeposit from '@/views/PRPDeposit/DepositIndex.vue'
import ComplianceModule from '@/views/Compliance/Landing.vue'
import ComplianceAdverse from '@/views/Compliance/Adverse/Index.vue'
import ComplianceAdverseNew from '@/views/Compliance/Adverse/NewEntry.vue'
import ComplianceAdverseUpload from '@/views/Compliance/Adverse/UploadBulk.vue'
import ComplianceAdverseUpdate from '@/views/Compliance/Adverse/UpdateEntry.vue'
import CompliancePEP from '@/views/Compliance/PEP/Index.vue'
import CompliancePEPNew from '@/views/Compliance/PEP/NewEntry.vue'
import CompliancePEPUpdate from '@/views/Compliance/PEP/UpdateEntry.vue'
import ComplianceNegativeList from '@/views/Compliance/NegativeList/Index.vue'
import ComplianceNegativeListNew from '@/views/Compliance/NegativeList/NewEntry.vue'
import ComplianceNegativeListUpdate from '@/views/Compliance/NegativeList/UpdateEntry.vue'
import ComplianceMonitoring from '@/views/Compliance/Monitoring/Index.vue'
import ComplianceMonitoringViewCustomer from '@/views/Compliance/Monitoring/ViewCustomer.vue'
import ComplianceWhitelisting from '@/views/Compliance/Whitelisting/Index.vue'
import ComplianceWhitelistingViewCustomer from '@/views/Compliance/Whitelisting/ViewCustomer.vue'
import ExcessFundRequests from '@/views/Admin/ExcessFundRequests.vue'
import CashCount from '@/views/BOS/CashCount/CashCountIndex.vue'
import CashCountForm from '@/views/BOS/CashCount/CashCountForm.vue'
import InterBranchTransfer from '@/views/BOS/InterBranchTransfer/InterBranchTransferIndex.vue'
import InterBranchTransferForm from '@/views/BOS/InterBranchTransfer/InterBranchTransferForm.vue'
import InterBranchTransferRequests from '@/views/Admin/InterBranchTransferRequests.vue'
import IBTReceipt from '@/views/Receipt/IBTReceipt.vue'
import MCRateRequest from '@/views/MCRateRequest/Index.vue'
import MCRateRequestNewEntry from '@/views/MCRateRequest/NewEntry.vue'
import CashTransfer from '@/views/CashTransfer/Index.vue'
import CashTransferNewEntry from '@/views/CashTransfer/NewEntry.vue'
import AdjustmentAndExpenses from '@/views/AdjustmentAndExpenses/Index.vue'
import AdjustmentAndExpensesNewEntry from '@/views/AdjustmentAndExpenses/NewEntry.vue'
import ReplenishBranch from '@/views/ReplenishBranch/Index.vue'
import ReplenishBranchNewEntry from '@/views/ReplenishBranch/NewEntry.vue'
import Turnover from '@/views/Turnover/Index.vue'
import MCRate from '@/views/MCRate/Landing.vue'
import MCRateGeneralSetup from '@/views/MCRate/GeneralSetup/Index.vue'
import MCRateGeneralSetupNew from '@/views/MCRate/GeneralSetup/NewEntry.vue'
import MCRateRequests from '@/views/MCRate/Requests/Index.vue'
import NonexAdjustmentApproval from '@/views/Admin/NonexAdjustmentApproval.vue'
import NonexAdjustmentIndex from '@/views/PeraRemit/NonexAdjustment/NonexAdjustmentIndex.vue'
import NonexAdjustmentForm from '@/views/PeraRemit/NonexAdjustment/NonexAdjustmentForm.vue'
import ComplianceAuditTrail from '@/views/Compliance/AuditTrail/Index.vue'
import ReceiptMaintenance from '@/views/Admin/ReceiptMaintenance.vue'
import Prompt from '@/views/Prompt/PromptLanding.vue'
import PromptOffers from '@/views/Prompt/Offers/OffersIndex.vue'
import PromptOffersNew from '@/views/Prompt/Offers/NewEntry.vue'
import PromptOffersUpdate from '@/views/Prompt/Offers/UpdateEntry.vue'
import PromptQualifiedCustomers from '@/views/Prompt/QualifiedCustomers/QualifiedCustomersIndex.vue'

const uspAuthAppURL = 'https://usp20-auth.perahub.com.ph'
const userData = VueCookies.get('USP_DATA')
const userToken = VueCookies.get('USP_TOKEN')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '',
      layout: SinglePage,
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter (to, from, next) {
      if (!userData || !userToken) {
        location.href = `${uspAuthAppURL}/login`
      } else {
        next('/')
      }
    }
  },
  {
    path: '/verify-otp-code',
    name: 'VerifyOneTimeCode',
    beforeEnter (to, from, next) {
      if (!userData || !userToken) {
        location.href = `${uspAuthAppURL}/verify-otp-code`
      } else {
        next('/')
      }
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    beforeEnter (to, from, next) {
      if (!userData || !userToken) {
        location.href = `${uspAuthAppURL}/forget-password`
      } else {
        next('/')
      }
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    beforeEnter (to, from, next) {
      if (!userData || !userToken) {
        location.href = `${uspAuthAppURL}/reset-password`
      } else {
        next('/')
      }
    }
  },
  {
    path: '/pera-remit',
    name: 'NonEx',
    component: PeraRemitLanding,
    meta: {
      title: 'Pera Remit',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/pera-remit/receive',
    name: 'PeraRemitReceive',
    component: PeraRemitReceive,
    meta: {
      title: 'Receive Money',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/receive/new',
    name: 'ReceiveMoneyNewTrx',
    component: NonExReceiveNew,
    meta: {
      title: 'Receive Money',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/receive/new-wu-trx',
    name: 'ReceiveMoneyWUNewTrx',
    component: WUReceiveNew,
    meta: {
      title: 'Receive Money with WU',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/receive/new-fulfill-trx',
    name: 'ReceiveMoneyFulfill',
    component: WUReceiveFulfill,
    meta: {
      title: 'Fulfill Receive Money',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/send',
    name: 'PeraRemitSend',
    component: PeraRemitSend,
    meta: {
      title: 'Send Money',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/send/new',
    name: 'PeraRemitSendNew',
    component: PeraRemitSendNew,
    meta: {
      title: 'Send Money',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/send/new-fulfill-trx',
    name: 'SendMoneyFulfill',
    component: PeraRemitSendFulfill,
    meta: {
      title: 'Fulfillment',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/quickpay',
    name: 'PeraRemitQuickpay',
    component: PeraRemitQuickpay,
    meta: {
      title: 'Quickpay',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/quickpay/new',
    name: 'PeraRemitQuickpayNew',
    component: PeraRemitQuickpayNew,
    meta: {
      title: 'Quickpay Transaction',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/pay-status',
    name: 'PayStatus',
    component: PayStatus,
    meta: {
      title: 'Pay Status',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/f2-zoom',
    name: 'F2Zoom',
    component: F2Zoom,
    meta: {
      title: 'F2 Zoom',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/manual-entry',
    name: 'PeraRemitManualEntry',
    component: PeraRemitManualEntry,
    meta: {
      title: 'Manual Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/manual-entry/send',
    name: 'PeraRemitManualSend',
    component: PeraRemitManualSend,
    meta: {
      title: 'Manual Send Entry',
      layout: Default,
      requiresAuth: true
    }
  },
  {
    path: '/pera-remit/manual-entry/receive',
    name: 'PeraRemitManualReceive',
    component: PeraRemitManualReceive,
    meta: {
      title: 'Manual Receive Entry',
      layout: Default,
      requiresAuth: true
    }
  },
  {
    path: '/pera-remit/manual-entry/update',
    name: 'PeraRemitManualUpdate',
    component: PeraRemitManualUpdate,
    meta: {
      title: 'Update Manual Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      title: 'Customers',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/customers/new',
    name: 'NewCustomer',
    component: NewCustomer,
    meta: {
      title: 'New Customer',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/customers/:crn',
    name: 'ViewCustomer',
    component: ViewCustomer,
    meta: {
      title: 'Customer Details',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: Receipt,
    meta: {
      title: 'Receipt',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/pera-bills/receipt',
    name: 'Bills Payment Receipt',
    component: BillsPaymentReceipt,
    meta: {
      title: 'Bills Payment Receipt',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/pera-bills/print-voucher',
    name: 'Bills Voucher Receipt',
    component: BillsVoucherReceipt,
    meta: {
      title: 'Bills Voucher Receipt',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/pera-remit/remote-location',
    name: 'OBO',
    component: OBO,
    meta: {
      title: 'Remote Location',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/users',
    name: 'UserManagement',
    component: UserManagement,
    meta: {
      title: 'User Management',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-protect',
    name: 'PeraProtect',
    component: PeraProtect,
    meta: {
      title: 'PERA Protect',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-protect/new',
    name: 'PeraProtectNew',
    component: PeraProtectNew,
    meta: {
      title: 'Pera Protect',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-cico',
    name: 'PeraCICO',
    component: PeraCICO,
    meta: {
      title: 'Pera CICO',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-cico/cash-in',
    name: 'PeraCashIn',
    component: PeraCashIn,
    meta: {
      title: 'Pera CICO',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-cico/cash-out',
    name: 'PeraCashOut',
    component: PeraCashOut,
    meta: {
      title: 'Pera CICO',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-bills',
    name: 'PeraBills',
    component: PeraBillsLanding,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/pera-bills/payments',
    name: 'PeraBillsPayment',
    component: PeraBillsPayment,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-bills/payments/new',
    name: 'PeraBillsPaymentNew',
    component: PeraBillsPaymentNew,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-bills/inquire',
    name: 'PeraBillsInquire',
    component: PeraBillsInquire,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-bills/collections',
    name: 'PeraBillsCollection',
    component: PeraBillsCollection,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-bills/collections/new',
    name: 'PeraBillsCollectionNew',
    component: PeraBillsCollectionNew,
    meta: {
      title: 'Pera Bills',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-palit',
    name: 'PeraPalit',
    component: PeraPalit,
    meta: {
      title: 'Pera Palit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-palit/buy',
    name: 'PeraPalitNewBuy',
    component: PeraPalitNewBuy,
    meta: {
      title: 'Pera Palit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-palit/sell',
    name: 'PeraPalitNewSell',
    component: PeraPalitNewSell,
    meta: {
      title: 'Pera Palit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-load',
    name: 'PeraLoad',
    component: PeraLoad,
    meta: {
      title: 'PERA Load',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-load/new',
    name: 'PeraLoadNew',
    component: PeraLoadNew,
    meta: {
      title: 'PERA Load',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/phvc-maintenance',
    name: 'PHVCMaintenance',
    component: PHVCMaintenance,
    meta: {
      title: 'PHVC Service Fee Maintenance',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards',
    name: 'Cards',
    component: CardsLanding,
    meta: {
      title: 'Cards',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/biller-maintenance',
    name: 'BillerMaintenance',
    component: BillerMaintenance,
    meta: {
      title: 'Biller Maintenance',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards/pera-bank',
    name: 'PeraBank',
    component: PeraBank,
    meta: {
      title: 'Pera Bank',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/partner-maintenance',
    name: 'PartnerMaintenance',
    component: PartnerMaintenance,
    meta: {
      title: 'Partner Maintenance',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards/pera-bank/new',
    name: 'PeraBankNew',
    component: PeraBankNew,
    meta: {
      title: 'Pera Bank',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/biller-prioritization',
    name: 'BillerPrioritization',
    component: BillerPrioritization,
    meta: {
      title: 'Biller Prioritization',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards/inventory',
    name: 'PHVCardInventory',
    component: PHVCardInventory,
    meta: {
      title: 'Card Inventory',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/user-role-management',
    name: 'UserRoleManagement',
    component: UserRoleManagement,
    meta: {
      title: 'User Role Management',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/biller-approval',
    name: 'BillerApproval',
    component: BillerApproval,
    meta: {
      title: 'Biller Approval',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/customer-hit-list',
    name: 'HitListDashboard',
    component: HitListDashboard,
    meta: {
      title: 'Hit List',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-travel',
    name: 'PeraTravel',
    component: PeraTravel,
    meta: {
      title: 'Pera Travel',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/pera-travel/new',
    name: 'PeraTravelNew',
    component: PeraTravelNew,
    meta: {
      title: 'Pera Travel',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/loans',
    name: 'Loans',
    component: LoansLanding,
    meta: {
      title: 'Loans',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/loans/collections',
    name: 'LoansCollection',
    component: LoansCollection,
    meta: {
      title: 'Loan Collections',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/loans/collections/new',
    name: 'LoansCollectionNew',
    component: LoansCollectionNew,
    meta: {
      title: 'Loan Collections',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/loans/pera-dali',
    name: 'PeraDali',
    component: PeraDali,
    meta: {
      title: 'Pera Dali',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/loans/pera-dali/new',
    name: 'PeraDaliNew',
    component: PeraDaliNew,
    meta: {
      title: 'Pera Dali',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/loans/print-voucher',
    name: 'Voucher Receipt',
    component: LoanVoucherReceipt,
    meta: {
      title: 'Voucher Receipt',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/loans/pera-loan',
    name: 'PeraLoan',
    component: PeraLoan,
    meta: {
      title: 'Pera Loan',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards/pera-debit-card',
    name: 'PeraDebitCard',
    component: PeraDebitCard,
    meta: {
      title: 'Pera Debit Card',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cards/pera-debit-card/new',
    name: 'PeraDebitCardNew',
    component: PeraDebitCardNew,
    meta: {
      title: 'Pera Debit Card',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/loans/pera-loan/new',
    name: 'PeraLoanNew',
    component: PeraLoanNew,
    meta: {
      title: 'Pera Loan',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsIndex,
    meta: {
      title: 'Reports',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/reports/:report',
    name: 'GenerateReportForm',
    component: GenerateReportForm,
    meta: {
      title: 'Generate Report',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/reports/:report/view',
    name: 'View Report',
    component: ReportViewer,
    meta: {
      title: 'View Report',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/promos',
    name: 'PromoMaintenance',
    component: PromoMaintenance,
    meta: {
      title: 'Promos',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/promos/new',
    name: 'PromoMaintenanceNew',
    component: PromoMaintenanceNew,
    meta: {
      title: 'Create Promo',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/promos/:id',
    name: 'PromoMaintenanceEdit',
    component: PromoMaintenanceEdit,
    meta: {
      title: 'Promo Details',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit',
    name: 'TransactionLimit',
    component: TransactionLimit,
    meta: {
      title: 'Transaction Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults',
    name: 'TransactionLimitDefaultIndex',
    component: TransactionLimitDefaultIndex,
    meta: {
      title: 'Default Limits',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults/location',
    name: 'TransactionLimitDefaultLocationForm',
    component: TransactionLimitDefaultLocationForm,
    meta: {
      title: 'Create Location Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults/location/:id',
    name: 'TransactionLimitDefaultLocationUpdateForm',
    component: TransactionLimitDefaultLocationUpdateForm,
    meta: {
      title: 'Update Location Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults/customer',
    name: 'TransactionLimitDefaultCustomerForm',
    component: TransactionLimitDefaultCustomerForm,
    meta: {
      title: 'Create Customer Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults/customer/:id',
    name: 'TransactionLimitDefaultCustomerUpdateForm',
    component: TransactionLimitDefaultCustomerUpdateForm,
    meta: {
      title: 'Update Customer Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/defaults/upload',
    name: 'TransactionLimitDefaultUploadFile',
    component: TransactionLimitDefaultUploadFile,
    meta: {
      title: 'Upload Preview',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/approvers',
    name: 'TransactionLimitApprovers',
    component: TransactionLimitApprovers,
    meta: {
      title: 'Approvers',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/approvers/new',
    name: 'TransactionLimitApproversForm',
    component: TransactionLimitApproversForm,
    meta: {
      title: 'New Approver',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/approvers/:id',
    name: 'TransactionLimitApproversUpdateForm',
    component: TransactionLimitApproversUpdateForm,
    meta: {
      title: 'Update Approver',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/increase-requests',
    name: 'TransactionLimitIncreaseRequests',
    component: TransactionLimitIncreaseRequests,
    meta: {
      title: 'Increase Requests',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/increase-requests/location',
    name: 'TransactionLimitIncreaseLocationRequestForm',
    component: TransactionLimitIncreaseLocationRequestForm,
    meta: {
      title: 'Create Location Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/increase-requests/location/:id',
    name: 'TransactionLimitIncreaseLocationRequestUpdateForm',
    component: TransactionLimitIncreaseLocationRequestUpdateForm,
    meta: {
      title: 'Update Location Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/increase-requests/customer',
    name: 'TransactionLimitIncreaseCustomerRequestForm',
    component: TransactionLimitIncreaseCustomerRequestForm,
    meta: {
      title: 'Create Customer Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/increase-requests/customer/:id',
    name: 'TransactionLimitIncreaseCustomerRequestUpdateForm',
    component: TransactionLimitIncreaseCustomerRequestUpdateForm,
    meta: {
      title: 'Update Customer Limit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/limit-approval',
    name: 'TransactionLimitApprovalRequests',
    component: TransactionLimitApprovalRequests,
    meta: {
      title: 'Limit Approval',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/limit-approval/location/:id',
    name: 'TransactionLimitApprovalLocationRequestUpdateForm',
    component: TransactionLimitApprovalLocationRequestUpdateForm,
    meta: {
      title: 'Location Limit Request',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/limit-approval/customer/:id',
    name: 'TransactionLimitApprovalCustomerRequestUpdateForm',
    component: TransactionLimitApprovalCustomerRequestUpdateForm,
    meta: {
      title: 'Customer Limit Request',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/providers',
    name: 'TransactionLimitProviders',
    component: TransactionLimitProviders,
    meta: {
      title: 'Providers',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/providers/new',
    name: 'TransactionLimitProvidersForm',
    component: TransactionLimitProvidersForm,
    meta: {
      title: 'New Provider',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/providers/:id',
    name: 'TransactionLimitProvidersUpdateForm',
    component: TransactionLimitProvidersUpdateForm,
    meta: {
      title: 'Update Provider',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/monitoring',
    name: 'TransactionLimitMonitoring',
    component: TransactionLimitMonitoring,
    meta: {
      title: 'Monitoring',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/monitoring/generate-report',
    name: 'TransactionLimitMonitoringGenerateReport',
    component: TransactionLimitMonitoringGenerateReport,
    meta: {
      title: 'Generate Report',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/transaction-limit/monitoring/generate-report/preview',
    name: 'TransactionLimitMonitoringPreviewReport',
    component: TransactionLimitMonitoringPreviewReport,
    meta: {
      title: 'Preview Report',
      layout: SinglePage,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/wu-payments',
    name: 'WUPaymentsLanding',
    component: WUPaymentsLanding,
    meta: {
      title: 'Pay @ WU',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/wu-payments/pay',
    name: 'WUPaymentsPaymentOrder',
    component: WUPaymentsPaymentOrder,
    meta: {
      title: 'Payment Order',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/wu-payments/pay/new',
    name: 'WUPaymentsPaymentOrderNew',
    component: WUPaymentsPaymentOrderNew,
    meta: {
      title: 'New Transaction',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/wu-payments/refund',
    name: 'WUPaymentsRefundOrder',
    component: WUPaymentsRefundOrder,
    meta: {
      title: 'Refund Order',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/wu-payments/refund/new',
    name: 'WUPaymentsRefundOrderNew',
    component: WUPaymentsRefundOrderNew,
    meta: {
      title: 'New Transaction',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/wu-payments/success',
    name: 'WUPaymentSuccessPage',
    component: WUPaymentSuccessPage,
    meta: {
      title: 'New Transaction',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/user-trusted-devices',
    name: 'UserTrustedDevices',
    component: UserTrustedDevices,
    meta: {
      title: 'Manage Trusted Devices',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/prp-deposit',
    name: 'PRPDeposit',
    component: PRPDeposit,
    meta: {
      title: 'PRP Deposit',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance',
    name: 'ComplianceModule',
    component: ComplianceModule,
    meta: {
      title: 'Compliance Module',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/compliance/adverse',
    name: 'ComplianceAdverse',
    component: ComplianceAdverse,
    meta: {
      title: 'Adverse',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/adverse/new',
    name: 'ComplianceAdverseNew',
    component: ComplianceAdverseNew,
    meta: {
      title: 'New Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/adverse/upload',
    name: 'ComplianceAdverseUpload',
    component: ComplianceAdverseUpload,
    meta: {
      title: 'Upload Preview',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/adverse/:id',
    name: 'ComplianceAdverseUpdate',
    component: ComplianceAdverseUpdate,
    meta: {
      title: 'Update Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/pep',
    name: 'CompliancePEP',
    component: CompliancePEP,
    meta: {
      title: 'PEP',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/pep/new',
    name: 'CompliancePEPNew',
    component: CompliancePEPNew,
    meta: {
      title: 'New Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/pep/:id',
    name: 'CompliancePEPUpdate',
    component: CompliancePEPUpdate,
    meta: {
      title: 'Update Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/negative-list',
    name: 'ComplianceNegativeList',
    component: ComplianceNegativeList,
    meta: {
      title: 'Negative List',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/negative-list/new',
    name: 'ComplianceNegativeListNew',
    component: ComplianceNegativeListNew,
    meta: {
      title: 'New Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/negative-list/:id',
    name: 'ComplianceNegativeListUpdate',
    component: ComplianceNegativeListUpdate,
    meta: {
      title: 'Update Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/monitoring',
    name: 'ComplianceMonitoring',
    component: ComplianceMonitoring,
    meta: {
      title: 'Compliance Monitoring',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/monitoring/:id',
    name: 'ComplianceMonitoringViewCustomer',
    component: ComplianceMonitoringViewCustomer,
    meta: {
      title: 'Risk Monitoring',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/profiling',
    name: 'ComplianceWhitelisting',
    component: ComplianceWhitelisting,
    meta: {
      title: 'Profiling',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/profiling/:id',
    name: 'ComplianceWhitelistingViewCustomer',
    component: ComplianceWhitelistingViewCustomer,
    meta: {
      title: 'View Customer',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/excess-fund-requests',
    name: 'ExcessFundRequests',
    component: ExcessFundRequests,
    meta: {
      title: 'Excess Funds',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cash-count',
    name: 'CashCount',
    component: CashCount,
    meta: {
      title: 'Cash Count',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cash-count/form',
    name: 'CashCountForm',
    component: CashCountForm,
    meta: {
      title: 'Cash Count Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/inter-branch-transfer',
    name: 'InterBranchTransfer',
    component: InterBranchTransfer,
    meta: {
      title: 'Inter Branch Transfer',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/inter-branch-transfer/form',
    name: 'InterBranchTransferForm',
    component: InterBranchTransferForm,
    meta: {
      title: 'Inter Branch Transfer Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/inter-branch-transfer/print',
    name: 'Inter Branch Transfer Receipt',
    component: IBTReceipt,
    meta: {
      title: 'Inter Branch Transfer Receipt',
      layout: SinglePage,
      requiresAuth: true
    }
  },
  {
    path: '/ibt-requests-approval',
    name: 'InterBranchTransferRequests',
    component: InterBranchTransferRequests,
    meta: {
      title: 'Inter Branch Transfer Requests',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/nonex-adjustment-approval',
    name: 'NonexAdjustmentApproval',
    component: NonexAdjustmentApproval,
    meta: {
      title: 'Nonex Adjustment Approval',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/nonex-adjustment',
    name: 'NonexAdjustmentIndex',
    component: NonexAdjustmentIndex,
    meta: {
      title: 'Nonex Adjustments',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/pera-remit/nonex-adjustment/form',
    name: 'NonexAdjustmentApproval',
    component: NonexAdjustmentForm,
    meta: {
      title: 'Nonex Adjustments - Request Form',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/mc-rate-request',
    name: 'MCRateRequest',
    component: MCRateRequest,
    meta: {
      title: 'MC Rate Request',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/mc-rate-request/new',
    name: 'MCRateRequestNewEntry',
    component: MCRateRequestNewEntry,
    meta: {
      title: 'MC Rate Request',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cash-transfer',
    name: 'CashTransfer',
    component: CashTransfer,
    meta: {
      title: 'Cash Transfer',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/cash-transfer/new',
    name: 'CashTransferNewEntry',
    component: CashTransferNewEntry,
    meta: {
      title: 'Cash Transfer',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/adjustment-and-expenses',
    name: 'AdjustmentAndExpenses',
    component: AdjustmentAndExpenses,
    meta: {
      title: 'Adjustment & Expenses',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/adjustment-and-expenses/new',
    name: 'AdjustmentAndExpensesNewEntry',
    component: AdjustmentAndExpensesNewEntry,
    meta: {
      title: 'Adjustment & Expenses',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/replenish-branch',
    name: 'ReplenishBranch',
    component: ReplenishBranch,
    meta: {
      title: 'Replenish Branch',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/replenish-branch/new',
    name: 'ReplenishBranchNewEntry',
    component: ReplenishBranchNewEntry,
    meta: {
      title: 'Replenish Branch',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/turnover',
    name: 'Turnover',
    component: Turnover,
    meta: {
      title: 'Turnover',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/mc-rate',
    name: 'MCRate',
    component: MCRate,
    meta: {
      title: 'MC Rate',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/mc-rate/setup',
    name: 'MCRateGeneralSetup',
    component: MCRateGeneralSetup,
    meta: {
      title: 'General Setup',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/mc-rate/setup/new',
    name: 'MCRateGeneralSetupNew',
    component: MCRateGeneralSetupNew,
    meta: {
      title: 'New Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/mc-rate/requests',
    name: 'MCRateRequests',
    component: MCRateRequests,
    meta: {
      title: 'Requests',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/compliance/audit-trail',
    name: 'ComplianceAuditTrail',
    component: ComplianceAuditTrail,
    meta: {
      title: 'Audit Trail',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/receipt-maintenance',
    name: 'ReceiptMaintenance',
    component: ReceiptMaintenance,
    meta: {
      title: 'Receipt Maintenance',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/prompt',
    name: 'Prompt',
    component: Prompt,
    meta: {
      title: 'Prompt Management',
      layout: Default,
      requiresAuth: true,
      mini: false
    }
  },
  {
    path: '/prompt/offers',
    name: 'PromptOffers',
    component: PromptOffers,
    meta: {
      title: 'Offers',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/prompt/offers/new',
    name: 'PromptOffersNew',
    component: PromptOffersNew,
    meta: {
      title: 'New Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/prompt/offers/:id',
    name: 'PromptOffersUpdate',
    component: PromptOffersUpdate,
    meta: {
      title: 'Update Entry',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  },
  {
    path: '/prompt/pre-qualified-customers',
    name: 'PromptQualifiedCustomers',
    component: PromptQualifiedCustomers,
    meta: {
      title: 'Pre-Qualified Customers',
      layout: Default,
      requiresAuth: true,
      mini: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(Middleware)

export default router
