import apigee from '@/api/apigee'

export default {
  getCashCountList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/cashcount?trx_date=${payload.trx_date}&location_id=${payload.location_id}&encoded_by=${payload.encoded_by}`)
  },
  insertCashCountEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/cashcount', payload)
  },
  updateCashCountEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/cashcount-update', payload)
  }
}
