import apigee from '@/api/apigee'
import qs from 'qs'

export default {
  retrieveAdverse () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/fraudsters-get-all')
  },
  retrieveAdverseItem (id) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/fraudsters-get?id=${id}`)
  },
  addAdverseEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/fraudsters-insert', payload)
  },
  addAdverseBulk (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/fraudsters-bulk-insert', payload)
  },
  updateAdverseEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/fraudsters-update', payload)
  },
  deleteAdverseEntry (id) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/maintenance/fraudsters-delete?id=${id}`)
  },
  retrievePEP () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-get-all')
  },
  retrievePEPItem (id) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/compliance-pep-get?id=${id}`)
  },
  addPEPEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-insert', payload)
  },
  addPEPBulk (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-bulk-insert', payload)
  },
  updatePEPEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-update', payload)
  },
  retrieveNegativeList () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-get-all')
  },
  retrieveNegativeListItem (id) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-get?id=${id}`)
  },
  addNegativeListEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-insert', payload)
  },
  addNegativeListBulk (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-bulk-insert', payload)
  },
  updateNegativeListEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-update', payload)
  },
  retrieveComplianceMonitoring (userCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/trx-compliance-monitoring-grid?user_code=${userCode}`)
  },
  updateEddStatus (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/compliance-monitoring-update-edd-status', payload)
  },
  searchByCustomer (payload) {
    const query = qs.stringify(payload)
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/search-by-customer?${query}`)
  },
  whitelistCustomer (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/trx-compliance-white-list-insert', payload)
  },
  searchCustomer (customerName) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-search?name=${customerName}`)
  },
  searchPNA (payload) {
    const query = qs.stringify(payload)
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/search-compliance-pna?${query}`)
  },
  saveRisk (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/save-risk', payload)
  },
  riskProfiling (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/risk-profiling', payload)
  },
  updateRiskScore (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/customer-update-risk-score', payload)
  },
  updateEDDUploadStatus (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/customer-update-edd-upload-status', payload)
  },
  getCustomerInfo (customerId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/get-customer-info?customer_id=${customerId}`)
  },
  getCustomerTransactions (payload) {
    const query = qs.stringify(payload)
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/compliance-detail-customer-trx-grid?${query}`)
  },
  getCustomerRiskLog (customerId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/compliance-monitoring-customer-risk-log-get?customer_id=${customerId}`)
  },
  savePhoto (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/save-photo', payload)
  },
  searchPEP ({ firstName, lastName }) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/compliance-pep-search?first_name=${firstName}&last_name=${lastName}`)
  },
  getPEPCategories () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-get-all-category')
  },
  getPEPPositions () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/compliance-pep-get-all-position')
  },
  getPredicateCrimes () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/compliance-negative-list-predicate-crimes')
  },
  whitelistPEPCustomer (payload) {
    const query = qs.stringify(payload)
    return apigee.post(`/uspdata/v1/usp-maintenance/maintenance/compliance-update-pep-status?${query}`)
  },
  getCustomerLogs (customerId, query) {
    const queryString = qs.stringify(query)
    return apigee.get(`/uspdata/v1/usp-reports/reports/get-audit-customer-changes-logs/${customerId}?${queryString}`)
  },
  getTransactionLogs (query) {
    const queryString = qs.stringify(query)
    return apigee.get(`/uspdata/v1/usp-reports/reports/get-audit-transaction-logs?${queryString}`)
  }
}
