import api from '@/api'
import apigee from '@/api/apigee'

export default {
  getBankDepositList (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/bankdeposit?trx_date=${payload.trxDate}&location_id=${payload.locationId}`)
  },
  getBankPartners () {
    return apigee.get('/uspdata/v1/usp-maintenance/bank-partners')
  },
  getBankDetails () {
    return apigee.get('/uspdata/v1/usp-maintenance/bank-service-charges')
  },
  getUBPToken () {
    return api.post('/v1/banks/unionbank/oauth/token')
  },
  executeUBPCashIn (payload, token) {
    api.defaults.headers.common['x-unionbank-token'] = `Bearer ${token}`
    return api.post('/v1/banks/unionbank/cashin', payload)
  },
  retryUBPCashIn (payload, token) {
    api.defaults.headers.common['x-unionbank-token'] = `Bearer ${token}`
    return api.post('/v1/banks/unionbank/retry', payload)
  },
  inquireUBPCashIn (payload) {
    return api.post('/v1/banks/unionbank/inquire', payload)
  },
  executeMBCashIn (payload) {
    return api.post('/v1/banks/metrobank-rta/payment', payload)
  },
  retryMBCashIn (payload) {
    return api.post('/v1/banks/metrobank-rta/retry', payload)
  },
  inquireMBCashIn (payload) {
    return api.post('/v1/banks/metrobank-rta/retry', payload)
  },
  executeBPICashIn (payload) {
    return apigee.post('/rta/v1/bpi/payment', payload)
  },
  retryBPICashIn (payload) {
    return apigee.post('/rta/v1/bpi/retry', payload)
  },
  inquireBPICashIn (payload) {
    return apigee.post('/rta/v1/bpi/inquire', payload)
  }
}
