import apigee from '@/api/apigee'

export default {
  retrieveData (trxDate, currencyId, locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/mc-rate-grid?trx_date=${trxDate}&currency_id=${currencyId}&location_id=${locationId}`)
  },
  saveData (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/mc-rate-insert', payload)
  },
  getRate (locationId, currencyId, trxDate) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/trx-mc-get-rate?location_id=${locationId}&currency_id=${currencyId}&trx_date=${trxDate}`)
  }
}
