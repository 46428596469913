import apigee from '@/api/apigee'

export default {
  grid (trxDate, locationID) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/eload?trx_date=${trxDate}&location_id=${locationID}`)
  },
  products () {
    return apigee.get('/uspdata/v1/usp-transaction/trx/eload-list')
  },
  save (payload) {
    return apigee.post('/eload/v1/loadcentral/sell', payload)
  },
  inquire (payload) {
    return apigee.post('/eload/v1/loadcentral/inquire', payload)
  },
  assignAllOAR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/eload/assign-alloar', payload)
  }
}
