import apigee from '@/api/apigee'

export default {
  uploadUserSignature (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-signature-create', payload)
  },

  retrieveUserSignature (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-signature-search', payload)
  },

  searchUser (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/search-user', payload)
  },
  getUsers () {
    return apigee.get('/uspdata/v1/usp-maintenance/user-management/show-users')
  },
  addUser (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/add-user', payload)
  },
  updateUser (userID, payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/user-management/update-user/${userID}`, payload)
  },
  deleteUser (userID) {
    return apigee.put(`/uspdata/v1/usp-maintenance/user-management/delete-user/${userID}`)
  },
  allocateLocation (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/add-user-loc', payload)
  },
  getUserRoles (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/show-user-management-role', payload)
  },
  searchUserRole (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/search-user-management-role', payload)
  },
  addUserRole (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/add-user-management-role', payload)
  },
  updateUserRole (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/update-user-management-role', payload)
  },
  deleteUserRole (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/delete-user-management-role', payload)
  },
  showUserRoles (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/show-user-roles', payload)
  },
  showAllModules (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/all-modules', payload)
  }
}
