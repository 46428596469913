import api from '@/api'
import apigee from '@/api/apigee'
// import petnetapiv2 from '@/api/petnetapiv2'

export default {
  getHitList (hitListData) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customerv3/profile?location_id=${hitListData.location_id}&location_type=${hitListData.location_type}&ci_status=${hitListData.ci_status}&date_from=${hitListData.date_from}&date_to=${hitListData.date_to}`)
  },
  updateCIStatus (id, ciStatus) {
    return apigee.put(`/uspdata/v1/usp-maintenance/customerv3/profile/${id}`, ciStatus)
  },
  searchByCRN (customerId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customerv3/search?customer_number=${customerId}`)
  },
  checkIfDuplicate (customerId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customerv3/check-duplicate?customer_number=${customerId}`)
  },
  getCardInfo (id) {
    return api.get(`/v1/banks/eonv2/cards/${id}/info`)
  },
  getCardDetails (id) {
    return api.get(`/v1/banks/eonv2/cards/${id}/details`)
  },
  inquireStatus (transactionId) {
    return api.get(`/v1/banks/eonv2/customers/investigation?transactionId=${transactionId}`)
  },
  createCustomerProfile (payload) {
    return api.post('/v1/banks/eonv2/customers/profileV3', payload)
  },
  createVirtualAccount (payload) {
    return api.post('/v1/banks/eonv2/accounts/virtual', payload)
  },
  generateCVV (payload) {
    return api.post('/v1/banks/eonv2/cards/generate/security', payload)
  },
  lockCard (payload) {
    return api.post('/v1/banks/eonv2/cards/lock', payload)
  },
  replaceCard (payload) {
    return api.post('/v1/banks/eonv2/cards/replacement', payload)
  },
  activateCard (payload) {
    return api.post('/v1/banks/eonv2/cards/pins/activation', payload)
  },
  retryTransaction (payload) {
    return api.post('/v1/banks/eonv2/retry', payload)
  }
}
