import apigee from '@/api/apigee'

export default {
  remoteLocations (locationGroupId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/remote/locations?location_group_id=${locationGroupId}`)
  },
  remoteUsers (locationId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/remote/users?location_id=${locationId}`)
  },
  remoteValidate (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/remote/validate', payload)
  }
}
