import apigee from '@/api/apigee'

export default {
  getCloseList (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/closing/pending-task', payload)
  },
  getExcessFundReasonList () {
    return apigee.get('/uspdata/v1/usp-maintenance/excess-fund/list')
  },
  checkExcessFund (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/excess-fund/check?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  excessFundRequest (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/excess-fund/request', payload)
  },
  updateExcessFundStatus (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/excess-fund/status/${payload.id}`, payload.params)
  },
  getExcessFundRequestList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/excess-fund/approval?trx_date=${payload.trx_date}&status=${payload.status}&user_id=${payload.user_id}&user_name=${payload.user_name}`)
  },
  waiveVariance (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/closing/waive-variance', payload)
  },
  depositExcessFunds (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/excess-fund/deposit', payload)
  }
}
