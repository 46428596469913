import apigee from '@/api/apigee'

export default {
  getIBTList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/ibt/grid?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  getIBTApprovalList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/ibt/approval?user_name=${payload.user_name}`)
  },
  getBorrowerBank (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/ibt/bank?location_id=${payload.location_id}`)
  },
  getUsersOnBorrowerLocations (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/ibt/users-by-location?location_id=${payload.location_id}`)
  },
  getReasonList () {
    return apigee.get('/uspdata/v1/usp-maintenance/ibt/reason-list')
  },
  requestTransfer (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/ibt/request', payload)
  },
  requestApprovalAction (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/ibt/approval/${payload.id}`, payload.params)
  },
  tagAsReleased (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/ibt/release/${payload.id}`)
  },
  tagAsReceived (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/ibt/receive/${payload.id}`, payload.params)
  },
  requestSettlement (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/ibt/settle/${payload.id}`, payload.params)
  },
  getReceiptData (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/receipt/print-ibt?trx_date=${payload.trx_date}&reference_no=${payload.reference_no}`)
  },
  getIBTCount (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/ibt/ibt-count?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  }
}
