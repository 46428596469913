import apigee from '@/api/apigee'
import qs from 'qs'

export default {
  getRptTotalRemco (trxDate, locationId) {
    return apigee.get(`/uspdata/v1/usp-reports/reports/total-transaction-remco?trx_date=${trxDate},&location_id=${locationId}`)
  },
  getRptTotalRH (trxDate, groupLocation) {
    return apigee.get(`/uspdata/v1/usp-reports/reports/total-transaction-sales-rh?trx_date=${trxDate}&group_location=${groupLocation}`)
  },
  getRptUserBalanceTotal (locationID, trxDate, userId) {
    return apigee.post(`/uspdata/v1/usp-maintenance/users/balance?location_id=${locationID}&trx_date=${trxDate}&user_id=${userId}`)
  },
  getRptTypes () {
    return apigee.get('/uspdata/v1/usp-maintenance/reports-admin/grid')
  },
  createRptType (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/reports-admin/create', payload)
  },
  generateReport (endpoint, payload, method) {
    const query = qs.stringify(payload)

    if (method === 'POST') return apigee.post(`/uspdata/v1/usp-reports/reports/${endpoint}`, payload)
    else return apigee.get(`/uspdata/v1/usp-reports/reports/${endpoint}?${query}`)
  }
}
