import api from '@/api'
import apigee from '@/api/apigee'

export default {
  retrieveOrders (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/wucommercial/payments?trx_date=${payload.trx_date}&location_id=${payload.location_id}&tran_type=${payload.tran_type}`)
  },
  merchantList (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/lists', payload)
  },
  orderLookup (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/lookup', payload)
  },
  orderPaymentHold (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/payment-hold', payload)
  },
  orderPaymentRelease (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/release', payload)
  },
  orderRefundHold (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/refund-hold', payload)
  },
  orderRefundRelease (payload) {
    return api.post('/v1/remit/wu/wu/trx/merchant-order/refund-release', payload)
  }
}
