import api from '@/api'
import apigee from '@/api/apigee'

export default {
  // Bills Payment
  getTransactions (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/billspayment-grid?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  getTPARequest (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/bills/tpa', payload)
  },
  getMisc (payload) {
    return apigee.post('/billspay/v1/bayad/token', payload)
  },
  getBillers () {
    return apigee.get('/billspay/v1/bayad/billers')
  },
  getBillerInfo (payload) {
    return apigee.post('/billspay/v1/bayad/biller-info', payload)
  },
  getOtherFees (payload) {
    return apigee.post('/billspay/v1/bayad/fees', payload)
  },
  validateTrx (payload) {
    return apigee.post('/billspay/v1/bayad/validate', payload)
  },
  executeTrx (payload) {
    return apigee.post('/billspay/v1/bayad/transact', payload)
  },
  inquireTrx (payload) {
    return apigee.post('/billspay/v1/bayad/transact-inquire', payload)
  },
  retryTrx (payload) {
    return apigee.post('/billspay/v1/bayad/retry', payload)
  },
  assignSingleOAR (id, payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/maintenance/billspayment/${id}`, payload)
  },
  assignMultipleOAR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/billspayment', payload)
  },
  // Bills Collection (Pera Dali)
  getCollectionTrx (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/pera-dali?trx_date=${payload.trx_date}&location_id=${payload.location_id}&user_id=${payload.user_id}&trx_type=${payload.trx_type}`)
  },
  getLoanProvider (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/collections/loan-provider?location_id=${payload.location_id}`)
  },
  getOtherProvider (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/collections/pera-dali-provider?location_id=${payload.location_id}`)
  },
  executeCollectionTrx (payload) {
    return apigee.post('/uspdata/v1/usp-transaction/trx/pera-dali', payload)
  },
  loanAccountLookup (payload) {
    return api.post('/v1/loanpaycenter/api/validate/account-number', payload)
  },
  generateBillsCollectionVoucher (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/receipt/print-voucher?location_id=${payload.location_id}&trx_date=${payload.trx_date}&ref_no=${payload.ref_no}&trx_type=${payload.trx_type}`)
  }
}
