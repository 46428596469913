import api from '@/api'
import apigee from '@/api/apigee'

export default {
  receiveMoneySearch (mtcn, currencyCode, foreignRefNo, terminalId, operatorId, locationId) {
    return api.post(`/v1/remit/wu/wu/trx/receive-money/search?mtcn=${mtcn}&pd_dest_currency_code=${currencyCode}&foreign_reference_no=${foreignRefNo}&terminal_id=${terminalId}&operator_id=${operatorId}&location_id=${locationId}`)
  },
  receiveMoneyPay (payload) {
    return api.post('/v1/remit/wu/wu/trx/receive-money/pay', payload)
  },
  receiveMoneyHoldSearch (payload) {
    return api.post('/v1/remit/wu/wu/trx/hold/search', payload)
  },
  receiveMoneyHoldSelect (payload) {
    return api.post('/v1/remit/wu/wu/trx/hold/select', payload)
  },
  sendMoneyRelease (payload) {
    return api.post('/v1/remit/wu/wu/trx/send-money/release', payload)
  },
  receiveMoneyRelease (payload) {
    return api.post('/v1/remit/wu/wu/trx/receive-money/release', payload)
  },
  f2ZoomSearch (payload) {
    return api.post('/v1/remit/wu/wu/collection/f2-zoom', payload)
  },
  getPayStatus (payload) {
    return api.post('/v1/remit/wu/wu/collection/pay-status', payload)
  },
  kycLookup (payload) {
    return api.post('/v1/remit/wu/wu/trx/prereq/kyc-lookup', payload)
  },
  generateACRWU (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-wu', payload)
  },
  qpValidate (payload) {
    return api.post('/v1/remit/wu/wu/trx/quick-pay/validate', payload)
  },
  qpStore (payload) {
    return api.post('/v1/remit/wu/wu/trx/quick-pay/store', payload)
  },
  qpFeeInquiry (payload) {
    return api.post('/v1/remit/wu/wu/trx/prereq/fee-inquiy', payload)
  },
  mcRate (payload) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/mc-get-rate?trx_date=${payload.trxDate}&location_id=${payload.locationId}&currency_id=${payload.currencyId}`)
  },
  retryReceiveMoney (payload) {
    return api.post('/v1/remit/wu/wu/trx/receive-money/retry', payload)
  },
  retrySendMoney (payload) {
    return api.post('/v1/remit/wu/wu/trx/send-money/retry', payload)
  }
}
