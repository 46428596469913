import apigee from '@/api/apigee'

export default {
  retrieveFilteredACR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-return-all-filtered', payload)
  },
  retrieveACRFile (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-get-file', payload)
  }
}
