<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 px-md-5 mb-md-12">
      <v-col class="mt-2 mt-sm-4 mt-md-5" :cols="12" :md="5">
        <h2 class="title ph-title primary--text mb-4">Guidelines: Please read.</h2>
        <p class="mb-4">
          1. If you are not the closing teller yesterday, first check the ending balance of closing teller.<br>
          1.1 <strong>if ending balance is CORRECT</strong>, then just transfer the case -> follow example A<br>
          1.2 <strong>if ending balance is INCORRECT, then reset balances for all tellers</strong> -> follow <strong>example B</strong> & <strong>example C</strong>
        </p>
        <p class="mb-4">
          If you are the closing teller yesterday, first check your ending balance yesterday<br>
          2.1 <strong>if your ending balance is CORRECT</strong>, then don’t reset your balance for today.<br>
          2.1. NOTE: Make sure to reset balances of other tellers -> follow <strong>example C</strong><br>
          2.2 <strong>If your ending balance is INCORRECT, then reset the balances for all tellers</strong> -> follow <strong>example B</strong> & <strong>example C</strong>
        </p>
        <p class="mb-4">
          <strong>Example A</strong> FROM Teller: (seller closing teller yesterday), Currency: (select currency), To Teller: (select your name), Amount: (input correct amount)<br>
          <strong>Example B</strong> FROM Teller: (seller Beginning Balance), Currency: (select currency), To Teller: (select your name), Amount: (input correct amount)<br>
          <strong>Example C</strong> FROM Teller: (seller Beginning Balance), Currency: (select currency), To Teller: (select your name), Amount: (input 0.00 amount)
        </p>
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-7 ml-md-12" :cols="12" :md="4">
        <v-form ref="form">
          <v-autocomplete
            v-model="formData.branchId"
            @change="locationChanged"
            :items="locations"
            item-text="location_name"
            item-value="location_id"
            class="ph-textbox mb-5 mb-md-7"
            label="Branch"
            :rules="requiredRules"
            width="100"
            outlined
          />
          <v-autocomplete
            v-model="formData.fromTeller"
            :items="fromTellers"
            item-text="display_text"
            class="ph-textbox mb-5 mb-md-7"
            label="From Teller"
            :rules="requiredRules"
            return-object
            outlined
          />
          <v-autocomplete
            v-model="formData.currencyId"
            :items="collections.currencies"
            class="ph-textbox mb-5 mb-md-7"
            label="Currency"
            :rules="requiredRules"
            outlined
          />
          <v-autocomplete
            v-model="formData.toTeller"
            :items="toTellers"
            item-text="display_text"
            class="ph-textbox mb-5 mb-md-7"
            label="To Teller"
            :rules="requiredRules"
            return-object
            outlined
          />
          <v-text-field
            v-model="formData.amount"
            class="ph-textbox mb-5 mb-md-7"
            label="Amount"
            type="number"
            :rules="requiredRules"
            hide-spin-buttons
            outlined
          />
        </v-form>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-btn
              @click="cancel"
              class="font-weight-bold rounded-lg"
              color="primary"
              width="150"
              outlined
              x-large
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col class="text-right" :cols="12" :md="6">
            <v-btn
              @click="saveData"
              class="font-weight-bold rounded-lg"
              color="primary"
              width="180"
              x-large
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      collections: 'collections'
    }),
    fromTellers () {
      const finalList = [...this.tellers]

      if (Number(this.currUser.location_id) === 371) {
        finalList.unshift({
          user_id: 'begin',
          trx_date: this.currUser.trx_date,
          display_text: 'Beginning Balance'
        })
      }

      return finalList
    },
    toTellers () {
      return this.tellers
    }
  },
  data () {
    return {
      tellers: [],
      formData: {
        branchId: '',
        fromTeller: '',
        currencyId: '',
        toTeller: '',
        amount: ''
      },
      locations: '',
      requiredRules: [v => !!v || 'This field is required'],
      breadcrumbsItems: [
        {
          text: 'Administrator',
          disabled: true,
          exact: true,
          to: '/'
        },
        {
          text: 'Turnover',
          disabled: true
        }
      ]
    }
  },
  methods: {
    async saveData () {
      if (this.$refs.form.validate()) {
        try {
          let payload = {
            trx_date: this.currUser.trx_date,
            total_amount: this.formData.amount,
            client_ip: this.currUser.ip_address,
            currency_id: this.formData.currencyId,
            acknowledge_by: this.formData.toTeller?.user_id,
            location_from: this.formData.branchId,
            user_from: this.formData.fromTeller?.user_id,
            transfer_by_admin: this.formData.fromTeller?.user_id,
            location_id: this.formData.branchId,
            user_id: this.formData.toTeller?.user_id,
            date_encoded: this.currUser.trx_date,
            date_acknowledge: this.currUser.trx_date
          }

          if (this.formData.fromTeller?.user_id === 'begin') {
            payload = {
              trx_date: this.currUser.trx_date,
              amount: this.formData.amount,
              currency_id: this.formData.currencyId,
              location_id: this.formData.branchId,
              user_id: this.formData.toTeller?.user_id,
              reset_by: this.currUser.user_id,
              date_encoded: this.currUser.trx_date,
              date_acknowledge: this.currUser.trx_date
            }
          }

          const { code } = await this.$store.dispatch('cashTransfer/saveData', payload)

          if (code === 200) {
            renderToast('success', 'Successful', 'Turnover has been saved')
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
          } else {
            renderToast('error', 'Request Error', 'Failed saving the given data')
          }
        } catch (e) {
          renderToast('error', 'USP Error', 'Failed saving the given data')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please fill out all required fields')
      }
    },
    cancel () {
      this.$router.push('/')
    },
    async locationChanged (newValue) {
      const { code, result } = await this.$store.dispatch('cashTransfer/getUsers', {
        locationId: newValue,
        trxDate: this.currUser.trx_date
      })

      if (code === 200) {
        this.tellers = result.map(item => {
          return {
            ...item,
            display_text: `${item.last_name}, ${item.first_name}`
          }
        })
      } else {
        renderToast('error', 'USP Error', 'Failed fetching the required data')
      }
    }
  },
  async beforeMount () {
    await this.$store.dispatch('getAllLocations', 0)
    if (this.currUser.location_type === '1') {
      this.locations = this.collections.locations.filter(item => {
        return item.location_id === this.currUser.location_id
      })
    } else {
      this.locations = this.collections.locations
    }
  }
}
</script>
