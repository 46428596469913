<template>
  <v-row class="ph-debit-card-forms-container">
    <v-col cols="12" md="8" class="ph-debit-card-form">
      <TransactionProfileCard />
      <v-row class="ph-debit-card-fields">
        <v-col cols="12">
          <v-alert
            text
            dense
            rounded
            color="warning"
            icon="mdi-information-outline"
            border="left"
            v-if="hasUBCard"
          >
            <b>The system has detected that the selected customer has an existing PHV/Virtual Card with number: {{selectedCustomer.ub_card_no}}</b>
          </v-alert>
        </v-col>
      </v-row>
      <v-form class="ph-debit-card-forms" ref="purchaseCard" v-if="hasUBCard && isAguirreBranch">
        <v-row class="ph-debit-card-fields" v-if="hasUBCard">
          <v-col cols="12">
            <div class="ph-debit-card-fields-title">
              <b>Details for Upgrade/Replace Card</b>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <p>Serial Number:</p>
            <v-text-field
              append-outer-icon="mdi-magnify"
              v-model="transactionData.serial_no"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :hide-spin-buttons="true"
              @click:append-outer="selectAcknowledgedSeries"
              readonly
              placeholder="Select a Series Number"
            />
          </v-col>
          <v-col cols="12" md="6">
            <p>Card Number:</p>
            <v-row>
              <v-col cols="3" v-for="(item, index) in 4" :key="index">
                <v-text-field
                  v-model="cardNumber[index]"
                  label=""
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                  type="text"
                  :ref="'cardTxt' + index"
                  :readonly="index < 2"
                  :hide-spin-buttons="true"
                  maxlength="4"
                  @input="tabSwitch($event, index)"
                  @paste="getCardNumber"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                />
              </v-col>
            </v-row>
            <br>
            <v-alert
              prominent
              v-if="isCardTaken !== ''"
              :type="isCardTaken ? 'error' : 'success'"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{
                    isCardTaken ? 'This card was already registered to ' + cardDetails.customer_name : 'This card is available for use'
                  }}
                </v-col>
                <v-col class="shrink" v-if="isCardTaken">
                  <v-btn @click="sendEmail">Send Email</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
      <v-form class="ph-debit-card-forms" ref="purchaseCard" v-if="!hasUBCard">
        <v-row class="ph-debit-card-fields">
          <v-col cols="12">
            <div class="ph-debit-card-fields-title">
              <b>Select One:</b>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <v-card @click="availCard('virtual')" :class="['phv-purchase-option-container', { 'selected': selectedPurchase === 'virtual' }]">
              <img class="phv-purchase-option-thumbnail" src="@/assets/images/ph-visa-card-front.svg" />
              <b>Avail Virtual Card</b>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" v-if="isAguirreBranch">
            <v-card @click="availCard('phv')" :class="['phv-purchase-option-container', { 'selected': selectedPurchase === 'phv' }]">
              <img class="phv-purchase-option-thumbnail" src="@/assets/images/ph-visa-card-front.svg" />
              <b>Purchase Visa Card</b>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ph-debit-card-fields" v-if="selectedPurchase !== ''">
          <v-col cols="12">
            <div class="ph-debit-card-fields-title">
              <b>Card Details</b>
            </div>
          </v-col>
          <v-col cols="12" md="6"  v-if="selectedPurchase === 'phv'">
            <p>Category:</p>
            <v-autocomplete
              v-model="transactionData.category"
              item-text="product_name"
              item-value="product_name"
              :items="serviceFeeList"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Category"
              return-object
              @change="selectCategory($event)"
              :rules="requiredField"
            >
              <template slot="selection" slot-scope="{ item }">
                {{item.product_name}} - PHP {{formatAmount(item.price_fee)}}
              </template>
              <template slot="item" slot-scope="{ item }">
                {{item.product_name}} - PHP {{formatAmount(item.price_fee)}}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" v-if="selectedPurchase === 'phv'">
            <p>Card Number:</p>
            <v-row>
              <v-col cols="3" v-for="(item, index) in 4" :key="index">
                <v-text-field
                  v-model="cardNumber[index]"
                  label=""
                  color="gray darken-1"
                  class="ph-textbox"
                  outlined
                  type="text"
                  :ref="'cardTxt' + index"
                  :readonly="index < 2"
                  :hide-spin-buttons="true"
                  maxlength="4"
                  @input="tabSwitch($event, index)"
                  @paste="getCardNumber"
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                />
              </v-col>
            </v-row>
            <br>
            <v-alert
              prominent
              v-if="isCardTaken !== ''"
              :type="isCardTaken ? 'error' : 'success'"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{
                    isCardTaken ? 'This card was already registered to ' + cardDetails.customer_name : 'This card is available for use'
                  }}
                </v-col>
                <v-col class="shrink" v-if="isCardTaken">
                  <v-btn @click="sendEmail">Send Email</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6" v-if="selectedPurchase === 'phv'">
            <v-text-field
              append-outer-icon="mdi-magnify"
              v-model="transactionData.serial_no"
              label="Serial Number"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :hide-spin-buttons="true"
              @click:append-outer="selectAcknowledgedSeries"
              readonly
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="selectedPurchase === 'phv'">
            <p v-if="selectedPurchase === 'virtual'">Amount:</p>
            <v-text-field
              v-model="transactionData.amount"
              label="Amount"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="number"
              readonly
              :hide-spin-buttons="true"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="transactionData.mothers_maiden_name"
              label="Mother's Maiden Name"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              type="text"
              :hide-spin-buttons="true"
              :rules="requiredField"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.civil_status"
              item-text="name"
              item-value="value"
              label="Civil Status"
              :items="civilStatus"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Civil Status"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.nationality"
              :items="collections.countries"
              item-text="nationality"
              item-value="three_iso_code"
              label="Nationality"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Nationality"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.source_of_fund"
              item-text="eon_description"
              item-value="eon_value_sent"
              label="Source of Fund"
              :items="eonSourceOfFund"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Source of Fund"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.employment_status"
              item-text="eon_description"
              item-value="eon_value_sent"
              label="Employment Status"
              :items="eonEmployment"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Employment Status"
              @change="checkEmployment(transactionData.employment_status)"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="transactionData.nature_of_work"
              item-text="eon_description"
              item-value="eon_value_sent"
              label="Nature of Work"
              :items="eonNatureOfWork"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Nature of Work"
              :rules="requiredField"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="!this.isUnemployed">
            <b>Employer's Data</b>
          </v-col>
          <v-col :cols="12" v-if="!this.isUnemployed">
            <v-text-field
              v-model="transactionData.company_name"
              class="ph-textbox"
              color="gray darken-1"
              label="Company Name"
              :rules="requiredField"
              @input="convertToProperCase(transactionData.company_name)"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-text-field
              v-model="transactionData.company_address"
              class="ph-textbox"
              color="gray darken-1"
              label="Company Address"
              :rules="requiredField"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-autocomplete
              v-model="transactionData.company_city"
              :items="collections.cities"
              item-text="city"
              item-value="city"
              :return-object="false"
              label="Company City"
              :rules="requiredField"
              color="gray darken-1"
              @change="onCurrentCityChange"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-autocomplete
              v-model="transactionData.company_barangay"
              :items="barangay"
              item-text="barangay"
              item-value="barangay"
              label="Company Barangay"
              :rules="requiredField"
              color="gray darken-1"
              @change="setCurrentZIPCode"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-text-field
              v-model="transactionData.company_province"
              class="ph-textbox"
              color="gray darken-1"
              label="Company Province"
              :rules="requiredField"
              disabled
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-text-field
              v-model="transactionData.company_zipcode"
              class="ph-textbox"
              color="gray darken-1"
              label="Company Zip Code"
              :rules="requiredField"
              disabled
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4" v-if="!this.isUnemployed">
            <v-autocomplete
              v-model="transactionData.company_country"
              :items="collections.countries"
              item-text="country"
              item-value="country"
              :return-object="false"
              label="Company Country"
              :rules="requiredField"
              color="gray darken-1"
              class="ph-textbox"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <p>Front ID:</p>
            <v-autocomplete
              v-if="frontIdList.length > 0"
              v-model="transactionData.id_front"
              item-text="id_type"
              item-value="eon_value_sent"
              :items="frontIdList"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Front ID"
              return-object
              @change="selectFrontImg($event)"
              :rules="requiredField"
              @click:clear="clearImage('front')"
            >
            </v-autocomplete>
            <p v-if="selectedCustomer.img === null">This customer has <b>no identification card.</b><br><br>Kindly update the profile and upload an ID to continue this transaction.</p>
            <p v-if="!isIDSupported">The Current ID of the customer is not on the accepted list. Please consider uploading another ID.</p>
            <br v-if="!isIDSupported || selectedCustomer.img === null">
            <v-btn text primary v-if="!isIDSupported || selectedCustomer.img === null" @click="updateDetails(selectedCustomer)">
              <v-icon left>mdi-card-account-details-outline</v-icon>
              Update ID
            </v-btn>
            <br v-if="transactionData.id_front_picture">
            <v-btn text primary @click="togglePhoto('front')" v-if="transactionData.id_front_picture">
              <v-icon left> mdi-{{showFrontId ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
              {{showFrontId ? 'Hide Image' : 'View Image'}}
            </v-btn>
            <br v-if="transactionData.id_front_picture">
            <br v-if="transactionData.id_front_picture">
            <v-img
              v-if="showFrontId"
              lazy-src="@/assets/images/grey.jpg"
              :src="(transactionData.id_front_picture !== '') ? 'data:image/png;base64, ' + transactionData.id_front_picture : 'https://picsum.photos/id/11/10/6'"
              class="ph-image-upload-thumbnail"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <p>Back ID:</p>
            <v-file-input
              truncate-length="15"
              class="ph-textbox ph-debit-card-upload"
              outlined
              placeholder="Upload Picture"
              @change="uploadedFile($event, 'back')"
              @click:clear="clearImage('back')"
              :rules="requiredField"
            ></v-file-input>
            <br v-if="transactionData.id_back">
            <v-btn text primary @click="togglePhoto('back')" v-if="transactionData.id_back">
              <v-icon left> mdi-{{showBackId ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
              {{showBackId ? 'Hide Image' : 'View Image'}}
            </v-btn>
            <br v-if="transactionData.id_back">
            <br v-if="transactionData.id_back">
            <v-img
              v-if="showBackId"
              lazy-src="@/assets/images/grey.jpg"
              :src="(transactionData.id_back !== '') ? 'data:image/png;base64, ' + transactionData.id_back : 'https://picsum.photos/id/11/10/6'"
              class="ph-image-upload-thumbnail"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <p>Selfie:</p>
            <v-file-input
              truncate-length="15"
              class="ph-textbox ph-debit-card-upload"
              outlined
              placeholder="Upload Picture"
              @change="uploadedFile($event, 'selfie')"
              @click:clear="clearImage('selfie')"
              :rules="requiredField"
            ></v-file-input>
            <br v-if="transactionData.image_selfie">
            <v-btn text primary @click="togglePhoto('selfie')"  v-if="transactionData.image_selfie">
              <v-icon left> mdi-{{showSelfie ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
              {{showSelfie ? 'Hide Image' : 'View Image'}}
            </v-btn>
            <br v-if="transactionData.image_selfie">
            <br v-if="transactionData.image_selfie">
            <v-img
              v-if="showSelfie"
              lazy-src="@/assets/images/grey.jpg"
              :src="(transactionData.image_selfie !== '') ? 'data:image/png;base64, ' + transactionData.image_selfie : 'https://picsum.photos/id/11/10/6'"
              class="ph-image-upload-thumbnail"
            ></v-img>
          </v-col>
          <v-col cols="12" v-if="isFrontIDSecondary">
            <p>Supporting ID for the Secondary ID:</p>
            <br>
            <v-alert
              text
              dense
              rounded
              color="info"
              icon="mdi-information-outline"
              border="left"
            >
              <b>Additional Supporting ID is required when the user submits a Secondary ID.</b>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6" v-if="isFrontIDSecondary">
            <v-autocomplete
              v-model="transactionData.secondary_id_type"
              label="ID Type *"
              :items="supportingIdList"
              item-text="eon_description"
              item-value="eon_value_sent"
              :return-object="false"
              :rules="requiredField"
              color="gray darken-1"
              class="ph-textbox"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" v-if="isFrontIDSecondary">
            <v-text-field
              v-model="transactionData.secondary_id_number"
              @input="convertToUpperCase(transactionData.secondary_id_number)"
              class="ph-textbox"
              color="gray darken-1"
              label="ID Number *"
              :rules="idNumberValidator(transactionData.secondary_id_number, transactionData.secondary_id_type, 'Philippines')"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" md="4" v-if="isFrontIDSecondary">
            <p>Secondary ID Front:</p>
            <v-file-input
              truncate-length="15"
              class="ph-textbox ph-debit-card-upload"
              outlined
              placeholder="Upload Picture"
              @change="uploadedFile($event, 'secondary')"
              @click:clear="clearImage('secondary')"
              :rules="requiredField"
            ></v-file-input>
            <br v-if="transactionData.image_secondary">
            <v-btn text primary @click="togglePhoto('secondary')"  v-if="transactionData.image_secondary">
              <v-icon left> mdi-{{showSecondary ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
              {{showSecondary ? 'Hide Image' : 'View Image'}}
            </v-btn>
            <br v-if="transactionData.image_secondary">
            <br v-if="transactionData.image_secondary">
            <v-img
              v-if="showSecondary"
              lazy-src="@/assets/images/grey.jpg"
              :src="(transactionData.image_secondary !== '') ? 'data:image/png;base64, ' + transactionData.image_secondary : 'https://picsum.photos/id/11/10/6'"
              class="ph-image-upload-thumbnail"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4" v-if="isFrontIDSecondary">
            <p>Secondary ID Back:</p>
            <v-file-input
              truncate-length="15"
              class="ph-textbox ph-debit-card-upload"
              outlined
              placeholder="Upload Picture"
              @change="uploadedFile($event, 'secondary-back')"
              :rules="requiredField"
              @click:clear="clearImage('secondary-back')"
            ></v-file-input>
            <br v-if="transactionData.image_secondary_back">
            <v-btn text primary @click="togglePhoto('secondary')"  v-if="transactionData.image_secondary_back">
              <v-icon left> mdi-{{showSecondaryBack ? 'eye-off-outline' : 'eye-outline'}} </v-icon>
              {{showSecondaryBack ? 'Hide Image' : 'View Image'}}
            </v-btn>
            <br v-if="transactionData.image_secondary_back">
            <br v-if="transactionData.image_secondary_back">
            <v-img
              v-if="showSecondaryBack"
              lazy-src="@/assets/images/grey.jpg"
              :src="(transactionData.image_secondary_back !== '') ? 'data:image/png;base64, ' + transactionData.image_secondary_back : 'https://picsum.photos/id/11/10/6'"
              class="ph-image-upload-thumbnail"
            ></v-img>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="ph-debit-card-back-button">
        <v-col cols="12" class="ph-stepper-action-buttons">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primaryy-button ph-stepper-button ph-action-button"
            @click="cancelButton"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-debit-card-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in debitCardSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <wacom-transaction
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: selectedCustomer.first_name + ' ' +  selectedCustomer.middle_name + ' ' + selectedCustomer.last_name, receiver_name: 'N/A', total_amount: formatAmount(transactionData.amount)}"
        />
        <div class="ph-transfer-summary-action">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in-desktop"
            @click="showConsent"
            :disabled="hasUBCard"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-debit-card-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-debit-card-summary-minified">
          <div class="ph-debit-card-summary-content">
            <div class="ph-debit-card-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>PHP {{selectedPurchase === 'virtual' ? '0.00' : (amount > 0 ? amount : '-')}}</b></p>
            </div>
            <div class="ph-debit-card-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in debitCardSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn ph-validate-cash-in"
                  @click="showConsent"
                  :disabled="hasUBCard"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer, 'idcheck')"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="debitCardOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="debitCardOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <p v-if="!showAmount"><b>Amount to Cash-In: </b>PHP {{formatAmount(transactionData.amount)}}</p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Series No -->
    <v-dialog v-model="acknowledgedCardsDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '40%'">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            Cards Inventory
          </h3>
          <v-spacer />
          <v-btn @click="acknowledgedCardsDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select a series number to continue</strong>
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            :headers="acknowledgedCardHeaders"
            :items="acknowledgedCards"
            :items-per-page="10"
            @click:row="selectSeries"
            no-data-text="No series found"
          >
            <!-- <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template> -->
            <template v-slot:[`item.current_status`]="{ item }">
              <span class="ph-nonex-status-container green--text" v-if="item.current_status === 2">
                <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                Acknowledged
              </span>
              <span class="ph-nonex-status-container orange--text" v-if="item.current_status === 1">
                <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                Allocated
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer, 'ereceipt')"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="manualConfirmPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Would you like to proceed with this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="manualConfirmPrompt = false"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSpiel"
      persistent
      width="800">
      <v-card>
        <v-card-title class="ph-dialog-title">
          This Customer is under investigation due to a hit.
          <v-spacer></v-spacer>
          <v-btn dark icon @click="closeSpiel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="spiel-container">
          <h1 class="spiel-header">Step 1. Inform your customer that the transaction cannot be processed.</h1>
          <p class="spiel-text"><b>Sample spiel:</b> "Hi ma'am / sir, pasesnya na po. Hindi di po maka-proceed with PHVC purchase dahil sa system verification requirements. Pahintay nalang po ng 5-8 days, i-inform nalang po kayo through SMS pag pwede na po mag re-apply."</p>
          <h1 class="spiel-header">Step 2. Return customer's payment.</h1>
          <br>
          <h1 class="spiel-header">Step 3. If customer is insistent on knowing more details, mention limited view of the system and reassure that they will receive a SMS once reapplication is possible.</h1>
          <p class="spiel-text"><b>Sample spiel:</b> "Pasensya na po ma'am / sir, pero di po namin mapo-provide yung exact reason dahil limited lang po ang nakikita namin sa system. I-nonotify naman po kayo through text pag pwede na po kayo mag-apply ulit."</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ProgressDialog
      v-bind:showProgressDialog.sync="showProgressDialog"
      v-bind:setPercentage.sync="setPercentage"
      v-bind:setDescription.sync="setDescription"
      v-bind:setColor.sync="setColor"
    />
    <wacom-consent />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import { avatarColor, avatarInitial, getForeignRefNo, renderToast, aesDecrypt, trim } from '@/utils'
import TransactionProfileCard from '@/components/customers/TransactionProfileCard.vue'
import ProgressDialog from '@/components/partials/ProgressDialog.vue'
import { format, parseISO } from 'date-fns'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import wacomMixin from '@/mixins/wacom.js'

export default {
  name: 'CashInForm',
  mixins: [wacomMixin],
  data () {
    return {
      noIDFlag: false,
      debitCardOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      summaryDialog: false,
      isNotCustomerPage: true,
      showAmount: true,
      debitCardSummaryOverview: [],
      isUnemployed: false,
      providersList: [],
      mobileNumber: '',
      amount: '',
      currentId: '',
      foreignRefNo: '',
      petnetTrackingNo: '',
      currentProvider: '',
      currentProviderAmountPlaceholder: '',
      currentProviderTooltip: '',
      transactionData: {},
      executeData: {},
      minAmount: '',
      maxAmount: '',
      //  cardNumber: ['4162', '9700', '', ''], // DEV
      cardNumber: ['4162', '9704', '', ''], // PROD
      civilStatus: [
        { name: 'Single', value: 'S' },
        { name: 'Married', value: 'M' },
        { name: 'Divorced', value: 'D' },
        { name: 'Widowed', value: 'W' }
      ],
      showFrontId: false,
      showBackId: false,
      showSelfie: false,
      showSecondary: false,
      isFrontIDSecondary: false,
      showSecondaryBack: false,
      acknowledgedCardHeaders: [
        {
          text: 'Category',
          sortable: true,
          value: 'card_category'
        },
        {
          text: 'Serial Number',
          sortable: true,
          value: 'serial_number'
        },
        {
          text: 'Expiry Date',
          sortable: true,
          value: 'expiry_date'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'current_status'
        }
      ],
      acknowledgedCards: [],
      acknowledgedCardsDialog: false,
      cardDetails: {},
      isCardTaken: '',
      isIDSupported: '',
      frontIdList: [],
      supportingIdList: [],
      hasUBCard: false,
      selectedPurchase: '',
      barangay: [],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      showProgressDialog: false,
      setPercentage: 0,
      setDescription: '',
      setColor: '#1A2791',
      currentAccountNumber: '',
      currentEonCustId: '',
      requiredField: [
        v => !!v || 'This field is required'
      ],
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      customerSignature: '',
      manualConfirmPrompt: false
    }
  },
  computed: {
    ...mapGetters({
      debitCardTransactions: 'debitCard/debitCardTransactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      selectedCustomer: 'customers/selectedCustomer',
      debitCardCurrentTrx: 'debitCard/debitCardCurrentTrx',
      oarNumber: 'oar/oarNumber',
      eonSourceOfFund: 'debitCard/eonSourceOfFund',
      eonEmployment: 'debitCard/eonEmployment',
      eonNatureOfWork: 'debitCard/eonNatureOfWork',
      eonIDList: 'debitCard/eonIDList',
      serviceFeeList: 'phvc/serviceFeeList',
      selectedMobileUser: 'customers/selectedMobileUser',
      receipt: 'receipt',
      showSpiel: 'debitCard/showSpiel',
      consentValues: 'wacom/consentValues',
      seriesDetail: 'manualReceipt/seriesDetail'
    }),
    isAguirreBranch () {
      return (String(this.currUser.location_id) === '8')
    }
  },
  components: {
    TransactionProfileCard,
    ProgressDialog,
    wacomTransaction,
    wacomConsent,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  watch: {
    transactionData: {
      handler (val) {
        this.updateOverview(val)
      },
      deep: true
    },
    cardNumber: {
      async handler (val) {
        const cardValue = val.join('')

        if (cardValue.length === 16) {
          await this.$store.dispatch('debitCard/getCardStatus', cardValue).then(res => {
            if (res) {
              this.cardDetails = res
              this.isCardTaken = true
            } else {
              this.cardDetails = {}
              this.isCardTaken = false
            }
          }).catch(e => {
            console.log(e)
          })
        }

        this.transactionData = Object.assign({}, this.transactionData, { card_number: cardValue })
      },
      deep: true
    }
  },
  async mounted () {
    if (this.selectedCustomer) {
      this.foreignRefNo = this.getForeignRefNo()

      this.debitCardSummaryOverview.splice(0)

      this.debitCardSummaryOverview = [
        { name: 'Category', value: 'Select Purchase Type First' }
      ]

      const imgList = JSON.parse(this.selectedCustomer.img)
      const imageArray = []

      if (imgList === null) {
        this.noIDFlag = true
      } else {
        Object.entries(imgList).forEach(item => {
          const eonIdItem = this.eonIDList.filter(res => res.eon_description === item[1].id_type)

          if (eonIdItem.length > 0) {
            item[1].eon_value_sent = eonIdItem[0].eon_value_sent
            imageArray.push(item[1])
          }
        })

        this.frontIdList = imageArray.filter(item => item.id_type !== 'DSWD Certificate' || item.id_type !== 'Police Clearance' || item.id_type !== 'OFW ID' || item.id_type !== 'Barangay Certification' || item.id_type !== 'PRPWD (Philippine Registry for Persons with Disabilities)')
      }

      if (imgList !== null && imageArray.length < 1) {
        this.isIDSupported = false
      } else {
        this.isIDSupported = true
      }

      if (this.selectedMobileUser) {
        const getNationality = this.collections.countries.filter(res => res.nationality === this.selectedMobileUser.nationality)
        const currentIdType = this.selectedMobileUser.valid_id
        let selectedId = {}

        this.frontIdList.filter(list => {
          if (list.id_type.toLowerCase() === currentIdType.toLowerCase()) {
            selectedId = list
          }
        })

        if (selectedId.eon_value_sent === undefined || selectedId.eon_value_sent === {}) {
          this.renderToast('info', 'Front ID not on the customer\'s record', 'The front ID provided is not on the ID list of the selected customer. Kindly update the customer record or choose another ID.')
        }

        this.transactionData = Object.assign({}, this.transactionData, {
          source_of_fund: this.selectedMobileUser.emp_source_of_fund,
          employment_status: this.selectedMobileUser.emp_status,
          nature_of_work: this.selectedMobileUser.emp_nature_work,
          civil_status: this.selectedMobileUser.civil_status,
          nationality: getNationality[0].three_iso_code,
          mothers_maiden_name: this.selectedMobileUser.mothers_maiden_name,
          company_name: this.selectedMobileUser.emp_business_name,
          company_address: this.selectedMobileUser.emp_barangay,
          company_barangay: this.selectedMobileUser.emp_barangay,
          company_city: this.selectedMobileUser.emp_city,
          company_province: this.selectedMobileUser.emp_province,
          company_zipcode: this.selectedMobileUser.emp_zip_code,
          company_country: this.selectedMobileUser.emp_country,
          id_front: selectedId.eon_value_sent ? selectedId.eon_value_sent : '',
          id_front_picture: this.selectedMobileUser.uploaded_id ? this.selectedMobileUser.uploaded_id : '',
          id_number: this.selectedMobileUser.valid_id_no,
          id_back: this.selectedMobileUser.back_id ? this.selectedMobileUser.back_id : '',
          image_selfie: this.selectedMobileUser.selfie ? this.selectedMobileUser.selfie : ''
        })

        this.onCurrentCityChange(this.selectedMobileUser.emp_city)
      } else {
        const getNationality = this.collections.countries.filter(res => res.nationality === this.selectedCustomer.nationality)
        let civilStatus = ''

        switch (this.selectedCustomer.civil_status) {
          case 'Single':
            civilStatus = 'S'
            break
          case 'Married':
            civilStatus = 'M'
            break
          case 'Divorced':
            civilStatus = 'D'
            break
          case 'Widow/Er':
            civilStatus = 'W'
            break
        }

        this.transactionData = Object.assign({}, this.transactionData, {
          source_of_fund: '',
          employment_status: '',
          nature_of_work: '',
          civil_status: civilStatus,
          nationality: getNationality[0].three_iso_code,
          mothers_maiden_name: '',
          company_name: this.selectedCustomer.employer_name,
          company_address: '',
          company_barangay: '',
          company_city: '',
          company_province: '',
          company_zipcode: '',
          company_country: '',
          id_front: '',
          id_front_picture: '',
          id_number: '',
          id_back: '',
          image_selfie: ''
        })
      }

      this.transactionData = Object.assign({}, this.transactionData, { card_number: '41629700' })

      if (this.oarNumber === '') {
        await this.$store.dispatch('oar/getOarNumber', {
          locationID: this.currUser.location_id,
          type: 'OR'
        }).then(res => {
          if (res.result === null) {
            this.renderToast('warning', 'No OR available', 'Please allocate OR first to proceed to the transaction')
          }
        })
      }

      await this.$store.dispatch('customerProfileV3/getCardInfo', this.selectedCustomer.customer_number).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
          this.renderToast('error', 'Error', res.message)
        } else {
          this.currentEonCustId = res.result.customeridno
        }
      }).catch(e => {
        console.log(e)
      })

      if (this.selectedCustomer.ub_card_no && this.selectedCustomer.ub_account_no) {
        this.hasUBCard = true
      }

      this.supportingIdList = this.eonIDList
      const that = this
      setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
      setTimeout(wacomMixin.methods.checkForWacom, 2000)
      const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
      that.customerSignaturesReference = signatures.result
      this.checkConsent()
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    getForeignRefNo,
    renderToast,
    trim,
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.validateDebitCard()
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    updateProgress (percentage, description, color) {
      this.setPercentage = percentage
      this.setDescription = description
      this.setColor = color
    },
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.openVerifyOTP()
    },
    proceedTransaction () {
      this.openOTPDialog()
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        if (this.currUser.receipt_type === 3) {
          this.sendViaEmail = true
          if (this.selectedCustomer.email_add) {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = true
          } else {
            this.withoutEmailPrompt = true
            this.withEmailPrompt = false
            this.sendViaEmail = false
          }
        } else {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = false
          this.sendViaEmail = false
          this.manualConfirmPrompt = true
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.openOTPDialog()
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async updateOverview (data) {
      if (this.selectedPurchase !== 'virtual') {
        this.debitCardSummaryOverview = [
          { name: 'OR Number', value: this.oarNumber ? this.oarNumber.series_no : '-' },
          { name: 'Category', value: data.category ? data.category.product_name : '-' },
          { name: 'Serial Number', value: data.serial_no ? data.serial_no : '-' },
          { name: 'EON Card Number', value: data.card_number ? (data.card_number.length === 16 ? data.card_number : '-') : '-' },
          { name: 'Amount', value: (data.amount !== null || data.amount !== undefined) ? 'PHP ' + this.formatAmount(data.amount) : '-' }
        ]
      }
    },
    showConsent () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      } else {
        this.validateDebitCard()
      }
    },
    selectCategory (item) {
      this.transactionData = Object.assign({}, this.transactionData, { amount: item.price_fee })
    },
    async validateDebitCard () {
      if (!this.transactionData?.card_number && this.transactionData.card_number.length !== 12) {
        this.renderToast('error', 'Validation Error', 'Card Number is not complete.')
      } else if (this.isCardTaken) {
        this.renderToast('error', 'Validation Error', 'Card Number has already been taken.')
      } else {
        if (this.$refs.purchaseCard.validate()) {
          if (this.selectedPurchase === 'phv') {
            this.checkEmail()
          } else {
            this.openOTPDialog()
          }
        } else {
          this.renderToast('error', 'Validation Error', 'You must complete the following fields')
        }
      }
    },
    async savePendingTransaction (data, type) {
      const payload = data.eonparams
      const debitCardNumber = (type === 'phv' || type === 'replace') ? this.cardNumber.join('') : ''
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code

      console.log(payload)
      const pendingData = {
        address: address,
        barangay: barangay,
        city: city,
        province: province,
        zipCode: zipCode,
        email: this.selectedCustomer.email_add,
        or_series_no: this.receipt.or_series_no,
        or_receipt_no: this.receipt.or_receipt_no,
        ereceipt_id: this.receipt.ereceipt_id,
        customer_id: data.customer_id,
        customer_code: this.selectedCustomer.customer_number,
        first_name: payload.firstname,
        middle_name: payload.middlename,
        last_name: payload.lastname,
        UBCustomerCode: this.selectedMobileUser ? this.selectedMobileUser.UBCustomerCode : ''
      }
      const pendingPayload = {
        trx_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        customer_id: data.customer_id,
        customer_name: trim(`${payload.firstname} ${this.selectedCustomer.middle_name} ${payload.lastname}`),
        trx_type: 'virtual',
        mother_maiden_name: payload.mothersMaidenName,
        card_number: debitCardNumber,
        serial_number: type === 'phv' ? data.serial_number : '',
        loyalty_card_no: '',
        existing_tag: '0',
        amount: type === 'replace' ? 0 : data.amount,
        form_type: '',
        form_number: '',
        card_category: 'PERAHUB',
        trx_categ: 'REGULAR',
        client_ip: '127.0.0.1',
        pending_status: '0',
        pending_desc: '',
        pending_data: JSON.stringify(pendingData),
        status: '0'
      }

      switch (type) {
        case 'virtual':
          pendingPayload.trx_type = 'virtual'
          pendingPayload.trx_categ = 'REGULAR'
          break
        case 'phv':
          pendingPayload.trx_type = 'new'
          pendingPayload.trx_categ = 'REGULAR'
          break
        case 'replace':
          // HOA-OR03-2023-000000000004
          pendingPayload.trx_type = 'replace'
          pendingPayload.trx_categ = 'REGULAR'
          break
      }

      if (this.currUser.location_type !== '1') {
        pendingPayload.form_type = ''
        pendingPayload.form_number = this.foreignRefNo
      } else {
        pendingPayload.form_type = 'OR'
        pendingPayload.form_number = this.currUser.receipt_type === 3 ? this.receipt.or_series_no : (type === 'virtual' ? '' : this.seriesDetail.series_no)
      }

      let result = {}

      await this.$store.dispatch('debitCard/savePendingDebitCard', pendingPayload).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
          this.renderToast('error', 'Error', res.message)
        } else {
          result = res
        }
      })

      return result
    },
    async updatePendingTransaction (id) {
      const currentCustomer = this.selectedCustomer

      const updatePayload = {
        id: id,
        params: {
          pending_status: 1,
          pending_desc: '',
          pending_data: '',
          id_ub_card: id,
          customer_id: currentCustomer.customer_id,
          loyaltycard: '',
          ub_card_no: this.currentAccountNumber
        }
      }

      let result = {}

      await this.$store.dispatch('debitCard/updatePendingDebitCard', updatePayload).then(res => {
        if (!(res.code >= 200 && res.code <= 299)) {
          this.renderToast('error', 'Error', res.message)
          result = res
        } else {
          result = res
        }
      })

      return result
    },
    async executeRegistration (type) {
      const currentCustomer = this.selectedCustomer
      const currentTrx = this.transactionData
      this.showProgressDialog = true

      // const getPinNumber = debitCardNumber.substr(debitCardNumber.length - 6)
      // this.updateProgress(0, err.message, '#B71C1C')

      const payload = {
        form_number: '-',
        trx_categ: 'Virtual Card',
        serial_number: '-',
        new_card_number: '-',
        amount: 0,
        occupation: currentCustomer.occupation,
        customerBirthDate: currentCustomer.birth_date,
        type: 'virtual',
        customer_id: currentCustomer.customer_id,
        eonparams: {
          customer_code: currentCustomer.customer_number.toString(),
          firstname: currentCustomer.first_name,
          middlename: currentCustomer.middle_name ? currentCustomer.middle_name : '...',
          lastname: currentCustomer.last_name,
          birthDate: format(parseISO(currentCustomer.birth_date), 'dd/MM/yyyy'),
          birthPlace: currentCustomer.birth_place,
          mobileNumber: currentCustomer.mobile_no,
          email: currentCustomer.email_add,
          gender: currentCustomer.gender === 'female' ? 'F' : 'M',
          civilStatus: currentTrx.civil_status,
          mothersMaidenName: currentTrx.mothers_maiden_name,
          presentline1: currentCustomer.street1,
          presentline2: currentCustomer.street1,
          presentstreet: currentCustomer.street1,
          presentcity: currentCustomer.municipality,
          presentprovince: currentCustomer.province,
          presentcountry: currentCustomer.country,
          presentpostalcode: currentCustomer.zip_code,
          sourceOfFund: currentTrx.source_of_fund,
          employmentstatus: currentTrx.employment_status,
          natureOfWork: currentTrx.nature_of_work,
          companyName: !this.isUnemployed ? currentCustomer.employer_name : 'N/A',
          companystreet: !this.isUnemployed ? currentTrx.company_address : 'N/A',
          companybarangay: !this.isUnemployed ? currentTrx.company_barangay : 'N/A',
          companycity: !this.isUnemployed ? currentTrx.company_city : 'N/A',
          companyprovince: !this.isUnemployed ? currentTrx.company_province : 'N/A',
          companypostalcode: !this.isUnemployed ? currentTrx.company_zipcode : '0000',
          nationality: currentTrx.nationality,
          selfie: currentTrx.image_selfie,
          // idType: currentTrx.id_type,
          // idNumber: currentTrx.id_number,
          idFront: currentTrx.id_front_picture,
          idBack: currentTrx.id_back,
          secondaryIdType: this.isFrontIDSecondary ? currentTrx.id_type : '',
          secondaryIdNumber: this.isFrontIDSecondary ? currentTrx.id_number : '',
          secondaryIdType2: this.isFrontIDSecondary ? currentTrx.secondary_id_type : '',
          secondaryIdNumber2: this.isFrontIDSecondary ? currentTrx.secondary_id_number : '',
          secondaryIdFront: this.isFrontIDSecondary ? currentTrx.id_front_picture : '',
          secondaryIdBack: this.isFrontIDSecondary ? currentTrx.id_back : '',
          secondaryIdFront2: this.isFrontIDSecondary ? currentTrx.image_secondary : '',
          secondaryIdBack2: this.isFrontIDSecondary ? currentTrx.image_secondary_back : '',
          is_physical_card: '0'
        }
      }

      if (type === 'phv') {
        const debitCardNumber = this.cardNumber.join('')

        payload.form_number = this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.or_series_no : this.seriesDetail.series_no) : this.foreignRefNo
        payload.trx_categ = currentTrx.category.product_name
        payload.serial_number = currentTrx.serial_no
        payload.new_card_number = debitCardNumber
        payload.amount = currentTrx.amount
        payload.type = 'phv'
        payload.is_physical_card = '1'
      }
      if (!this.isFrontIDSecondary) {
        payload.eonparams = {
          ...payload.eonparams,
          idType: currentTrx.id_type,
          idNumber: currentTrx.id_number,
          primaryIdFront: currentTrx.id_front_picture,
          primaryIdBack: currentTrx.id_back,
          primaryIdType: currentTrx.id_type,
          primaryIdNumber: currentTrx.id_number
        }
      }

      this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_TRX', payload)
      const savePending = await this.savePendingTransaction(payload, type)

      this.updateProgress(0, 'Saving the Record and Creating a Customer Profile...', '#1A2791')

      if (savePending.code >= 200 && savePending.code <= 299) {
        this.updateProgress(25, 'Creating Virtual Card...', '#1A2791')

        const createCustomerProfileV3 = await this.$store.dispatch('customerProfileV3/createCustomerProfile', payload.eonparams).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(25, res.message, '#B71C1C')
          } else {
            return res
          }
        }).catch(err => {
          console.log(err)
          return err
        })

        if (parseInt(createCustomerProfileV3.result.ciStatus) === 10) {
          this.$store.commit('debitCard/SET_IS_CUSTOMER_HIT', false)
          const virtualAccountPayload = {
            customerId: createCustomerProfileV3.result.customerId,
            productType: 'PRD9183' // DEV
            // productType: 'PRD4190' // PROD
          }

          const createVirtualCard = await this.$store.dispatch('customerProfileV3/createVirtualAccount', virtualAccountPayload).then(res => {
            if (!(res.code >= 200 && res.code <= 299)) {
              this.updateProgress(25, res.message, '#B71C1C')
              this.renderToast('error', 'Error', res.message)
            } else {
              this.renderToast('success', 'Virtual Card Registration Successful', res.message)
              return res
            }
          })

          if (createVirtualCard.code >= 200 && createVirtualCard.code <= 299) {
            this.currentAccountNumber = createVirtualCard.result.accountNumber
            this.updateProgress(45, 'Generating Security...', '#1A2791')

            const generateCVV = await this.$store.dispatch('customerProfileV3/generateCVV', { customerId: createCustomerProfileV3.result.customerId }).then(res => {
              if (!(res.code >= 200 && res.code <= 299)) {
                this.updateProgress(45, res.message, '#B71C1C')
                this.renderToast('error', 'Error', res.message)
              } else {
                return res
              }
            })

            let lockCard = {}
            let replaceCard = {}
            let activateCard = {}
            let updatePending = {}
            const debitCardNumber = this.cardNumber.join('')

            if (type === 'phv') {
              if (generateCVV.code >= 200 && generateCVV.code <= 299) {
                this.updateProgress(60, 'Preparing Physical Card...', '#1A2791')

                lockCard = await this.$store.dispatch('customerProfileV3/lockCard', { customerId: createCustomerProfileV3.result.customerId }).then(res => {
                  if (!(res.code >= 200 && res.code <= 299)) {
                    this.updateProgress(60, res.message, '#B71C1C')
                    this.renderToast('error', 'Error', res.message)
                  } else {
                    return res
                  }
                })
              }

              if (lockCard.code >= 200 && lockCard.code <= 299) {
                this.updateProgress(70, 'Setting up Physical Card...', '#1A2791')

                const replaceCardPayload = {
                  customerId: createCustomerProfileV3.result.customerId,
                  new_card_number: debitCardNumber
                }

                replaceCard = await this.$store.dispatch('customerProfileV3/replaceCard', replaceCardPayload).then(res => {
                  if (!(res.code >= 200 && res.code <= 299)) {
                    this.updateProgress(70, res.message, '#B71C1C')
                    this.renderToast('error', 'Error', res.message)
                  } else {
                    return res
                  }
                })
              }

              if (replaceCard.code >= 200 && replaceCard.code <= 299) {
                this.updateProgress(80, 'Activating Physical Card...', '#1A2791')
                activateCard = await this.$fstore.dispatch('customerProfileV3/activateCard', {
                  customerId: createCustomerProfileV3.result.customerId,
                  apiNewPin: debitCardNumber.slice(-6)
                }).then(res => {
                  if (!(res.code >= 200 && res.code <= 299)) {
                    this.updateProgress(80, res.message, '#B71C1C')
                    this.renderToast('error', 'Error', res.message)
                  } else {
                    return res
                  }
                })
              }

              if (activateCard.code >= 200 && activateCard.code <= 299) {
                this.updateProgress(90, 'Finishing transaction...', '#1A2791')
                updatePending = await this.updatePendingTransaction(savePending.result.id).then(res => {
                  if (!(res.code >= 200 && res.code <= 299)) {
                    this.updateProgress(90, res.message, '#B71C1C')
                    this.renderToast('error', 'Error', res.message)
                  } else {
                    return res
                  }
                })
              }

              if (updatePending.code >= 200 && updatePending.code <= 299) {
                if (this.currUser.location_type === '1') {
                  if (this.currUser.receipt_type === 3) {
                    this.updateProgress(95, 'Generating E-Receipt...', '#1A2791')
                    const address = this.selectedCustomer.address
                    const barangay = this.selectedCustomer.barangay
                    const city = this.selectedCustomer.municipality
                    const province = this.selectedCustomer.province
                    const zipCode = this.selectedCustomer.zip_code
                    const saveReceipt = await this.$store.dispatch('saveReceipt', {
                      ereceipt_id: this.receipt.ereceipt_id,
                      application_id: this.currUser.application_id,
                      application_header_name: this.currUser.branchDetail.header_name,
                      application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                      receipt_type: this.receipt.type,
                      customer_id: this.selectedCustomer.customer_id,
                      customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                      customer_tin_no: '',
                      customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                      customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                      cashier_id: this.currUser.user_id,
                      cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                      or_receipt_no: this.receipt.or_receipt_no,
                      or_number: this.receipt.or_series_no,
                      or_service: `Pera Debit Card - ${this.selectedCustomer.customer_number}`,
                      or_currency: 'PHP',
                      or_principal_amount: Number(this.transactionData.amount),
                      or_vat_percent: 0,
                      or_vat_service_fee: 0,
                      or_vat_exempt_fee: 0,
                      or_vat_zero_rated_fee: 0,
                      or_vat_discount: 0,
                      or_vat_amount: 0,
                      or_total: Number(this.transactionData.amount),
                      grand_total: Number(this.transactionData.amount),
                      print_email_hold: 0,
                      is_forex_logo: 0
                    })

                    if (saveReceipt.code === 200) {
                      const that = this
                      this.currentAccountNumber = ''

                      if (saveReceipt.result.receipt_status === 1) {
                        this.updateProgress(100, 'Success!', '#1A2791')
                        this.renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                        this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)

                        setTimeout(function () {
                          that.showProgressDialog = false
                          that.updateProgress(0, '')
                        }, 1500)
                      } else if (saveReceipt.result.receipt_status === 2) {
                        const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                          application_id: this.currUser.application_id,
                          ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                        })
                        var newBlob = new Blob([res], { type: 'application/pdf' })
                        var newFileURL = URL.createObjectURL(newBlob)
                        window.open(newFileURL, '_blank')
                      } else {
                        this.renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                      }
                    } else {
                      this.renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
                    }
                  } else {
                    this.updateProgress(95, 'Saving transaction to record...', '#1A2791')
                    const payload = {
                      id: this.seriesDetail.id,
                      status: 2,
                      customer_id: this.selectedCustomer.customer_id,
                      encoded_by: this.currUser.user_id,
                      date_encoded: format(new Date(), 'yyyy-MM-dd'),
                      amount: Number(this.transactionData.amount),
                      description: `Pera Debit Card - ${this.selectedCustomer.customer_number}`,
                      remarks: `Pera Debit Card - ${this.selectedCustomer.customer_number}`
                    }

                    await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
                      if (res.code >= 200 && res.code <= 299) {
                        const that = this
                        this.currentAccountNumber = ''
                        this.updateProgress(100, 'Success!', '#1A2791')
                        this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)
                        this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - Service Invoice Number: ${this.seriesDetail.series_no}`)

                        setTimeout(function () {
                          that.showProgressDialog = false
                          that.updateProgress(0, '')
                        }, 1500)
                      } else {
                        this.renderToast('error', 'Error', res.message)
                      }
                    }).catch(err => {
                      this.renderToast('error', 'Error', err)
                    })
                  }
                }

                this.updateProgress(100, 'Success!', '#1A2791')
                this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)

                setTimeout(function () {
                  this.showProgressDialog = false
                  this.updateProgress(0, '')
                }, 2500)
              }
            } else {
              if (generateCVV.code >= 200 && generateCVV.code <= 299) {
                this.updateProgress(75, 'Finishing transaction...', '#1A2791')

                updatePending = await this.updatePendingTransaction(savePending.result.id).then(res => {
                  if (!(res.code >= 200 && res.code <= 299)) {
                    this.updateProgress(75, res.message, '#B71C1C')
                    this.renderToast('error', 'Error', res.message)
                  } else {
                    return res
                  }
                })
              }

              if (updatePending.code >= 200 && updatePending.code <= 299) {
                this.updateProgress(100, 'Success!', '#1A2791')
                this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)

                const that = this

                setTimeout(function () {
                  that.showProgressDialog = false
                  that.updateProgress(0, '')
                }, 2500)
              }
            }
          }
        } else {
          this.$store.commit('debitCard/SET_SHOW_SPIEL', true)
          this.$store.commit('debitCard/SET_IS_CUSTOMER_HIT', true)
          this.updateProgress(25, 'Transaction Failed: This Customer is under investigation due to a hit.', '#B71C1C')
        }
      }
    },
    async executePHVCardReplacement () {
      const debitCardNumber = this.cardNumber.join('')
      const currentCustomer = this.selectedCustomer
      const currentTrx = this.transactionData

      this.showProgressDialog = true
      this.updateProgress(0, 'Preparing to replace card...', '#1A2791')

      let lockCard = {}
      let replaceCard = {}
      let activateCard = {}
      let updatePending = {}

      const payload = {
        customer_id: currentCustomer.customer_id,
        lastname: currentCustomer.last_name,
        firstname: currentCustomer.first_name,
        middlename: currentCustomer.middle_name,
        mothersMaidenName: '',
        new_card_number: debitCardNumber,
        serial_number: currentTrx.serial_no,
        amount: '0',
        form_number: this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.or_series_no : this.seriesDetail.series_no) : this.foreignRefNo,
        type: 'replace',
        trx_categ: 'Card Replacement',
        eonparams: {
          lastname: currentCustomer.last_name,
          firstname: currentCustomer.first_name,
          middlename: currentCustomer.middle_name,
          mothersMaidenName: ''
        }
      }

      this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_TRX', payload)

      const savePending = await this.savePendingTransaction(payload, 'replace')

      if (savePending.code >= 200 && savePending.code <= 299) {
        this.updateProgress(20, 'Preparing your new Physical Card...', '#1A2791')

        lockCard = await this.$store.dispatch('customerProfileV3/lockCard', { customerId: this.currentEonCustId }).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(30, res.message, '#B71C1C')

            this.renderToast('error', 'Error', res.message)
          } else {
            return res
          }
        })
      }

      if (lockCard.code >= 200 && lockCard.code <= 299) {
        this.updateProgress(40, 'Setting up your new Physical Card...', '#1A2791')

        const replaceCardPayload = {
          customerId: this.currentEonCustId,
          new_card_number: debitCardNumber
        }

        replaceCard = await this.$store.dispatch('customerProfileV3/replaceCard', replaceCardPayload).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(60, res.message, '#B71C1C')
            this.renderToast('error', 'Error', res.message)
          } else {
            return res
          }
        })
      }

      if (replaceCard.code >= 200 && replaceCard.code <= 299) {
        this.updateProgress(60, 'Activating your new Physical Card...', '#1A2791')

        activateCard = await this.$store.dispatch('customerProfileV3/activateCard', {
          customerId: this.currentEonCustId,
          apiNewPin: debitCardNumber.slice(-6)
        }).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(90, res.message, '#B71C1C')
            this.renderToast('error', 'Error', res.message)
          } else {
            return res
          }
        })
      }

      if (activateCard.code >= 200 && activateCard.code <= 299) {
        this.updateProgress(80, 'Finishing transaction...', '#1A2791')

        updatePending = await this.updatePendingTransaction(savePending.result.id).then(res => {
          if (!(res.code >= 200 && res.code <= 299)) {
            this.updateProgress(90, res.message, '#B71C1C')
            this.renderToast('error', 'Error', res.message)
          } else {
            return res
          }
        })
      }

      if (updatePending.code >= 200 && updatePending.code <= 299) {
        if (this.currUser.receipt_type === 3) {
          this.updateProgress(95, 'Generating E-Receipt...', '#1A2791')
          const address = this.selectedCustomer.address
          const barangay = this.selectedCustomer.barangay
          const city = this.selectedCustomer.municipality
          const province = this.selectedCustomer.province
          const zipCode = this.selectedCustomer.zip_code
          const saveReceipt = await this.$store.dispatch('saveReceipt', {
            ereceipt_id: this.receipt.ereceipt_id,
            application_id: this.currUser.application_id,
            application_header_name: this.currUser.branchDetail.header_name,
            application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
            receipt_type: this.receipt.type,
            customer_id: this.selectedCustomer.customer_id,
            customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
            customer_tin_no: '',
            customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
            customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
            cashier_id: this.currUser.user_id,
            cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
            or_receipt_no: this.receipt.or_receipt_no,
            or_number: this.receipt.or_series_no,
            or_service: `Pera Debit Card - ${this.selectedCustomer.customer_number}`,
            or_currency: 'PHP',
            or_principal_amount: Number('0'),
            or_vat_percent: 0,
            or_vat_service_fee: 0,
            or_vat_exempt_fee: 0,
            or_vat_zero_rated_fee: 0,
            or_vat_discount: 0,
            or_vat_amount: 0,
            or_total: Number('0'),
            grand_total: Number('0'),
            print_email_hold: 0,
            is_forex_logo: 0
          })

          if (saveReceipt.code === 200) {
            const that = this
            this.currentAccountNumber = ''

            if (saveReceipt.result.receipt_status === 1) {
              this.updateProgress(100, 'Success!', '#1A2791')
              this.renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)

              setTimeout(function () {
                that.showProgressDialog = false
                that.updateProgress(0, '')
              }, 1500)
            } else if (saveReceipt.result.receipt_status === 2) {
              const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                application_id: this.currUser.application_id,
                ereceipt_api_id: saveReceipt.result.ereceipt_api_id
              })
              var newBlob = new Blob([res], { type: 'application/pdf' })
              var newFileURL = URL.createObjectURL(newBlob)
              window.open(newFileURL, '_blank')
              this.updateProgress(100, 'Success!', '#1A2791')
              this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)

              setTimeout(function () {
                that.showProgressDialog = false
                that.updateProgress(0, '')
              }, 2500)
            } else {
              this.renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
            }
          } else {
            this.renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
          }
        } else {
          this.updateProgress(95, 'Saving transaction to record...', '#1A2791')
          const payload = {
            id: this.seriesDetail.id,
            status: 2,
            customer_id: this.selectedCustomer.customer_id,
            encoded_by: this.currUser.user_id,
            date_encoded: format(new Date(), 'yyyy-MM-dd'),
            amount: Number(this.transactionData.amount),
            description: `Pera Debit Card - ${this.selectedCustomer.customer_number}`,
            remarks: `Pera Debit Card - ${this.selectedCustomer.customer_number}`
          }

          await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
            if (res.code >= 200 && res.code <= 299) {
              const that = this
              this.currentAccountNumber = ''
              this.updateProgress(100, 'Success!', '#1A2791')
              this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 2)
              this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - Service Invoice Number: ${this.seriesDetail.series_no}`)

              setTimeout(function () {
                that.showProgressDialog = false
                that.updateProgress(0, '')
              }, 1500)
            } else {
              this.renderToast('error', 'Error', res.message)
            }
          }).catch(err => {
            this.renderToast('error', 'Error', err)
          })
        }
      }
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    openVerifyOTP () {
      this.otp = ''
      this.debitCardOTPDialog = true

      console.log(this.debitCardOTPDialog)
    },
    updateDetails (customerDetails, type) {
      this.noIDFlag = false
      if (type === 'idcheck') {
        if (this.selectedMobileUser) {
          this.$router.push({
            path: `/customers/${customerDetails.customer_number}`,
            query: {
              editable: true,
              persistData: true,
              redirectURL: this.$route.path,
              isMobileUser: true
            }
          })
        } else {
          this.$router.push({
            path: `/customers/${customerDetails.customer_number}`,
            query: {
              editable: true,
              persistData: true,
              redirectURL: this.$route.path
            }
          })
        }
      } else {
        this.$router.push({
          path: `/customers/${customerDetails.customer_number}`,
          query: {
            editable: true,
            persistData: true,
            redirectURL: this.$route.path
          }
        })
      }
    },
    cancelButton () {
      this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 0)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.debitCardOTPDialog = false

          if (this.currUser.location_type !== '1') {
            this.executeVerifiedAction()
          } else {
            if (this.currUser.receipt_type === 3) {
              const getReceipt = await this.$store.dispatch('getReceipt', {
                location_id: this.currUser.location_id,
                type: '1',
                application_id: this.currUser.application_id
              })

              if (getReceipt.code === 200) {
                this.executeVerifiedAction()
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
              }
            } else {
              if (this.selectedPurchase !== 'virtual') {
                const payload = {
                  location_id: this.currUser.location_id,
                  type: 'OR'
                }

                await this.$store.dispatch('manualReceipt/getAvailableSeriesNo', payload).then(res => {
                  if (res.code >= 200 && res.code <= 299) {
                    if (res.result.length > 0) {
                      this.executeVerifiedAction()
                    } else {
                      this.renderToast('error', 'Error', 'No available series number for this branch. Please request from support.')
                    }
                  } else {
                    this.renderToast('error', 'Error', res.message)
                  }
                })
              }
            }
          }
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    async executeVerifiedAction () {
      if (this.selectedPurchase === 'phv') {
        this.executeRegistration('phv')
      } else if (this.selectedPurchase === 'virtual') {
        this.executeRegistration('virtual')
      } else {
        this.executePHVCardReplacement()
      }
    },
    togglePhoto (type) {
      switch (type) {
        case 'front':
          this.showFrontId = !this.showFrontId
          break
        case 'back':
          this.showBackId = !this.showBackId
          break
        case 'selfie':
          this.showSelfie = !this.showSelfie
          break
        case 'secondary':
          this.showSecondary = !this.showSecondary
          break
        case 'secondary-back':
          this.showSecondaryBack = !this.showSecondaryBack
          break
      }
    },
    async uploadedFile (item, type) {
      let idData = {}

      if (item !== null) {
        const getBase64 = await this.uploadedFileToConvert(item)

        switch (type) {
          case 'front':
            idData = {
              id_front: getBase64
            }
            break
          case 'back':
            idData = {
              id_back: getBase64
            }
            break
          case 'selfie':
            idData = {
              image_selfie: getBase64
            }
            break
          case 'secondary':
            idData = {
              image_secondary: getBase64
            }
            break
          case 'secondary-back':
            idData = {
              image_secondary_back: getBase64
            }
            break
        }

        this.transactionData = Object.assign({}, this.transactionData, idData)
      } else {
        switch (type) {
          case 'front':
            idData = {
              id_front: ''
            }
            break
          case 'back':
            idData = {
              id_back: ''
            }
            break
          case 'selfie':
            idData = {
              image_selfie: ''
            }
            break
          case 'secondary':
            idData = {
              image_secondary: ''
            }
            break
          case 'secondary-back':
            idData = {
              image_secondary_back: ''
            }
            break
        }

        this.transactionData = Object.assign({}, this.transactionData, idData)
      }
    },
    uploadedFileToConvert (item) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.readAsBinaryString(item)
        reader.onload = function () {
          resolve(btoa(reader.result))
        }
      })
    },
    async urlToBase64 (url) {
      const data = await fetch(url)
      const blob = await data.blob()

      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    async selectAcknowledgedSeries () {
      const getAcknowledgedCards = {
        location_id: this.currUser.location_id,
        card_category: 'PERAHUB',
        current_status: 2
      }

      await this.$store.dispatch('debitCard/getCardInventory', getAcknowledgedCards).then(res => {
        this.acknowledgedCards = res
        this.acknowledgedCardsDialog = true
      })
    },
    getStoragePath (value) {
      if (value.usp2 === 'true') {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_BASE_URL)}/${this.selectedCustomer.customer_id}/${value.img}`
      } else {
        return `${aesDecrypt(process.env.VUE_APP_STORAGE_USP1_URL)}/${this.selectedCustomer.customer_id}/${value.img}`
      }
    },
    selectSeries (item) {
      this.transactionData = Object.assign({}, this.transactionData, { serial_no: item.serial_number })
      this.acknowledgedCardsDialog = false
      this.renderToast('info', 'Selection Successful', `Series number ${item.serial_number} was successfully selected`)
    },
    tabSwitch (item, index) {
      console.log(item, index)
      const element = document.querySelectorAll('input')

      if (item.length === 4 && index === 2) {
        element[5].focus()
      } else if (item.length === 0 && index === 3) {
        element[4].focus()
      }
    },
    getCardNumber (e) {
      let clipboardValue = ''
      let pastedValue = ''

      console.log(e)
      e.stopPropagation()
      e.preventDefault()

      // Get pasted data via clipboard API
      clipboardValue = e.clipboardData || window.clipboardData
      pastedValue = clipboardValue.getData('Text')

      const substring = pastedValue.match(/.{1,4}/g)

      if (pastedValue.length === 8) {
        // this.cardNumber = ['4162', '9700', substring[0], substring[1]] // DEV
        this.cardNumber = ['4162', '9704', substring[0], substring[1]] // PROD
        e[5].focus()
      }
    },
    async selectFrontImg (item) {
      const getImageURL = this.getStoragePath({ usp2: 'true', img: item.img })
      const imgToBase64 = await this.urlToBase64(getImageURL)
      const imgToBase64Array = imgToBase64.split(',')

      const imgItems = {
        id_type: item.eon_value_sent,
        id_number: item.id_number,
        id_front_picture: imgToBase64Array[1]
      }

      console.log(item)
      this.transactionData = Object.assign({}, this.transactionData, imgItems)

      const primaryIdAcceptables = ['OWWA', 'OFW', 'NCDA', 'IBP', 'PAS', 'PRC', 'GSIS', 'SSS', 'UMID', 'DRL', 'TIN', 'NBI', 'PHID', 'VID', 'POS', 'SCID', 'EMPID', 'PHILSYSID']
      let secondaryIdAcceptables = ['AICR', 'GOCC', 'SBID', 'TINND', 'PHIDND', 'VIDND', 'POSND', 'SCIDND', 'EMPIDNP', 'SCH']
      const checkIfPrimary = primaryIdAcceptables.filter(e => e === item.eon_value_sent).length > 0
      const checkIfSecondary = secondaryIdAcceptables.filter(e => e === item.eon_value_sent).length > 0

      if (checkIfSecondary) {
        this.isFrontIDSecondary = true
        const secondaryIDListArray = []
        // Check if primary ID is School Id
        if (item.eon_value_sent === 'SCH') {
          secondaryIdAcceptables = ['SDSCH']
        }
        // Must display only the secondary ids
        Object.entries(secondaryIdAcceptables).forEach(idSecList => {
          const eonIdItem = this.eonIDList.filter(res => res.eon_value_sent === idSecList[1] && res.eon_value_sent !== item.eon_value_sent)
          if (eonIdItem.length > 0) {
            secondaryIDListArray.push(eonIdItem[0])
          }
          // switch (item.eon_value_sent) {
          //   case 'SCH':
          //     secondaryIDListArray.push({
          //       eon_description: 'Supporting Documents - School ID',
          //       eon_value_sent: 'SDSCH'
          //     })
          //     break
          //   default: {
          //     const eonIdItem = this.eonIDList.filter(res => res.eon_value_sent === idSecList[1] && res.eon_value_sent !== item.eon_value_sent)
          //     if (eonIdItem.length > 0) {
          //       secondaryIDListArray.push(eonIdItem[0])
          //     }
          //   }
          //     break
          // }
        })
        this.supportingIdList = secondaryIDListArray
      } else {
        this.isFrontIDSecondary = false
      }

      console.log(checkIfPrimary)
    },
    sendEmail () {
      const debitCardNumber = this.cardNumber.join('')

      const payload = {
        location_id: this.currUser.location_id,
        card_number: debitCardNumber
      }

      this.$store.dispatch('debitCard/inquireCardNumber', payload).then(res => {
        if (res.code === 200) {
          this.renderToast('success', 'Success', 'An Email Request has been sent.')
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    availCard (card) {
      this.selectedPurchase = card
      this.debitCardSummaryOverview.splice(0)

      switch (card) {
        case 'virtual':
          this.renderToast('info', 'Virtual Card Selected', 'You are currently applying for a Virtual Card.')

          this.debitCardSummaryOverview = [
            { name: 'Category', value: 'Virtual Card' },
            { name: 'Amount', value: '0.00' }
          ]
          break
        case 'phv':
          this.renderToast('info', 'PHV Card Selected', 'You are currently purchasing a PeraHub Visa Card.')

          this.debitCardSummaryOverview = [
            { name: 'OR Number', value: '-' },
            { name: 'Category', value: '-' },
            { name: 'Serial Number', value: '-' },
            { name: 'EON Card Number', value: '-' },
            { name: 'Amount', value: '-' }
          ]
          break
      }
    },
    convertToUpperCase (value) {
      if (value) {
        this.transactionData.secondary_id_number = value.toUpperCase()
      }
    },
    convertToProperCase (value) {
      if (value) {
        this.transactionData.company_name = value.toProperCase()
      }
    },
    idNumberValidator (text, type, country) {
      let rules = [v => !!v || 'This field is required']

      switch (type) {
        case 'Passport':
          if (country === 'Philippines') {
            rules = [
              ...rules,
              v => /^([a-zA-Z0-9]){9,9}$/.test(text) || 'Should be 9 characters and only letters/numbers are allowed'
            ]
          } else {
            rules = [
              ...rules,
              v => /^([a-zA-Z0-9]){6,9}$/.test(text) || 'Should be 6-9 characters and only letters/numbers are allowed'
            ]
          }
          break
        case 'Gov\'t Office/GOCC ID':
        case 'OWWA ID (Overseas Workers)':
        case 'Seaman\'s Book':
        case 'DSWD Certificate':
        case 'Senior Citizen Card':
        case 'Student\'s ID':
        case 'Police Clearance':
        case 'OFW ID':
        case 'Alien Cert. of Registration':
        case 'Employment ID':
        case 'Barangay Certification':
        case 'AFP ( Armed Forces of the Philippines ID)':
        case 'PRPWD (Philippine Registry for Persons with Disabilities)':
          rules = [
            ...rules,
            v => /^[a-zA-Z0-9]*$/.test(text) || 'Only letters/numbers are allowed'
          ]
          break
        case 'Driver\'s license':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){11,11}$/.test(text) || 'Should be 11 characters and only letters/numbers are allowed'
          ]
          break
        case 'Postal ID':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){12,12}$/.test(text) || 'Should be 12 characters and only letters/numbers are allowed'
          ]
          break
        case 'NBI Clearance':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){18,20}$/.test(text) || 'Should be 18-20 characters and only letters/numbers are allowed'
          ]
          break
        case 'Voter\'s ID':
          rules = [
            ...rules,
            v => /^([a-zA-Z0-9]){16,25}$/.test(text) || 'Should be 16-25 characters and only letters/numbers are allowed'
          ]
          break
        case 'GSIS e-Card':
          rules = [
            ...rules,
            v => /^([0-9]){11,11}$/.test(text) || 'Should be 11 characters and only numbers are allowed'
          ]
          break
        case 'Disabled Person Cert/ID NCWDP':
        case 'Unified MultiPurpose ID (UMID)':
        case 'PAG IBIG ID': // not on kycid
        case 'PhilHealth': // not on kycid
        case 'National Council for the Welfare': // not on kycid
          rules = [
            ...rules,
            v => /^([0-9]){12,12}$/.test(text) || 'Should be 12 characters and only numbers are allowed'
          ]
          break
        case 'PRC ID (Prof. Reg. Commission)':
          rules = [
            ...rules,
            v => /^([0-9]){7,7}$/.test(text) || 'Should be 7 characters and only numbers are allowed'
          ]
          break
        case 'Integrated Bar (IBP) ID':
          rules = [
            ...rules,
            v => /^([0-9]){5,5}$/.test(text) || 'Should be 5 characters and only numbers are allowed'
          ]
          break
        case 'SSS Card (Social Security)':
          rules = [
            ...rules,
            v => /^(01|02|03|04|05|06|07|08|09|10|33|34)([0-9]){8,8}$/.test(text) || 'Should begin with 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 33 or 34, must be 10 characters and only numbers are allowed'
          ]
          break
        case 'Taxpayer\'s ID (TIN ID)':
          rules = [
            ...rules,
            v => /^(([0-9]){12,12}|([0-9]){9,9})$/.test(text) || 'Should be 9 or 12 characters and only numbers are allowed'
          ]
          break
        case 'PhilSys (Philippine Identification System ID)':
          rules = [
            ...rules,
            v => /^([0-9]){16,16}$/.test(text) || 'Should be 16 characters and only numbers are allowed'
          ]
          break
      }

      return rules
    },
    async onCurrentCityChange (city) {
      const selectedCity = this.collections.cities.filter(item => item.city === city)

      if (selectedCity.length === 1) this.transactionData.company_province = selectedCity[0]?.province

      const barangayList = await this.$store.dispatch('getBarangayList', city)

      if (barangayList.code === 200) this.barangay = barangayList.result
    },
    setCurrentZIPCode (barangay) {
      const selectedBarangay = this.barangay.filter(item => item.barangay === barangay)

      if (selectedBarangay.length === 1) {
        this.transactionData.company_zipcode = selectedBarangay[0]?.zipcode
      }
    },
    checkEmployment (employmentType) {
      switch (employmentType) {
        case 'UEH':
        case 'UER':
        case 'UES':
          this.isUnemployed = true
          break
        default:
          this.isUnemployed = false
      }
    },
    clearImage (type) {
      console.log(type)
      switch (type) {
        case 'front':
          this.showFrontId = false
          break
        case 'back':
          this.showBackId = false
          break
        case 'selfie':
          this.showSelfie = false
          break
        case 'secondary':
          this.showSecondary = false
          break
        case 'secondary-back':
          this.showSecondaryBack = false
          break
      }
    },
    closeSpiel () {
      this.$store.commit('debitCard/SET_SHOW_SPIEL', false)
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'PHV',
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-debit-card-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-debit-card-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
    z-index: 2;
  }

  .ph-debit-card-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-debit-card-summary-minified-info {
    margin-right: auto;
  }

  .ph-debit-card-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-debit-card-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-debit-card-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  .ph-debit-card-providers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ph-debit-card-partner-container {
    height: 100px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
  }

  .ph-debit-card-partner-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-debit-card-partner-icon-img {
    margin: auto;
    object-fit: fill;
    z-index: 1;
    border-radius: 0 !important;
  }

  .ph-debit-card-partner-icon-img.partner-paymaya {
    height: 50px;
    width: auto;
    /* position: relative;
    bottom: 15px; */
  }

  .ph-debit-card-partner-icon-img.partner-diskartech {
    height: 45px;
    width: auto;
  }

  .ph-debit-card-partner-icon-img.partner-dragonpay {
    height: 65px;
    width: auto;
  }

  .ph-debit-card-partner-icon-img.partner-ph,
  .ph-debit-card-partner-icon-img.partner-eon {
    height: 30px;
    width: auto;
  }

  .ph-debit-card-partner-icon-img.partner-phv {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }

  .phv-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    font-size: 25px;
    font-family: 'Proxima Nova Bold';
  }

  .ph-debit-card-fields,
  .ph-debit-card-back-button {
    margin: 0;
  }

  .ph-debit-card-partner-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .ph-image-upload-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }

  .ph-debit-card-forms {
    width: 100%;
  }

  .phv-purchase-option-container {
    height: 175px;
    display: flex;
    z-index: 2;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
    border-radius: 12px !important;
    transition: all .2s;
    cursor: pointer;
    flex-direction: column;
    text-align: center;
  }

  .phv-purchase-option-container:hover {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .phv-purchase-option-thumbnail {
    height: 100px;
    width: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .phv-purchase-option-container.selected {
    background: rgba(26, 39, 145, 0.1);
    border: 1px solid #1A2791;
  }

  .spiel-container {
    line-height: 1.3;
    padding: 30px 24px !important;
  }

  .spiel-header {
    color: #1A2791;
    font-family: 'Baloo Extra Bold';
    margin-bottom: 20px;
  }

  .spiel-text {
    font-size: 15px;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }

    .ph-debit-card-forms-container {
      margin: 0;
    }
  }
</style>
<style>
  .v-input.ph-textbox.ph-debit-card-upload .v-input__prepend-outer .v-input__icon .v-icon--link {
    margin: 0;
    margin-left: 15px;
    margin-bottom: 5px;
  }
</style>
