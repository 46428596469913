<template>
  <v-row class="ph-wu-quickpay-forms-container">
    <v-col cols="12" v-if="showRemoteNotice">
      <v-alert
        color="info"
        dark
        icon="mdi-remote"
        border="left"
        prominent
      >
        This transaction will be processed on behalf of <b>{{remoteData.remote_location.location_name}}</b> branch.
      </v-alert>
    </v-col>
    <v-col cols="12" md="8" class="ph-wu-quickpay-form">
      <v-form ref="quickPayForm">
        <v-btn text class="text-none" @click="backToSearch">
          <v-icon>mdi-arrow-left</v-icon> Back to Search
        </v-btn>
        <v-stepper v-model="quickPayForm" elevation="0" class="ph-wu-quickpay-stepper">
          <v-stepper-header>
            <v-stepper-step
              :complete="quickPayForm > 1"
              step="1"
            >
              Transfer Information
            </v-stepper-step>
            <v-divider class="ph-wu-quickpay-stepper-divider"></v-divider>
            <v-stepper-step
              :complete="quickPayForm > 2"
              step="2"
            >
              KYC Lookup
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <QuickPayTransferInfo v-bind:quickPayTrxData="quickPayTrxData" />
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="toggleForm(2)"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <QuickPayKYCLookup v-bind:quickPayTrxData="quickPayTrxData" />
              <v-row>
                <v-col cols="12" class="ph-stepper-action-buttons">
                  <v-btn
                    color="danger"
                    outlined
                    text
                    light
                    class="ph-primary-button ph-stepper-button ph-action-button"
                    @click="toggleForm(1)"
                  >
                    Back
                  </v-btn>
                  <v-spacer/>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
                    @click="summaryDialog = true"
                    v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="4" class="ph-wu-quickpay-summary">
      <v-card
        elevation="0"
        color="rgba(26, 39, 145, 0.05)"
      >
        <div class="ph-transfer-summary-header">
          <b>Summary Overview</b>
        </div>
        <br>
        <div class="ph-transfer-summary-content">
          <p class="ph-transfer-summary-content-title">Please confirm if the Quick Pay details are correct</p>
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in transferSummaryOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="ph-transfer-summary-verify">
          <v-checkbox
            v-model="ackFlag"
            label="I have Verified Customer Data"
          ></v-checkbox>
        </div>
        <wacom-transaction
          v-if="currUser.has_wacom"
          @submit-sig="submitSig" type="transactional"
          :referenceSignatures="customerSignaturesReference"
          :trxData="{sender_name: `${quickPayTrxData.fullName}`, receiver_name: `${quickPayTrxData.company}`, total_amount: sendoutAmount}"
        />
        <wacom-consent />
        <wacom-data-consent-first />
        <wacom-data-consent-second />
        <wacom-data-consent-third />
        <wacom-data-consent-fourth />
        <wacom-data-consent-fifth />
        <wacom-data-consent-sixth />
        <wacom-data-consent-seventh
          :remco="'Western Union'"
        />
        <div class="ph-transfer-summary-action">
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-action-button"
            :disabled="ackFlag === false"
            @click="prePrintReceipt"
          >
            PRE-PRINT
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn"
            :disabled="isPrePrintClicked === false"
            @click="checkEmail"
          >
            SEND NOW
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-quickpay-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-quickpay-summary-minified">
          <div class="ph-wu-quickpay-summary-content">
            <div class="ph-wu-quickpay-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>{{sendoutCurrency && sendoutAmount ? `${sendoutCurrency} ${sendoutAmount}`: '-'}}</b></p>
            </div>
            <div class="ph-wu-quickpay-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <div class="ph-transfer-summary-content">
                <p class="ph-transfer-summary-content-title">Please confirm if the sendout details are correct</p>
                <v-simple-table class="ph-transfer-summary-table">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="item in transferSummaryOverview"
                        :key="item.name"
                      >
                        <td class="transfer-info-title">{{ item.name }}</td>
                        <td class="transfer-info-value">{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="ph-transfer-summary-verify">
                <v-checkbox
                  v-model="ackFlag"
                  label="I have Verified Customer Data"
                ></v-checkbox>
              </div>
              <div class="ph-transfer-summary-action">
                <v-btn
                  color="danger"
                  outlined
                  text
                  light
                  class="ph-primary-button ph-action-button"
                  :disabled="ackFlag === false"
                  @click="prePrintReceipt"
                >
                  PRE-PRINT
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  class="ph-primary-button ph-primary-execute-btn"
                  :disabled="isPrePrintClicked === false"
                  @click="checkEmail"
                >
                  Confirm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noIDFlag"
      width="500"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          No identification card found
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          The selected customer does not have a record of his/her identification cards in our system.<br><br>Valid ID's are required on this transaction.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="noIDFlag = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="updateDetails(selectedCustomer)"
          >
            Update Customer Record
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- OTP -->
    <v-dialog
      v-model="qpOTPDialog"
      persistent
      width="30%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="qpOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="manualConfirmPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Would you like to proceed with this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="manualConfirmPrompt = false"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="validationDialog" persistent>
      <v-card>
        <v-card-title class="ph-dialog-title">
          Form Validation
        </v-card-title>
        <v-card-text class="ph-dialog-header">
          <br>
          There are errors in the form submitted. Please refer to the message below:
          <br>
          <span v-html="validationMessage"></span>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="validationDialog = false"
          >
            Ok, got it.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import QuickPayTransferInfo from '@/components/wu/QuickPayTransferInfo'
import QuickPayKYCLookup from '@/components/wu/QuickPayKYCLookup'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction.vue'
import wacomConsent from '@/components/wacom/wacomConsent.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import { renderToast } from '@/utils'
import { validateWuQuickPay } from '@/utils/validations/wu'

export default {
  name: 'QuickPayForm',
  mixins: wacomMixin,
  data () {
    return {
      quickPayForm: 1,
      ackFlag: false,
      isPrePrintClicked: false,
      showRemoteNotice: false,
      quickPayTrxData: {},
      transferSummaryOverview: [],
      terminalId: '',
      operatorId: '',
      locationId: '',
      qpOTPDialog: false,
      otp: '',
      otpInvalid: false,
      isOTPLoading: false,
      otpMessage: '',
      summaryDialog: false,
      sendoutCurrency: '',
      sendoutAmount: '',
      inquireRes: {},
      receiverCountryName: '',
      customerSignaturesReference: [],
      noIDFlag: false,
      requiredField: [
        v => !!v || 'This field is required'
      ],
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      hasCustomerDataConsent: false,
      validationDialog: false,
      validationMessage: '',
      manualConfirmPrompt: false
    }
  },
  components: {
    QuickPayTransferInfo,
    QuickPayKYCLookup,
    wacomTransaction,
    wacomConsent,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  computed: {
    ...mapGetters({
      collections: 'collections',
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData',
      selectedCustomer: 'customers/selectedCustomer',
      qpForeignRefNo: 'wu/qpForeignRefNo',
      wuKYCSingleSearchData: 'wu/wuKYCSingleSearchData',
      qpValidateData: 'wu/qpValidateData',
      oarNumber: 'oar/oarNumber',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues',
      seriesDetail: 'manualReceipt/seriesDetail'
    })
  },
  watch: {
    quickPayTrxData: {
      handler (val) {
        this.populateOverview(val)
      },
      deep: true
    }
  },
  async mounted () {
    this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')

    if (this.selectedCustomer) {
      if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        this.terminalId = this.remoteData.ftid
        this.operatorId = this.remoteData.remote_user
        this.locationId = this.remoteData?.remote_location?.location_id
        this.showRemoteNotice = true
      } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        this.terminalId = this.currUser.fs_id
        this.operatorId = this.currUser.user_id
        this.locationId = this.currUser.location_id
        this.showRemoteNotice = false
      } else {
        this.terminalId = this.currUser.fs_id
        this.operatorId = this.currUser.user_id
        this.locationId = this.currUser.location_id
        this.showRemoteNotice = false
      }

      this.transferSummaryOverview = [
        { name: 'Sender Name', value: this.selectedCustomer.full_name },
        { name: 'Sender DOB', value: this.selectedCustomer.birth_date },
        { name: 'Receiver Name', value: '-' },
        { name: 'Destination', value: '-' },
        { name: 'Exchange Rate', value: '-' },
        { name: 'Send Amount', value: '-' },
        { name: 'Transfer Fee', value: '-' },
        { name: 'Pay Amount', value: '-' },
        { name: 'Total Amount', value: '-' }
      ]

      const initialData = {
        fullName: this.selectedCustomer.full_name,
        occupation: this.selectedCustomer.occupation,
        sourceOfFund: this.selectedCustomer.source_fund,
        relationTo: this.selectedCustomer.relation_to,
        purposeOfTrasaction: this.selectedCustomer.purpose_transaction,
        countryOfBirth: this.selectedCustomer.country_birth,
        nationality: this.selectedCustomer.nationality,
        isOnBehalf: 'N',
        requiredField: this.requiredField
      }

      console.log(this.selectedCustomer.img)

      if (this.selectedCustomer.img === '{}' || this.selectedCustomer.img === '') {
        this.noIDFlag = true
      } else {
        this.noIDFlag = false
      }

      this.quickPayTrxData = Object.assign({}, this.quickPayTrxData, initialData)

      const that = this
      setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
      setTimeout(wacomMixin.methods.checkForWacom, 2000)
      const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
      that.customerSignaturesReference = signatures.result
      this.checkConsent()
    }
  },
  methods: {
    validateWuQuickPay,
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.openVerifyOTP()
    },
    async proceedTransaction () {
      this.openOTPDialog()
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        if (this.currUser.receipt_type === 3) {
          this.sendViaEmail = true
          if (this.selectedCustomer.email_add) {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = true
          } else {
            this.withoutEmailPrompt = true
            this.withEmailPrompt = false
            this.sendViaEmail = false
          }
        } else {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = false
          this.sendViaEmail = false
          this.manualConfirmPrompt = true
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.openOTPDialog()
    },
    submitSig (emitted) {
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
      this.customerSignature = emitted
      if (this.hasCustomerDataConsent === false) {
        this.generateConsent()
      }
      this.generatePrePrint()
    },
    renderToast,
    backToSearch () {
      this.$store.commit('wu/SET_QP_CURRENT_PAGE', 0)
      this.$store.commit('wu/SET_WU_KYC_SINGLE_SEARCH_DATA', {})
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
    },
    toggleForm (step) {
      this.quickPayForm = step

      switch (step) {
        case 1:
          this.$store.commit('SET_PAGE_TITLE', 'Transfer Information')
          break
        case 2:
          this.$store.commit('SET_PAGE_TITLE', 'KYC Lookup')
          break
      }
    },
    populateOverview (payload) {
      this.transferSummaryOverview = [
        { name: 'Sender Name', value: this.selectedCustomer.full_name },
        { name: 'Sender DOB', value: this.selectedCustomer.birth_date },
        { name: 'Receiver Name', value: payload?.company ? payload.company : '-' },
        { name: 'Destination', value: payload?.country ? payload.country : '-' },
        { name: 'Exchange Rate', value: payload?.exchangeRate ? payload.exchangeRate : '-' },
        { name: 'Send Amount', value: payload?.sendingCurrency ? `${payload.sendingCurrency} ${numeral(payload.sendAmount).format('0,0.00')}` : '-' },
        { name: 'Transfer Fee', value: payload?.sendingCurrency ? `${payload.sendingCurrency} ${numeral(payload.transferFee).format('0,0.00')}` : '-' },
        { name: 'Pay Amount', value: payload?.receivingCurrency ? `${payload.receivingCurrency} ${numeral(payload.receiveAmount).format('0,0.00')}` : '-' },
        { name: 'Total Amount', value: payload?.sendingCurrency ? `${payload.sendingCurrency} ${numeral(payload.totalAmount).format('0,0.00')}` : '-' }
      ]

      this.sendoutCurrency = payload.sendingCurrency
      this.sendoutAmount = numeral(payload.totalAmount).format('0,0.00')
    },

    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },

    async generatePrePrint () {
      console.log('inquire res', this.inquireRes)
      console.log('current trx', this.quickPayTrxData)
      console.log('current customer', this.selectedCustomer)
      const currentTrx = this.quickPayTrxData
      const prePrintPayload = {}
      // const receiverMiddleName = currentTrx.receiver.name.middle_name ? currentTrx.receiver.name.middle_name : ''
      // const senderMiddleName = currentTrx.sender.name.middle_name ? currentTrx.sender.name.middle_name : ''
      // const filteredOrigin = this.collections.countries.filter(function (e) {
      //   return e.currency === currentTrx.payment_details.originating_country_currency.iso_code.currency_code
      // })
      console.log(this.collections.countries)
      const filteredDest = this.collections.countries.filter(function (e) {
        return e.three_iso_code === currentTrx.country
      })
      console.log(filteredDest)
      this.receiverCountryName = filteredDest[0].country
      // this.originCurrencyName = filteredOrigin[0].currency_name
      // this.destCurrencyName = filteredDest[0].currency_name
      // console.log(filteredOrigin, 'filtered origin')
      prePrintPayload.print_data = {
        receiver_name: currentTrx.company,
        sender_name: currentTrx.fullName,
        mywu_number: (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : '',
        points_earned: '0',
        total_wu_points: '',
        id_type: currentTrx.idName,
        id_number: currentTrx.idNumber,
        id_expiration: currentTrx.idExpirationDate,
        tin_number: '',
        sss_gsis_number: '',
        occupation: this.selectedCustomer.occupation,
        name_of_employer: this.selectedCustomer.employer_name,
        date_of_birth: this.selectedCustomer.birth_date,
        country_of_birth: this.selectedCustomer.country_birth,
        nationality: this.selectedCustomer.nationality,
        purpose_of_transaction: this.selectedCustomer.purpose_transaction,
        source_of_funds: this.selectedCustomer.source_fund,
        relationship: this.selectedCustomer.relation_to,
        present_address_line1: this.selectedCustomer.address,
        present_address_line2: this.selectedCustomer.barangay,
        present_city: this.selectedCustomer.municipality,
        present_state: this.selectedCustomer.province,
        present_postcode: this.selectedCustomer.zip_code,
        permanent_address_line1: this.selectedCustomer.street1,
        permanent_address_line2: this.selectedCustomer.barangay1,
        permanent_city: this.selectedCustomer.city1,
        permanent_state: this.selectedCustomer.province1,
        permanent_postcode: this.selectedCustomer.zip_code1,
        landline: '',
        mobile_no: this.selectedCustomer.mobile_no,
        mtcn: '',
        date_and_time: this.formatDateTime(new Date()),
        agent_details: this.currUser.location_name + ' BRANCH',
        originating_country: 'Philippines',
        city: currentTrx.payoutCity + ' ' + currentTrx.payoutState.state_code,
        amount_receive: '',
        total: 'Philippine Peso ' + (this.inquireRes.result.financials.gross_total_amount / 100).toFixed(2),
        pay_amount: currentTrx.receivingCurrency + ' ' + (this.inquireRes.result.financials.destination_principal_amount / 100).toFixed(2),
        exchange_rate: currentTrx.exchangeRateValue,
        dst_pay_amount: '',
        cash: '',
        checque: '',
        checque_number: '',
        credit_to_bank_account: '',
        account_number: '',
        amount_sent: (this.inquireRes.result.financials.originators_principal_amount / 100).toFixed(2),
        transfer_fee: (this.inquireRes.result.financials.charges / 100).toFixed(2),
        delivery_charge: '0.00',
        discount: '',
        destination_country: filteredDest[0].country,
        originating_curr: '',
        destination_curr: '',
        message_charge: '0.00',
        mc_rate: '',
        buyback_amount: '',
        total_amount: '',
        message: ''
      }
      if (this.currUser.has_wacom) {
        prePrintPayload.print_data.fla_signature = this.currUser.userSignature
        prePrintPayload.print_data.customer_signature = this.customerSignature
      }
      prePrintPayload.data = {
        has_wacom: this.currUser.has_wacom,
        trx_type: 1,
        remco_name: 'Western Union',
        control_number: this.qpValidateData.new_details.mtcn,
        customer_id: this.selectedCustomer.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: this.selectedCustomer.customer_number,
        uploaded_by: this.currUser.user_id,
        first_name: this.selectedCustomer.first_name,
        last_name: this.selectedCustomer.last_name,
        product: 'WU Quickpay',
        receipt_type: 'pre'
      }
      if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.remoteData.remote_location.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.remoteData?.remote_location?.location_id
        prePrintPayload.data.uploaded_by = this.remoteData.remote_user
        prePrintPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
      } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.currUser.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.currUser.location_id
        prePrintPayload.data.uploaded_by = this.currUser.user_id
        prePrintPayload.data.location_name_uploaded = this.currUser.location_name
      }
      console.log(prePrintPayload)
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', prePrintPayload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      this.renderToast('success', 'Pre-print receipt generated', 'The receipt was successfully generated. You may now process the transaction')
    },

    async prePrintReceipt () {
      const currentTrx = this.quickPayTrxData
      const currentCustomer = this.selectedCustomer

      const singleKYCLookupPayload = {
        foreign_reference_no: this.qpForeignRefNo,
        search_by: 'by_id',
        id_type: currentTrx.idType,
        id_number: currentTrx.idNumber,
        contact_phone: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        first_name: currentCustomer.first_name,
        last_name: currentCustomer.last_name,
        is_multi: 'S',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        location_id: this.locationId
      }

      let kycLookupCustomer = {}
      let customerComplianceBuffer = ''
      let galacticId = ''

      console.log(singleKYCLookupPayload)

      if (!this.wuKYCSingleSearchData?.customer) {
        await this.$store.dispatch('wu/kycLookup', singleKYCLookupPayload).then(res => {
          if (res.result) {
            kycLookupCustomer = res.result.customer
            customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
            galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        kycLookupCustomer = this.wuKYCSingleSearchData.customer
        customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
        galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
      }

      const senderCountryDetails = this.collections.countries.filter(item => item.country === this.selectedCustomer.country)

      let senderCountryCode = ''
      let senderCountryCurrencyCode = ''
      let wuNationality = ''

      if (senderCountryDetails.length === 1) {
        senderCountryCode = senderCountryDetails[0].two_iso_code
        senderCountryCurrencyCode = senderCountryDetails[0].currency
      }

      const selectedNationality = this.collections.countries.filter(item => item.nationality === currentTrx.nationality)

      if (selectedNationality.length === 1) {
        wuNationality = selectedNationality[0]?.country
      }

      const idDetails = this.collections.wuIDList.filter(item => item.id_value === currentTrx.idType)
      let hasIssueDate = ''
      let hasExpirationDate = ''

      if (idDetails.length > 0) {
        hasIssueDate = idDetails[0].hasIssueDate
        hasExpirationDate = idDetails[0].hasExpiration
        console.log(idDetails)
      }

      const birthDate = this.selectedCustomer.birth_date

      const payload = {
        foreign_reference_no: this.qpForeignRefNo,
        sender_name_type: this.wuKYCSingleSearchData?.customer?.name?.name_type ? this.wuKYCSingleSearchData.customer.name.name_type : 'D',
        sender_first_name: currentCustomer.first_name,
        sender_middle_name: currentCustomer.middle_name,
        sender_last_name: currentCustomer.last_name,
        sender_addr_line1: currentCustomer.address,
        sender_addr_line2: currentCustomer.barangay,
        sender_city: currentCustomer.city1,
        sender_state: currentCustomer.province,
        sender_postal_code: currentCustomer.zip_code,
        sender_addr_country_code: senderCountryCode,
        sender_addr_currency_code: senderCountryCurrencyCode,
        sender_addr_country_name: currentCustomer.country,
        sender_contact_phone: currentCustomer.tel_no,
        sender_mobile_country_code: currentCustomer.mobile_no !== '' ? (currentCustomer.mobile_country_code.substring(1) || '63') : '',
        sender_mobile_no: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        id_type: currentTrx.idType,
        id_country_of_issue: currentTrx.idIssueCountry,
        id_number: currentTrx.idNumber,
        id_issue_date: currentTrx.idIssueDate ? format(parseISO(currentTrx.idIssueDate), 'ddMMyyyy') : '',
        id_expiration_date: currentTrx.idExpirationDate ? format(parseISO(currentTrx.idExpirationDate), 'ddMMyyyy') : '',
        date_of_birth: birthDate.match('\\d{4}-\\d{2}-\\d{2}') ? format(parseISO(currentCustomer.birth_date), 'ddMMyyyy') : currentCustomer.birth_date,
        occupation: currentTrx.occupation,
        Country_of_Birth: currentTrx.countryOfBirth,
        nationality: wuNationality,
        Source_of_Funds: currentTrx.sourceOfFund,
        Sender_Employeer: currentCustomer.employer_name,
        Relationship_to_Receiver: currentTrx.relationTo,
        GEN_III_Indicator: '1',
        ack_flag: this.ackFlag === true ? 'X' : '',
        mywu_enroll_tag: 'conv',
        email: currentCustomer.email_add,
        company_name: currentTrx.company,
        company_code: currentTrx.codeCity,
        company_account_code: currentTrx.accountNumber,
        reference_no: currentTrx.reference,
        destination_country_code: currentTrx.countryCode,
        destination_currency_code: currentTrx.receivingCurrency,
        destination_state_code: currentTrx.payoutState.state_code,
        destination_city_name: currentTrx.payoutCity,
        transaction_type: 'QP',
        principal_amount: currentTrx.principalAmount * 100,
        fixed_amount_flag: currentTrx.fixedAmountFlag,
        promo_code: currentTrx.promoCode ? currentTrx.promoCode : '',
        message: [],
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        remote_terminal_id: this.currUser.location_id,
        remote_operator_id: this.currUser.user_id,
        employment_position_level: currentCustomer.position_level,
        Purpose_of_Transaction: currentTrx.purposeOfTrasaction,
        is_on_behalf: '',
        galactic_id: galacticId,
        gender: currentCustomer.gender === 'female' ? 'F' : 'M',
        location_id: this.locationId
      }

      const toValidate = {
        id_type: currentTrx.idType,
        id_country_of_issue: currentTrx.idIssueCountry,
        id_number: currentTrx.idNumber,
        id_issue_date: currentTrx.idIssueDate ? format(parseISO(currentTrx.idIssueDate), 'ddMMyyyy') : '',
        id_expiration_date: currentTrx.idExpirationDate ? format(parseISO(currentTrx.idExpirationDate), 'ddMMyyyy') : '',
        Purpose_of_Transaction: currentTrx.purposeOfTrasaction,
        is_on_behalf: currentTrx.isOnBehalf,
        employment_position_level: currentCustomer.position_level,
        occupation: currentTrx.occupation,
        sender_addr_country_code: senderCountryCode,
        Source_of_Funds: currentTrx.sourceOfFund,
        sender_addr_line1: currentCustomer.address,
        // mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        destination_country_code: currentTrx.countryCode,
        Country_of_Birth: currentTrx.countryOfBirth,
        // reason_for_send: '',
        date_of_birth: birthDate.match('\\d{4}-\\d{2}-\\d{2}') ? format(parseISO(currentCustomer.birth_date), 'ddMMyyyy') : currentCustomer.birth_date,
        sender_mobile_country_code: currentCustomer.mobile_no !== '' ? (currentCustomer.mobile_country_code.substring(1) || '63') : '',
        sender_contact_phone: currentCustomer.tel_no,
        sender_mobile_no: currentCustomer.mobile_no.substring(1),
        has_issue_date: hasIssueDate,
        has_expiration_date: hasExpirationDate,
        foreign_reference_no: this.qpForeignRefNo,
        sender_first_name: currentCustomer.first_name
      }

      const checkContactPhoneFormat = toValidate.sender_mobile_no !== '' && toValidate.sender_mobile_no.match(/^\d+$/)
      const checkTelNumberFormat = toValidate.sender_contact_phone !== '' && toValidate.sender_contact_phone.match(/^\d+$/)
      const checkPhoneCountryCodeFormat = toValidate.sender_mobile_country_code !== '' && toValidate.sender_mobile_country_code.match(/^\d+$/)

      const checkOccupation = this.collections.occupation.filter(res => {
        if (res.occupation_value === this.selectedCustomer.occupation) {
          return true
        } else {
          return false
        }
      })

      const checkPurpose = this.collections.purposeOfTransaction.filter(res => {
        if (res.purpose === this.selectedCustomer.purpose_transaction) {
          return true
        } else {
          return false
        }
      })

      const checkSourceOfFund = this.collections.sourceOfFund.filter(res => {
        if (res.source_of_funds === this.selectedCustomer.source_fund) {
          return true
        } else {
          return false
        }
      })

      const checkSenderCountry = this.collections.countries.filter(res => {
        if (res.country === this.selectedCustomer.country) {
          return true
        } else {
          return false
        }
      })

      const checkBirthCountry = this.collections.countries.filter(res => {
        if (res.country === this.selectedCustomer.country_birth) {
          return true
        } else {
          return false
        }
      })

      const selectedCity = this.collections.cities.filter(item => item.city === this.selectedCustomer.municipality)
      let currentProvince = ''

      if (selectedCity.length === 1) currentProvince = selectedCity[0]?.province

      const checkProvince = currentProvince === this.selectedCustomer.province

      const validateCheck = await this.validateWuQuickPay(toValidate)
      this.validationMessage = ''

      if (this.quickPayTrxData.destinationCountryCode === 'MX' || this.quickPayTrxData.destinationCountryCode === 'US') {
        let isStateInvalid = false

        if (this.quickPayTrxData.receiverState === '') {
          this.validationDialog = true
          this.validationMessage += 1 + '. Receiver State is Required<br>'
          isStateInvalid = true
        }

        if (this.quickPayTrxData.destinationCountryCode === 'MX') {
          if (this.quickPayTrxData.receiverCity === '') {
            this.validationDialog = true
            this.validationMessage += (isStateInvalid ? 2 : 1) + '. Receiver City is Required<br>'
          }
        }
      } else if (toValidate.sender_mobile_no !== '' && !checkContactPhoneFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Mobile Number<br>'
      } else if (toValidate.sender_mobile_country_code !== '' && !checkPhoneCountryCodeFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Phone Country Code<br>'
      } else if (toValidate.sender_contact_phone !== '' && !checkTelNumberFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Telephone Number<br>'
      } else if (checkOccupation.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Occupation<br>'
      } else if (checkPurpose.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Purpose<br>'
      } else if (checkSourceOfFund.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Source Of Funds<br>'
      } else if (checkSenderCountry.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Sender Country<br>'
      } else if (checkBirthCountry.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Birth Country<br>'
      } else if (!checkProvince) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Province<br>'
      } else if (validateCheck?.error) {
        this.validationDialog = true
        validateCheck.error.details.forEach((item, index) => {
          this.validationMessage += (index + 1) + '. ' + item.message + '<br>'
        })
      } else {
        if (this.$refs.quickPayForm.validate()) {
          await this.$store.dispatch('wu/doValidateQPTransaction', payload).then(res => {
            console.log(res)
            if (res?.result) {
              this.inquireRes = res
              if (this.currUser.has_wacom) {
                if (this.hasCustomerDataConsent) {
                  this.$store.commit('wacom/SET_WACOM_CONSENT_DIALOG', true)
                } else {
                  this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
                }
              } else {
                this.generatePrePrint()
              }
              this.renderToast('success', 'Success', 'Validate Successful')
              this.isPrePrintClicked = true
            } else {
              this.renderToast('error', 'WU Error', res.message)
            }
          }).catch(err => {
            console.log(err)
            this.renderToast('error', 'WU Error', err.message)
          })
        } else {
          this.renderToast('error', 'Incomplete Data!', 'You must complete the form to process this transaction.')
        }
      }
    },
    openVerifyOTP () {
      this.otp = ''
      if (this.isPrePrintClicked === true) {
        this.qpOTPDialog = true
      } else {
        this.renderToast('error', 'Pre-print receipt not yet generated!', 'You must generate a Pre-Print receipt first in order to process this transaction.')
      }
    },
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    async doVerify (pin) {
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        const payload = {
          user_name: this.currUser.user_name,
          otp_code: this.otp,
          pin: this.currUser.otp_pin,
          module: 'send'
        }

        const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)
        const isOTPVerified = OTPVerification.result?.value || false

        if (isOTPVerified) {
          this.otpInvalid = false
          this.isOTPLoading = false
          this.qpOTPDialog = false

          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              const getReceipt = await this.$store.dispatch('getReceipt', {
                location_id: this.currUser.location_id,
                type: '2',
                application_id: this.currUser.application_id
              })

              if (getReceipt.code === 200) {
                this.payTransaction()
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
              }
            } else {
              const payload = {
                location_id: this.currUser.location_id,
                type: 'OAR'
              }

              await this.$store.dispatch('manualReceipt/getAvailableSeriesNo', payload).then(res => {
                if (res.code >= 200 && res.code <= 299) {
                  if (res.result.length > 0) {
                    this.payTransaction()
                  } else {
                    this.renderToast('error', 'Error', 'No available series number for this branch. Please request from support.')
                  }
                } else {
                  this.renderToast('error', 'Error', res.message)
                }
              })
            }
          } else {
            this.payTransaction()
          }
        } else {
          this.otp = ''
          this.otpInvalid = true
          this.isOTPLoading = false
          this.otpMessage = 'Incorrect OTP PIN, please try again.'
        }
      } else {
        this.otpInvalid = true
        this.isOTPLoading = false
        this.otpMessage = 'OTP PIN must be 6-digits'
      }
    },
    async payTransaction () {
      const qpValidateData = this.qpValidateData
      const currentTrx = this.quickPayTrxData
      const currentCustomer = this.selectedCustomer

      const singleKYCLookupPayload = {
        foreign_reference_no: this.qpForeignRefNo,
        search_by: 'by_id',
        id_type: currentTrx.idType,
        id_number: currentTrx.idNumber,
        contact_phone: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        first_name: currentCustomer.first_name,
        last_name: currentCustomer.last_name,
        is_multi: 'S',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        location_id: this.locationId
      }

      let kycLookupCustomer = {}
      let customerComplianceBuffer = ''
      let galacticId = ''

      if (!this.wuKYCSingleSearchData?.customer) {
        await this.$store.dispatch('wu/kycLookup', singleKYCLookupPayload).then(res => {
          if (res.result) {
            kycLookupCustomer = res.result.customer
            customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
            galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
          } else {
            if (qpValidateData) {
              customerComplianceBuffer = qpValidateData.compliance.compliance_data_buffer
              galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
            }
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        kycLookupCustomer = this.wuKYCSingleSearchData.customer
        customerComplianceBuffer = kycLookupCustomer.compliance_details.compliance_data_buffer
        galacticId = customerComplianceBuffer.substring(customerComplianceBuffer.indexOf('J6') + 4)
      }

      console.log(qpValidateData)
      console.log(currentCustomer)
      console.log(kycLookupCustomer)

      const senderCountryDetails = this.collections.countries.filter(item => item.country === this.selectedCustomer.country)

      let senderCountryCode = ''
      let senderCountryCurrencyCode = ''
      if (senderCountryDetails.length === 1) {
        senderCountryCode = senderCountryDetails[0].two_iso_code
        senderCountryCurrencyCode = senderCountryDetails[0].currency
      }

      let wuNationality = ''
      const selectedNationality = this.collections.countries.filter(item => item.nationality === currentTrx.nationality)

      if (selectedNationality.length === 1) {
        wuNationality = selectedNationality[0]?.country
      }

      const payload = {
        foreign_reference_no: this.qpForeignRefNo,
        sender_name_type: this.wuKYCSingleSearchData?.customer?.name?.name_type ? this.wuKYCSingleSearchData.customer.name.name_type : 'D',
        sender_first_name: currentCustomer.first_name,
        sender_middle_name: currentCustomer.middle_name,
        sender_last_name: currentCustomer.last_name,
        sender_addr_country_code: senderCountryCode,
        sender_addr_currency_code: senderCountryCurrencyCode,
        sender_contact_phone: currentCustomer.tel_no,
        sender_mobile_country_code: currentCustomer.mobile_country_code.substring(1),
        sender_mobile_no: currentCustomer.mobile_no.substring(1),
        mywu_number: (currentCustomer.wu_card_no !== '0' || currentCustomer.wu_card_no !== null) ? currentCustomer.wu_card_no : '',
        mywu_enroll_tag: 'conv',
        email: currentCustomer.email_add,
        company_name: currentTrx.company,
        company_code: currentTrx.codeCity,
        company_account_code: currentTrx.accountNumber,
        reference_no: currentTrx.reference,
        destination_country_code: currentTrx.countryCode,
        destination_currency_code: currentTrx.receivingCurrency,
        destination_state_code: currentTrx.payoutState.state_code,
        destination_city_name: currentTrx.payoutCity,
        transaction_type: 'QP',
        principal_amount: qpValidateData.financials.originators_principal_amount,
        fixed_amount_flag: currentTrx.fixedAmountFlag,
        promo_code: currentTrx.promoCode ? currentTrx.promoCode : '',
        message: '[]',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        remote_terminal_id: this.currUser.location_id,
        remote_operator_id: this.currUser.user_id,
        second_id_number: '',
        third_id_number: '',
        addl_service_charges: qpValidateData.service_code.addl_service_charges,
        compliance_data_buffer: qpValidateData.compliance.compliance_data_buffer,
        originating_city: qpValidateData.payment_details.originating_city,
        originating_state: qpValidateData.payment_details.originating_state,
        financials: qpValidateData.financials,
        promotions: qpValidateData.promotions,
        mtcn: qpValidateData.new_details.mtcn,
        new_mtcn: qpValidateData.new_details.new_mtcn,
        exchange_rate: currentTrx.exchangeRateValue,
        otp: this.otp,
        user_code: this.currUser.user_id,
        compliance_details: {
          id_details: {
            id_type: currentTrx.idType,
            id_country_of_issue: currentTrx.idIssueCountry,
            id_number: currentTrx.idNumber
          },
          third_id_number: '',
          id_issue_date: format(parseISO(currentTrx.idIssueDate), 'ddMMyyyy'),
          id_expiration_date: format(parseISO(currentTrx.idExpirationDate), 'ddMMyyyy'),
          date_of_birth: format(parseISO(currentCustomer.birth_date), 'ddMMyyyy'),
          occupation: currentTrx.occupation,
          transaction_reason: currentTrx.purposeOfTrasaction,
          Country_of_Birth: currentTrx.countryOfBirth,
          nationality: wuNationality,
          Source_of_Funds: currentTrx.sourceOfFund,
          Current_address: {
            addr_line1: currentCustomer.address,
            addr_line2: currentCustomer.barangay,
            city: currentCustomer.city1,
            state_name: currentCustomer.province,
            postal_code: currentCustomer.zip_code,
            country: currentCustomer.country
          },
          Name_of_Employer_Business: currentCustomer.employer_name,
          Relationship_to_Receiver_Sender: currentTrx.relationTo,
          I_act_on_My_Behalf: currentTrx.isOnBehalf,
          galactic_id: galacticId,
          employment_position_level: currentCustomer.position_level
        },
        customer_code: currentCustomer.customer_number,
        client_reference_no: this.qpForeignRefNo,
        wu_trx_category: 'QP',
        location_id: this.locationId,
        remote_location_id: this.currUser.location_id,
        trx_date: this.currUser.trx_date,
        currency_id: '1',
        client_ip: '',
        customer_id: currentCustomer.customer_id,
        customer_name: currentCustomer.full_name,
        dom_int: 'IMT',
        risk_score: currentCustomer.risk_score,
        risk_criteria: currentCustomer.risk_criteria,
        form_type: (this.currUser.location_type === '1' ? 'OAR' : ''),
        form_number: (this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no) : this.qpForeignRefNo)
      }

      await this.$store.dispatch('wu/doStoreQPTransaction', payload).then(async res => {
        const address = this.selectedCustomer.address
        const barangay = this.selectedCustomer.barangay
        const city = this.selectedCustomer.municipalty
        const province = this.selectedCustomer.province
        const zipCode = this.selectedCustomer.zip_code

        if (this.currUser.location_type === '1') {
          if (this.currUser.receipt_type === 3) {
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.selectedCustomer.customer_id,
              customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
              customer_tin_no: '',
              customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
              customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'Quickpay',
              oar_currency: 'PHP',
              oar_principal_amount: qpValidateData.financials.originators_principal_amount / 100,
              oar_discount: 0,
              oar_total: qpValidateData.financials.gross_total_amount / 100,
              grand_total: qpValidateData.financials.gross_total_amount / 100,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
          } else {
            const payload = {
              id: this.seriesDetail.id,
              status: 2,
              customer_id: this.selectedCustomer.customer_id,
              encoded_by: this.currUser.user_id,
              date_encoded: format(new Date(), 'yyyy-MM-dd'),
              amount: qpValidateData.financials.gross_total_amount / 100,
              description: 'Quickpay',
              remarks: 'Quickpay'
            }

            await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
              } else {
                this.renderToast('error', 'Error', res.message)
              }
            }).catch(err => {
              this.renderToast('error', 'Error', err)
            })
          }
        }

        console.log(res)
        if (res?.result) {
          let i = 0
          let j = 0
          let pinMessage = ''
          let promoTextMessage = ''

          for (i; i < res.result.confirmed_details.pin_message.length; i++) {
            if (res.result.confirmed_details.pin_message[i] !== '') {
              pinMessage += res.result.confirmed_details.pin_message[i] + ' '
            }
          }
          for (j; j < res.result.confirmed_details.promo_text_message.length; j++) {
            if (res.result.confirmed_details.promo_text_message[j] !== '') {
              promoTextMessage += res.result.confirmed_details.promo_text_message[j] + ' '
            }
          }
          const printReceiptPayload = {}
          printReceiptPayload.print_data = {
            sender: currentCustomer.first_name + ' ' + currentCustomer.middle_name + ' ' + currentCustomer.last_name,
            address: currentCustomer.address + ' ' + currentCustomer.barangay + ' ' + currentCustomer.municipality + ' ' + currentCustomer.province + ' ' + currentCustomer.zip_code,
            id_type: currentTrx.idName,
            id_number: currentTrx.idNumber,
            test_question: '',
            answer: '',
            sender_mobile_number: currentCustomer.mobile_no,
            receiver: currentTrx.company,
            r_city: currentTrx.payoutCity + ' ' + currentTrx.payoutState.state_code,
            r_country: this.receiverCountryName,
            service: 'Quick Pay',
            message: payload.message,
            receiver_mobile_number: '',
            mtcn: res.result.confirmed_details.mtcn.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
            date_time: this.formatDateTime(new Date()),
            agent_name: this.currUser.location_name + ' BRANCH',
            amount: (this.inquireRes.result.financials.originators_principal_amount / 100).toFixed(2),
            transfer_fee: (this.inquireRes.result.financials.charges / 100).toFixed(2),
            msg_charge: '',
            delivery_charge: '0.00',
            promo_code: currentTrx.promoCode,
            discount: '',
            total: 'Philippine Peso ' + (this.inquireRes.result.financials.gross_total_amount / 100).toFixed(2),
            exchange_rate: currentTrx.exchangeRateValue,
            payout_amount: (this.inquireRes.result.financials.destination_principal_amount / 100).toFixed(2),
            promo_text_message: promoTextMessage,
            pin_text_message_set_2: pinMessage,
            want_to_register: '',
            mywu_number: (this.selectedCustomer.wu_card_no !== '0' || this.selectedCustomer.wu_card_no !== null) ? this.selectedCustomer.wu_card_no : '',
            mywu_points: res.result.confirmed_details.new_points_earned,
            total_mywu_points: parseInt(kycLookupCustomer.mywu_details.current_yr_pts) + parseInt(res.result.confirmed_details.new_points_earned),
            adj_type: '',
            mc_rate: '',
            buyback_amount: '',
            total_amount: '',
            purpose_of_trxn: this.selectedCustomer.purpose_transaction,
            promo_flag: 'N',
            expiration_date: '',
            vuser_group_id: this.currUser.user_group_id
          }
          if (this.currUser.has_wacom) {
            printReceiptPayload.print_data.customer_signature = this.customerSignature
            printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
          }
          printReceiptPayload.data = {
            has_wacom: this.currUser.has_wacom,
            trx_type: 1,
            remco_name: 'Western Union',
            control_number: res.result.confirmed_details.mtcn,
            customer_id: this.selectedCustomer.customer_id.toString(),
            location_id: this.currUser.location_id,
            location_name_uploaded: this.currUser.location_name,
            crn: this.selectedCustomer.customer_number,
            uploaded_by: this.currUser.user_id,
            first_name: this.selectedCustomer.first_name,
            last_name: this.selectedCustomer.last_name,
            product: 'WU Receive Money',
            receipt_type: 'acr'
          }
          if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
            printReceiptPayload.print_data.agent_name = this.remoteData.remote_location.location_name + ' BRANCH'
            printReceiptPayload.data.location_id = this.remoteData?.remote_location?.location_id
            printReceiptPayload.data.uploaded_by = this.remoteData.remote_user
            printReceiptPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
          } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
            printReceiptPayload.print_data.agent_name = this.currUser.location_name + ' BRANCH'
            printReceiptPayload.data.location_id = this.currUser.location_id
            printReceiptPayload.data.uploaded_by = this.currUser.user_id
            printReceiptPayload.data.location_name_uploaded = this.currUser.location_name
          }
          console.log(printReceiptPayload)
          this.generateACR(printReceiptPayload)
          this.otp = ''
          this.$store.commit('obo/SET_REMOTE_DATA', {})
        } else {
          this.renderToast('error', 'WU Error', res.message)
        }
      }).catch(err => {
        console.log(err)
        this.renderToast('error', 'WU Error', err.message)
      })
    },
    async generateACR (payload) {
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', payload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      this.renderToast('success', 'Quick Pay Successful', 'Transaction successful!')
    },
    updateDetails (customerDetails) {
      this.noIDFlag = false

      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'WU QuickPay',
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    }
  }
}
</script>
<style scoped>
  .v-stepper.ph-wu-quickpay-stepper .v-stepper__header {
    box-shadow: none !important;
  }

  .ph-wu-quickpay-summary .v-card {
    padding: 20px;
  }

  .ph-transfer-summary-header {
    font-size: 18px;
    text-align: center;
  }

  .ph-transfer-summary-content-title {
    font-size: 14px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
    border: 2px solid #1A2791;
  }

  .ph-transaction-container {
    padding: 0;
  }

  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 100px;
  }

  .ph-wu-quickpay-summary-minified {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F4F4FA;
  }

  .ph-wu-quickpay-summary-content {
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
  }

  .ph-wu-quickpay-summary-minified-info {
    margin-right: auto;
  }

  .ph-wu-quickpay-summary-minified-info p {
    margin-bottom: 0;
  }

  .ph-wu-quickpay-summary-minified-action {
    margin-left: auto;
  }

  .ph-primary-button.ph-stepper-button {
    height: 40px !important;
    width: 115px;
  }

  .ph-wu-quickpay-form {
    padding: 12px 0;
  }

  /* @media only screen and (min-width: 600px) {
    .v-stepper.ph-wu-quickpay-stepper .v-stepper__step {
      padding-left: 0;
    }
  } */

  .transfer-info-title,
  .transfer-info-value {
    border-bottom: thin dashed rgba(0,0,0,.12) !important;
  }

  @media only screen and (min-width: 960px) {
    .ph-stepper-action-buttons {
      display: flex;
      margin-bottom: 0;
    }
  }
</style>
